export const testReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'ADD_TESTS':
      return { ...state, tests: [...state.tests, ...payload] };
    case 'UPDATE_TESTS':
      return { ...state, tests: payload };
    case 'SELECT_TEST':
      return {
        ...state,
        selectedTest: payload
      };
    case 'RESET_TESTS':
      return payload;
    default:
      return state;
  }
};
