import { handleError } from 'helpers/utils';
import axios from './axiosInstance';

export const logoutService = async () => {
  try {
    const resp = await axios.get('/auth/logout');
    return resp.data;
  } catch (err) {
    return handleError(err);
  }
};

export const resetPasswordService = async email => {
  try {
    const resp = await axios.post('/auth/reset-password', { email });
    return resp.data;
  } catch (err) {
    return handleError(err);
  }
};

export const authChangePasswordService = async (token, password) => {
  try {
    const resp = await axios.post('/auth/change-password', { token, password });
    return resp.data;
  } catch (err) {
    return handleError(err);
  }
};
