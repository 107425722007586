export const authReducer = (state, action) => {
  const { type, payload } = action;
  let authState, authStateString;
  switch (type) {
    case 'LOGIN_SUCCESS':
      authState = {
        ...state,
        ...payload,
        isLogedIn: true
      };
      authStateString = JSON.stringify(authState);
      localStorage.setItem('authState', authStateString);
      return authState;
    case 'RESET_DATA':
      authState = {
        ...state,
        userRole: null,
        isLogedIn: false
      };
      authStateString = JSON.stringify(authState);
      localStorage.setItem('authState', authStateString);
      return authState;
    case 'SAVE_ROLE':
      return {
        ...state,
        userRole: payload
      };
    default:
      return state;
  }
};
