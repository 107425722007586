import { Fragment, useState, useEffect, useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDebounce } from 'use-debounce';

import { getAllTemplatesService } from 'services/templateService';
import { TemplateContext } from 'context/Context';
import TemplateFullDetailViewModal from './templates/TemplateFullDetailViewModal';
import AssessmentView from './templates/AssessmentView';
import usePagination from 'hooks/usePagination';
import useError from 'hooks/useError';

export default function Tamplates() {
  const { getResponse } = useError();
  const { templateState, saveAllTemplates } = useContext(TemplateContext);
  const [isTemplateFetching, setTemplateFetching] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [debouncedText] = useDebounce(search, 1000);
  const [selectedAssessment, setSelectedAssessment] = useState(null);
  const [selectedAssessmentType, setAssessmentType] = useState({
    value: null,
    label: 'Select'
  });

  const handleAssessmentClick = item => {
    setSelectedAssessment(item);
  };
  const handleModalClose = () => {
    setSelectedAssessment(null);
  };

  const getAllTemplates = async (
    pageId,
    debouncedText,
    selectedAssessmentType
  ) => {
    setTemplateFetching(true);
    const res = await getAllTemplatesService(
      pageId,
      debouncedText,
      selectedAssessmentType.value === 'all'
        ? null
        : selectedAssessmentType.value
    );
    getResponse(res)
      .then(res => {
        saveAllTemplates(res);
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setTemplateFetching(false);
      });
  };

  const { Pagination } = usePagination(setPage);

  useEffect(() => {
    getAllTemplates(page, debouncedText, selectedAssessmentType);
  }, [page, debouncedText, selectedAssessmentType]);

  return (
    <Fragment>
      <Row className="flex-between-center mb-3">
        <Col md>
          <h5 className="mb-2 mb-md-0">Templates</h5>
        </Col>
      </Row>
      <AssessmentView
        data={templateState.templates?.data || []}
        onAssessmentClick={handleAssessmentClick}
        isLoading={isTemplateFetching}
        search={search}
        setSearch={setSearch}
        selectedAssessmentType={selectedAssessmentType}
        setAssessmentType={setAssessmentType}
      />
      {templateState.templates?.total_pages ? (
        <Pagination totalPages={templateState.templates.total_pages} />
      ) : null}
      <TemplateFullDetailViewModal
        data={selectedAssessment}
        show={selectedAssessment != null}
        handleClose={handleModalClose}
      />
    </Fragment>
  );
}
