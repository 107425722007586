import CloseButton from 'components/common/CloseButton';
import DynamicForms from 'components/common/DynamicForm';
import React from 'react';
import { Button, Modal, Tab, Tabs } from 'react-bootstrap';
import { useContext } from 'react';
import { StepContext } from 'context/Context';
import { useEffect } from 'react';
import { useState } from 'react';
import { updateStepService } from 'services/killchainService';
import { toast } from 'react-toastify';
import useError from 'hooks/useError';
import AdvanceOptionsForStep from './config/AdvanceOptionsForStep';

export default function ConfigForm() {
  const {
    stepState: { selectedStepConfig, nodes },
    updateSelectStepConfig
  } = useContext(StepContext);
  const [show, setShow] = useState(false);
  const [formSchema, setFormSchema] = useState([]);
  const [variables, setVariables] = useState(null);
  const [isParser, setParser] = useState(false); // state to show whether parser option is there or not in step data
  const [formOfOS, setFormOfOS] = useState({});

  const handleClose = () => updateSelectStepConfig(null);

  useEffect(() => {
    setShow(selectedStepConfig ? true : false);
  }, [selectedStepConfig]);

  useEffect(() => {
    let stepIndex = nodes.findIndex(n => n.id === selectedStepConfig);
    let configData = nodes[stepIndex]?.data?.config;
    if (nodes[stepIndex]?.data?.parserOptions) {
      setParser(true);
    }
    setFormSchema(configData);
    setVariables(nodes[stepIndex]?.data?.variables);
  }, [selectedStepConfig]);

  const handleFormUpdate = (os, data) => {
    setFormOfOS({ ...formOfOS, [os]: data });
  };

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
      <Modal.Header>
        <Modal.Title>Configure</Modal.Title>
        <CloseButton onClick={handleClose} />
      </Modal.Header>
      <Modal.Body>
        <Tabs
          defaultActiveKey="schema"
          id="configuration-tabs"
          transition={false}
        >
          <Tab
            eventKey="schema" // it should be fixed and used for formSchema for each os in step
            title="Default"
            className="border-bottom border-x p-3"
          >
            {Object.keys(formSchema ?? {})?.map(os => (
              <React.Fragment key={os}>
                <h4 className="text-capitalize">{os}</h4>
                <RenderFormByOS
                  key={formSchema[os]}
                  os={formSchema[os]}
                  variables={variables?.[os] ?? null}
                  handleFormUpdate={data => handleFormUpdate(os, data)}
                />
              </React.Fragment>
            ))}
            {Object.keys(formSchema ?? {}).length > 0 ? (
              <StepConfigSave data={formOfOS} />
            ) : (
              'No configuration available.'
            )}
          </Tab>
          <Tab
            eventKey="commands" // currently used for commands for each os in step
            title="Advance"
            className="border-bottom border-x p-3"
          >
            {isParser ? (
              <AdvanceOptionsForStep />
            ) : (
              'No Advance Opions Avalable Yet.'
            )}
          </Tab>
        </Tabs>
      </Modal.Body>
    </Modal>
  );
}

const RenderFormByOS = ({ os, variables, handleFormUpdate }) => {
  if (!os) {
    return 'No configuration available.';
  }
  return os.map(section => {
    if (!section?.form) {
      return 'No configuration available.';
    }
    return (
      <DynamicForms
        formsData={section?.form}
        variables={variables}
        // ActionComponent={StepConfigSave}
        onFormUpdate={handleFormUpdate}
      />
    );
  });
};

const StepConfigSave = ({ data }) => {
  const { getResponse } = useError();

  const [apply, setApply] = useState(false);
  const {
    stepState: { selectedStepConfig, nodes },
    updateSelectStepConfig,
    updateNodeDataProps
  } = useContext(StepContext);

  const onSave = async () => {
    setApply(true);
    const resultOfOS = {};
    Object.keys(data).map((key, idx) => {
      const result = {};
      data[key].forEach(obj => {
        obj.forms.forEach((form, formIdx) => {
          form.fields.forEach(field => {
            if (obj.multiple) {
              if (!result[obj.name]) {
                result[obj.name] = [];
              }
              if (!result[obj.name][formIdx]) {
                result[obj.name][formIdx] = {};
              }
              result[obj.name][formIdx][field.name] = field.value;
            } else {
              result[field.name] = field.value;
            }
          });
        });
      });
      resultOfOS[key] = result;
    });

    // change result as { windows: {key:''}, otheros: {key:''}}
    let stepId = nodes.find(n => n.id === selectedStepConfig).data.stepId;
    const res = await updateStepService(stepId, { variables: resultOfOS });
    getResponse(res)
      .then(res => {
        updateNodeDataProps(selectedStepConfig, { variables: resultOfOS });
        setApply(false);
        updateSelectStepConfig(null);
        toast(
          <span className="text-success">
            Configuration updated successfully.
          </span>
        );
      })
      .catch(err => {
        setApply(false);
      });
  };

  return (
    <div className="p-2 border-top">
      <Button
        variant="primary"
        size="sm"
        className="py-2 px-4 md-auto"
        onClick={onSave}
        disabled={apply}
      >
        {apply ? 'Applying...' : 'Apply'}
      </Button>
    </div>
  );
};
