import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { AuthContext } from 'context/Context';
import { authReducer } from 'reducers/authReducer';
import { useNavigate } from 'react-router-dom';
import { logoutService } from 'services/authService';

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const savedAuthStateString = localStorage.getItem('authState');
  const savedAuthState = JSON.parse(savedAuthStateString);
  const initData = {
    isLogedIn: savedAuthState?.isLogedIn || false,
    userRole: savedAuthState?.userRole || null,
    orgId: savedAuthState?.orgId || null
  };
  const [authState, dispatch] = useReducer(authReducer, initData);

  // useEffect(() => {

  // }, [authState]);

  const onLoginSuccess = ({ userRole, orgId }) => {
    dispatch({
      type: 'LOGIN_SUCCESS',
      payload: { userRole, orgId }
    });
  };

  const onAuthError = () => {
    dispatch({
      type: 'RESET_DATA'
    });
  };

  const logout = async () => {
    const res = await logoutService();
    onAuthError();
    localStorage.removeItem('authState');
    navigate('/');
  };

  const saveRole = role => {
    dispatch({
      type: 'SAVE_ROLE',
      payload: role
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...authState,
        logout,
        onAuthError,
        onLoginSuccess,
        saveRole
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthProvider;
