import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';

const NotificationDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isAllRead, setIsAllRead] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      window.innerWidth < 1200 && setIsOpen(false);
    });
  }, []);

  return (
    <Dropdown navbar={true} as="li" show={isOpen}>
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="/notifications"
        className={classNames('px-0 nav-link', {
          'notification-indicator notification-indicator-danger': !isAllRead
        })}
      >
        <span className="notification-indicator-number"></span>

        <FontAwesomeIcon icon="bell" transform="shrink-6" className="fs-4" />
      </Dropdown.Toggle>
    </Dropdown>
  );
};

export default NotificationDropdown;
