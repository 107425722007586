import { handleError } from 'helpers/utils';
import axios from './axiosInstance';

export const getAllProjectService = async (page = 1) => {
  try {
    const resp = await axios.get('/project?page=' + page);
    return resp.data;
  } catch (err) {
    return handleError(err);
  }
};

export const createProjectService = async data => {
  try {
    const resp = await axios.post('/project', data);
    return resp.data;
  } catch (err) {
    return handleError(err);
  }
};

export const updateProjectService = async (id, data) => {
  try {
    const resp = await axios.put('/project/' + id, data);
    return resp.data;
  } catch (err) {
    return handleError(err);
  }
};

export const deleteProjectService = async id => {
  try {
    const resp = await axios.delete('/project/' + id);
    return resp.data;
  } catch (err) {
    return handleError(err);
  }
};
