import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import user from 'assets/img/icons/user-icon.png';
import Avatar from 'components/common/Avatar';
import { AuthContext } from 'context/Context';

const ProfileDropdown = () => {
  const { logout } = useContext(AuthContext);
  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className="pe-0 ps-2 nav-link"
      >
        <Avatar
          src={user}
          className="bg-400 rounded-circle overflow-hidden"
          mediaClass="mt-1"
        />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          <Dropdown.Item as={Link} to="/account">
            Account
          </Dropdown.Item>
          <Dropdown.Item as={'button'} onClick={logout}>
            Logout
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
