import React from 'react';
import { Button, Modal, Form, Col, Row } from 'react-bootstrap';
import CloseButton from 'components/common/CloseButton';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createKillChainWithoutTemplateService } from 'services/killchainService';
import useError from 'hooks/useError';

function CreateAssessmentModal({ projectId, show, handleClose }) {
  const navigate = useNavigate();
  const { getResponse } = useError();

  const [formData, setFormData] = useState({});
  const [isCreating, setCreating] = useState(false);

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const onCreate = async () => {
    setCreating(true);
    const res = await createKillChainWithoutTemplateService({
      ...formData,
      projectId: projectId
    });
    setCreating(false);
    getResponse(res)
      .then(res => {
        navigate(`/assessments/${res.id}/setup`);
      })
      .catch(err => {});
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>Create New Assessment</Modal.Title>
          <CloseButton onClick={handleClose} disabled={isCreating} />
        </Modal.Header>
        <Modal.Body>
          <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
            <Form.Label column sm={4}>
              Assessment Name*
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                name="name"
                value={formData.name}
                type="text"
                placeholder="e.g. Linear"
                onChange={handleChange}
              />
            </Col>
          </Form.Group>

          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formHorizontalPassword"
          >
            <Form.Label column sm={4}>
              Assessment Description*
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                as="textarea"
                rows={3}
                name="description"
                value={formData.description}
                placeholder="Write a few sentences about the assessment..."
                onChange={handleChange}
              />
            </Col>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="falcon-default"
            className="py-2 px-4"
            onClick={handleClose}
            disabled={isCreating}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            className="py-2 px-4"
            disabled={isCreating}
            onClick={onCreate}
          >
            {isCreating ? 'Creating...' : 'Create'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CreateAssessmentModal;
