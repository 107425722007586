import { AgentContext } from 'context/Context';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { getAllAgentService } from 'services/agentService';
import useError from './useError';

export default function useAgent(isAlive) {
  const { getResponse } = useError();
  const [isFetching, setFetching] = useState(false); // initial fetching state = false
  const { agentState, saveAllAgents } = useContext(AgentContext);

  useEffect(() => {
    if (agentState.agents.length == 0) {
      getAllAgents(null, null, null, null, isAlive);
    }
  }, []);

  const getAllAgents = useCallback(
    async (pageId, search, os = null, tags = null, isAlive = null) => {
      setFetching(true);
      const res = await getAllAgentService(pageId, search, os, tags, isAlive);
      setFetching(false);
      getResponse(res)
        .then(res => {
          saveAllAgents(res);
        })
        .catch(err => console.error(err));
    },
    []
  );

  return { agents: agentState.agents, isFetching, getAllAgents };
}
