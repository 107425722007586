import React, { useContext } from 'react';
import IntegrationFormatForm from './IntegrationFormatForm';
import { useState } from 'react';
import { updateIntegrationForOrganizationService } from 'services/integrationService';
import { AuthContext } from 'context/Context';
import { toast } from 'react-toastify';
import useError from 'hooks/useError';

export default function IntegrationWrapper({ data, type }) {
  const { getResponse } = useError();
  const { orgId } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    ...data?.secrets,
    name: data?.Integration?.slug
  });
  const formChange = e => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };
  const getFields = () => {
    let empty = [];
    data?.Integration?.secrets?.forEach(s => {
      empty.push({ id: s.key, label: s.label });
    });
    return empty;
  };

  const handleUpdateIntegration = async () => {
    let { name, ...rest } = formData;
    const res = await updateIntegrationForOrganizationService(orgId, data.id, {
      integrationId: data.id,
      secrets: {
        ...rest
      }
    });
    getResponse(res)
      .then(res => {
        toast(<span className="text-success">{res.message}</span>);
      })
      .catch(err => console.error(err));
  };

  return (
    <div className="border border-2 border-400 rounded p-3 pt-1 my-3">
      <IntegrationFormatForm
        title={type}
        disableIntegrationSelect
        integrationOptions={[
          { value: data?.Integration?.slug, label: data?.Integration?.name }
        ]}
        data={formData}
        handleChange={formChange}
        fields={getFields()}
      />
      {/* save changes */}
      <div className="row m-0 mt-3">
        <div className="col-md-12 m-0 p-0">
          <button
            type="button"
            className="btn btn-outline-primary border-1"
            onClick={handleUpdateIntegration}
          >
            Save Changes
          </button>
        </div>
      </div>
    </div>
  );
}
