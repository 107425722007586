import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import { NotificationContext } from 'context/Context';
import { notificationReducer } from 'reducers/notificationReducer';

const NotificationProvider = ({ children }) => {
  const initData = {
    data: []
  };
  const [notificationState, dispatch] = useReducer(
    notificationReducer,
    initData
  );

  const saveAllNotifications = data => {
    dispatch({
      type: 'SAVE_ALL_NOTIFICATION_DATA',
      payload: data
    });
  };
  return (
    <NotificationContext.Provider
      value={{
        notificationState,
        saveAllNotifications
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

NotificationProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default NotificationProvider;
