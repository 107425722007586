import React from 'react';
import { useContext } from 'react';
import {
  AddStepContext,
  StepContext,
  TechniqueContext,
  TemplateContext
} from 'context/Context';

export default function useClearContextState() {
  const { resetAddStep } = useContext(AddStepContext);
  const { resetStep } = useContext(StepContext);
  const { resetTechnique } = useContext(TechniqueContext);
  const { resetTemplate } = useContext(TemplateContext);

  const clearContextState = () => {
    resetAddStep();
    resetTechnique();
    resetTemplate();
    resetStep();
  };
  return { clearContextState };
}
