import CloseButton from 'components/common/CloseButton';
import ControlledImageSlider from 'components/common/ControlledImageSlider';
import { Modal } from 'react-bootstrap';

function ScreenshotView({ id, data, handleClose }) {
  const imgData = data.find(o => o.id === id);
  return (
    <>
      <Modal
        show={true}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="xl"
      >
        <Modal.Header>
          <Modal.Title>ScreenShots</Modal.Title>
          <CloseButton onClick={handleClose} />
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="screenshots_slider">
            <ControlledImageSlider data={imgData.img ?? []} initialIndex={0} />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ScreenshotView;
