import React from 'react';

function ShadowPopup({ children }) {
  return (
    <div className="position-absolute top-50 start-50 translate-middle bg-white shadow p-3 rounded">
      {children}
    </div>
  );
}

export default ShadowPopup;
