import DateTimeModal from 'components/common/DateTimeModal';
import { StepContext } from 'context/Context';
import { getUTCFormatByString } from 'helpers/utils';
import useError from 'hooks/useError';
import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { updateKillchainExecutionStep } from 'services/killchainService';

export default function StepScheduleModal() {
  const { id } = useParams();
  const { getResponse } = useError();
  const [isUpdating, setUpdating] = useState(false);
  const { stepState, updateSelectedStepForSchedule, updateNodeDataProps } =
    useContext(StepContext);

  const handleClose = () => {
    updateSelectedStepForSchedule(null);
  };

  if (!stepState?.selectedStepForSchedule) {
    return null;
  }
  const node = stepState?.nodes?.find(
    n => n.id == stepState?.selectedStepForSchedule
  );

  const handleSave = async date => {
    setUpdating(true);
    let dateStr = getUTCFormatByString(date);
    let data = {
      scheduledAt: dateStr
    };
    const res = await updateKillchainExecutionStep(id, node.data.stepId, data);
    setUpdating(false);
    getResponse(res)
      .then(res => {
        updateNodeDataProps(stepState?.selectedStepForSchedule, data);
        updateSelectedStepForSchedule(null);
      })
      .catch(err => console.error(err));
  };
  return (
    <DateTimeModal
      show={stepState?.selectedStepForSchedule !== null}
      handleClose={handleClose}
      onSave={handleSave}
      isLoading={isUpdating}
      date={node?.data?.scheduledAt}
    />
  );
}
