import CardDropdown from 'components/common/CardDropdown';
import Flex from 'components/common/Flex';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Col, Dropdown, Row } from 'react-bootstrap';
import { RiNodeTree } from 'react-icons/ri';
import { BiMapPin } from 'react-icons/bi';
import { MdArrowBackIos, MdOutlineComputer } from 'react-icons/md';
import { ReactFlowProvider } from 'reactflow';
import ViewModeKillchainFlow from './killchain/results/ViewModeKillchainFlow';
import PhaseComponent from './killchain/results/PhaseComponent';
import {
  getKillChainByIdService,
  getKillchainExecutionByIdService
} from 'services/killchainService';
import { useParams } from 'react-router-dom';
import { ExecutionContext, StepContext, TestContext } from 'context/Context';
import { capitalize, getDateInFormatOfDMonthY } from 'helpers/utils';
import ConfigFormModal from './flow/ConfigForm';
import StepScheduleModal from './killchain/results/StepScheduleModal';
import KillchainLoader from 'components/common/KillchainLoader';
import TableLoader from 'components/common/TableLoader';
import useError from 'hooks/useError';

export default function AssessmentExecution() {
  const { getResponse } = useError();
  const {
    executionState: { executionResult },
    saveExecution
  } = useContext(ExecutionContext);
  const {
    stepState,
    addNodesToStep,
    addEdgesToStep,
    updateKillchain,
    resetStep
  } = useContext(StepContext);
  const { resetTest, addTest } = useContext(TestContext);
  const { id, executionId } = useParams();
  const [killchainData, setKillchainData] = useState({
    type: null
  });

  const [isFetching, setFetching] = useState(false);
  const [selectedAgent, setAgent] = useState(null);
  // const [nodes, setNodes] = useState([]);
  // const [edges, setEdges] = useState([]);

  const getAssessmentExecutions = useCallback(async () => {
    setFetching(true);
    const res = await getKillchainExecutionByIdService(id, executionId);
    getResponse(res)
      .then(res => {
        saveExecution(res);
        setKillchainData({ type: res?.type });
        setFetching(false);
      })
      .catch(err => {
        setFetching(false);
      });
  }, []);

  const getKillChain = async () => {
    const res = await getKillChainByIdService(id);
    getResponse(res)
      .then(res => {
        if (res.type === 'killchain') {
          handleSteps(res.Steps);
        } else {
          handleTests(res.Steps);
        }
      })
      .catch(err => console.error(err));
  };

  useEffect(() => {
    updateKillchain(id);
    getKillChain();
    getAssessmentExecutions();
    return () => {
      resetStep();
      resetTest();
    };
  }, []);

  const handleTests = steps => {
    addTest(
      steps.map(step => {
        const { technique, edges, node, scenario, ...rest } = step;
        return { ...rest, stepId: step.id };
      })
    );
  };

  const handleSteps = steps => {
    const nodes = [];
    const edges = [];
    steps.forEach(step => {
      if (step.edges) {
        step.edges.forEach(ed => {
          edges.push(ed);
        });
      }
      let advanceConfig = {};
      step?.supported_platforms?.forEach(platform => {
        let parser = step?.[platform]?.[0]?.commands?.[0]?.parser;
        if (parser) {
          advanceConfig = {
            ...advanceConfig,
            parserOptions: {
              ...advanceConfig.parserOptions,
              [platform]: parser
                ?.filter(pArr => pArr?.name)
                ?.map(p => ({
                  label: p.name,
                  value: p.key
                }))
            }
          };
        }
      });

      nodes.push({
        ...step.node,
        type: 'custom',
        data: {
          number: nodes.length + 1,
          name:
            step.name ||
            step?.techniqueName ||
            step?.Technique?.name ||
            step?.Technique?.techniqueName,
          description:
            step.description ||
            step?.techniqueDescription ||
            step?.Technique?.description ||
            step?.Technique?.techniqueDescription,
          techniqueId: step.techniqueId,
          config: step.formSchema?.formSchema ?? null,
          variables: step.variables,
          parserKey: step.parserKey, // should come as {windows: value, debian: value, ...}
          filterOutputTo: step.filterOutputTo, // should come as [value]
          filterOutputFileName: step?.filterOutputFileName,
          action: 'play',
          detailStatus: 'noaction',
          status: 'noaction',
          stepId: step.id,
          index: step.index,
          tags:
            step?.Taggable?.map(each => each?.tag).map(tag => tag?.name) || [],
          successStepId: step.successStepId,
          failureStepId: step.failureStepId,
          ...advanceConfig
        }
      });
      if (nodes.length == 1) {
        nodes[0].sourcePosition = 'right';
      }
    });
    addNodesToStep(nodes);
    addEdgesToStep(edges);
  };

  return (
    <React.Fragment>
      <Row className="bg-white py-4 px-3 m-0 ">
        <Col xs={12}>
          <Flex className={'mb-3 justify-content-center'} alignItems={'center'}>
            {/* <Flex className="me-4" alignItems={'center'}>
              <MdArrowBackIos className="text-black fw-bold" size={20} />
              <span className="fs-1">Back</span>
            </Flex> */}
            <h5>{executionResult?.ExecutionSteps?.[0]?.KillChain?.name}</h5>
          </Flex>
        </Col>
        <Col>
          <Flex className={'justify-content-center'}>
            <div className="rounded-pill border border-2 px-3 py-1 me-2">
              <RiNodeTree className="me-2" />
              <span className="fs--1 fw-semi-bold">TOTAL STEPS : </span>
              <span className="fw-bold fs--1 ">
                {executionResult?.ExecutionSteps?.length}
              </span>
            </div>
            <div className="rounded-pill border border-2 px-3 py-1 me-2">
              <BiMapPin className="me-2" />
              <span className="fs--1 fw-semi-bold">CURRENT STEP : </span>
              <span className="fw-bold fs--1 ">2</span>
            </div>
            <Flex
              alignItems={'center'}
              className="rounded-pill border border-2 px-3 py-1"
            >
              <MdOutlineComputer className="me-2" />
              <div className="fs--1 fw-semi-bold">AGENTS :</div>
              <div className="fw-bold fs--1 ">
                {executionResult?.agents?.length}
              </div>
              <CardDropdown
                drop="down-centered"
                btnRevealClass="py-0 px-1 ms-2"
              >
                <div className="py-2">
                  <div className="mb-2 text-black mx-3 fs--2 text-uppercase fw-semi-bold">
                    Select Agent
                  </div>
                  {executionResult?.agents?.map(agent => (
                    <Dropdown.Item
                      as={'div'}
                      key={agent?.id}
                      onClick={() => {
                        setAgent(agent);
                      }}
                    >
                      {agent?.name || agent?.meta?.computerName}
                    </Dropdown.Item>
                  ))}
                </div>
              </CardDropdown>
            </Flex>
          </Flex>
          <Flex className={'my-3 justify-content-center'}>
            <div className="rounded-pill bg-success px-3 py-0 me-2">
              <span className="fs--1 d-inline-block mb-1 text-white fw-semi-bold">
                Success |{' '}
                {
                  executionResult?.ExecutionSteps?.filter(
                    es => es.status == 'success'
                  ).length
                }
              </span>
            </div>
            <div className="rounded-pill bg-danger px-3 py-0 me-2">
              <span className="fs--1 d-inline-block mb-1 text-white fw-semi-bold">
                Failure |{' '}
                {
                  executionResult?.ExecutionSteps?.filter(
                    es => es.status == 'failed'
                  ).length
                }
              </span>
            </div>
          </Flex>
          <Flex className={'justify-content-center'}>
            <div className=" fs--2 fw-bold">
              <span className="text-uppercase">Started on : </span>
              <span>
                {getDateInFormatOfDMonthY(executionResult?.createdAt)}
              </span>
            </div>
            <div className="mx-3 border"></div>
            <div className=" fs--2 fw-bold">
              <span className="text-uppercase">Ended on : </span>
              <span>
                {getDateInFormatOfDMonthY(executionResult?.updatedAt)}
              </span>
            </div>
            <div className="mx-3 border"></div>
            <div className=" fs--2 fw-bold">
              <span className="text-uppercase">Started by : </span>
              <span>
                {executionResult?.ExecutionSteps?.[0]?.user?.firstName}{' '}
                {executionResult?.ExecutionSteps?.[0]?.user?.lastName}
              </span>
            </div>
          </Flex>
          {/* <div className=" fs--2 fw-bold">
            <span className="text-uppercase">Ended on : </span>
            <span>03 March 2023</span>
          </div>
          <div className=" fs--2 fw-bold">
            <span className="text-uppercase">Started by : </span>
            <span>Avinash</span>
          </div> */}
        </Col>
      </Row>
      {killchainData &&
        killchainData.type === 'killchain' &&
        (isFetching ? (
          <KillchainLoader />
        ) : (
          <div className="my-4">
            <div className="position-relative" style={{ height: 400 }}>
              <ReactFlowProvider>
                <ViewModeKillchainFlow
                  flowNodes={stepState?.nodes}
                  flowEdges={stepState?.edges}
                />
              </ReactFlowProvider>
            </div>
          </div>
        ))}
      {isFetching ? (
        <TableLoader />
      ) : (
        <PhaseComponent selectedAgent={selectedAgent} setAgent={setAgent} />
      )}
      <ConfigFormModal />
      <StepScheduleModal />
    </React.Fragment>
  );
}
