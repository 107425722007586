import { AuthContext } from 'context/Context';
import React, { useContext, useEffect, useState } from 'react';
import { Navigate, Route } from 'react-router-dom';

// Function to check if the user has any of the required roles
const hasRequiredRole = (userRole, requiredRoles) => {
  return requiredRoles.includes(userRole);
};

// RoleRoute component that handles role-based routing
const RoleRoute = ({ element, requiredRoles, ...rest }) => {
  const { userRole } = useContext(AuthContext);
  if (hasRequiredRole(userRole, requiredRoles)) {
    return element;
  }

  return <Navigate to="/" replace />;
};

export default RoleRoute;
