import React, { memo, useContext, useEffect } from 'react';
import { Handle, Position } from 'reactflow';
import TagView from 'components/common/TagView';
import classNames from 'classnames';
import { MdSettings, MdOutlineTimer, MdDeleteOutline } from 'react-icons/md';
import { useState } from 'react';
import { EditStepContext, StepContext } from 'context/Context';
import { useCallback } from 'react';
import { BiMessageSquareEdit } from 'react-icons/bi';

const HANDLE_POSITION = {
  right: Position.Right,
  left: Position.Left,
  bottom: Position.Bottom,
  top: Position.Top
};

export default memo(({ id, data, isConnectable }) => {
  const { toggleModal, updateScenario } = useContext(EditStepContext);
  const {
    updateNodeData,
    updateSelectStepConfig,
    showStepData,
    updateDeleteStep
  } = useContext(StepContext);
  const [formData, setFormData] = useState({
    name: data.name || '',
    description: data.description || ''
  });

  useEffect(() => {
    setFormData({
      ...formData,
      name: data.name || '',
      description: data.description || ''
    });
  }, [data]);
  //   const handleChange = e => {
  //     const { name, value } = e.target;
  //     setFormData({ ...formData, [name]: value });
  //   };

  const handleFocusBlur = useCallback(formFields => {
    if (
      data.name != formFields.name ||
      data.description != formFields.description
    ) {
      const updatedData = { ...data, ...formFields };
      updateNodeData(id, updatedData);
    }
  }, []);

  const onBlur = useCallback(() => {
    handleFocusBlur(formData);
  }, [formData]);

  const handleStepEdit = () => {
    showStepData(id);
    updateScenario({ id: data.techniqueId });
    toggleModal();
  };

  const onStepDelete = () => updateDeleteStep(id);

  return (
    <>
      <div>
        <div className="node-tag text-info bg-200 d-inline-flex align-items-center py-1 px-2">
          <div className="fs--1 text-uppercase fw-semi-bold">
            Step {data.index}
          </div>
          <div className="mx-2">
            <MdSettings onClick={() => updateSelectStepConfig(id)} />
          </div>
          <div className="me-2">
            <BiMessageSquareEdit onClick={handleStepEdit} />
          </div>

          <div className="">
            <MdDeleteOutline onClick={onStepDelete} />
          </div>
        </div>
        <div
          className={classNames(
            'reactflow_custom_node bg-white position-relative p-2 shadow rounded',
            {
              'bg-soft-danger': data.detailStatus === 'pending',
              'opacity-50': data.status === 'not-proccessed'
            }
          )}
        >
          <div className="line-clamp-2 fs--1 fw-medium" title={formData.name}>
            {formData.name}
          </div>
          <div
            title={formData.description}
            className="line-clamp-2 mt-2  fs--1 fw-medium text-black"
          >
            {formData.description}
          </div>
          <div className="mt-3">
            <TagView data={data?.tags || []} maxTagShow={1} />
          </div>
        </div>
      </div>
      <>
        {[
          0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
          20
        ].map(i => (
          <Handle
            key={'target_left' + i}
            type="target"
            position={HANDLE_POSITION['left']}
            className="sourceConnectLeftLine custom"
            style={{
              top: 35 + 6 * i
            }}
            id={'l' + String(i)}
            isConnectableStart={false}
            isConnectableEnd={true}
          />
        ))}
      </>
      <>
        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].map(i => (
          <Handle
            key={'target_bottom' + i}
            type="target"
            position={HANDLE_POSITION['bottom']}
            className="sourceConnectBottomtLine custom"
            style={{
              left: 3 + 6 * i
            }}
            id={'bs' + String(i)}
            isConnectableStart={false}
            isConnectableEnd={true}
          />
        ))}
      </>
      <>
        {[
          0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
          20
        ].map(i => (
          <Handle
            key={'target_top' + i}
            type="target"
            position={HANDLE_POSITION['top']}
            className="sourceConnectToptLine custom"
            style={{
              left: 3 + 6 * i
            }}
            id={'ts' + String(i)}
            isConnectableStart={false}
            isConnectableEnd={true}
          />
        ))}
      </>
      <>
        {[
          0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
          20
        ].map(i => (
          <Handle
            key={'source_right' + i}
            type="source"
            position={HANDLE_POSITION['right']}
            className="left-0 targetConnectRightLine custom"
            style={{
              top: 35 + 6 * i
            }}
            id={'r' + String(i)}
            isConnectableStart={true}
            isConnectableEnd={false}
          />
        ))}
      </>
      <>
        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].map(i => (
          <Handle
            key={'source_bottom' + i}
            type="source"
            position={HANDLE_POSITION['bottom']}
            className="targetConnectBottomtLine custom"
            style={{
              right: -3 + 6 * i
            }}
            id={'bt' + String(i)}
            isConnectableStart={true}
            isConnectableEnd={false}
          />
        ))}
      </>
      <>
        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(i => (
          <Handle
            key={'source_top' + i}
            type="source"
            position={HANDLE_POSITION['top']}
            className="targetConnectTopLine custom"
            style={{
              right: -3 + 6 * i
            }}
            id={'tt' + String(i)}
            isConnectableStart={true}
            isConnectableEnd={false}
          />
        ))}
      </>
    </>
  );
});
