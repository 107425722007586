import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { StepContext } from 'context/Context';
import { stepReducer } from 'reducers/stepReducer';
import {
  updateKillchainExecutionStep,
  updateStepService,
  updateStepsService
} from 'services/killchainService';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  findIndexChangedNodes,
  findIndexOfSteps,
  refectorToStepWithIndex
} from 'helpers/utils';
import useError from 'hooks/useError';

const LABEL_DATA = {
  success: { label: 'SUCCESS', className: 'success' },
  failure: { label: 'FAILURE', className: 'danger' }
};
const TOGGLE_LABEL_DATA = {
  failure: {
    label: 'SUCCESS',
    className: 'success',
    key: 'success'
  },
  success: {
    label: 'FAILURE',
    className: 'danger',
    key: 'failure'
  }
};

const StepProvider = ({ children }) => {
  const { getResponse } = useError();
  const location = useLocation();
  const initData = {
    nodes: [],
    edges: [],
    techniques: [],
    scenarioType: null,
    selectedStep: null,
    selectedStepConfig: null,
    showStepData: false,
    currentKillchain: null,
    selectedDeleteStep: null,
    selectedStepForSchedule: null,
    isKillchainFetching: false
  };
  const navigate = useNavigate();
  const [stepState, dispatch] = useReducer(stepReducer, initData);

  const updateKillchainFetchingStatus = val => {
    dispatch({ type: 'KILLCHAIN_FETCH_STATUS', payload: val });
  };

  const updateScenarioType = type => {
    dispatch({
      type: 'UPDATE_SCENARIO_TYPE',
      payload: type
    });
  };

  const addNodesToStep = newNodes => {
    dispatch({ type: 'ADD_NODES_TO_STEP', payload: newNodes });
  };

  const addEdgesToStep = newEdges => {
    dispatch({ type: 'ADD_EDGES_TO_STEP', payload: newEdges });
  };

  const addHandleToStartNode = handleData => {
    dispatch({ type: 'ADD_HANDLE_TO_OUTPUT', payload: handleData });
  };

  const addHandleToEndNode = handleData => {
    dispatch({ type: 'ADD_HANDLE_TO_INPUT', payload: handleData });
  };

  const updateEdge = params => {
    let handleLabel = { 0: 'a', 1: 'b', 2: 'c' };
    let targetNode = stepState.nodes.find(n => n.id === params.target);
    let targetNodeOutLen = targetNode.data.output.length;
    let sourceNode = stepState.nodes.find(n => n.id === params.source);
    let sourceNodeInLen = sourceNode.data.inputs.length;

    let edgeParams = {
      source: params.target,
      target: params.source,
      sourceHandle: params.target + 'o' + handleLabel[targetNodeOutLen],
      targetHandle: params.source + 'i' + handleLabel[sourceNodeInLen]
    };

    let DOT_POS = {
      0: {
        left: { top: 20, bottom: 'auto' },
        right: { top: 20, bottom: 'auto' }
      },
      1: {
        left: { bottom: 20, top: 'auto' },
        right: { bottom: 20, top: 'auto' }
      },
      2: {
        left: { bottom: 40, top: 'auto' }
      }
    };

    const startHandleData = {
      nodeId: params.target,
      handleId: edgeParams.sourceHandle,
      position: 'right',
      dotPositon: DOT_POS[targetNodeOutLen]['right']
    };

    const endHandleData = {
      nodeId: params.source,
      handleId: edgeParams.targetHandle,
      position: 'left',
      dotPositon: DOT_POS[sourceNodeInLen]['left']
    };

    addHandleToStartNode(startHandleData);
    addHandleToEndNode(endHandleData);

    let labelKey;
    switch (targetNode.data.output.length) {
      case 0:
        labelKey = 'success';
        break;
      case 1:
        labelKey = 'failure';
        break;
      default:
        break;
    }
    // let edgeId = //reactflow__edge-2sourceConnectLeftLine-1targetConnectRightLine
    //   'reactflow__edge-' +
    //   edgeParams.source +
    //   edgeParams.sourceHandle +
    //   '-' +
    //   edgeParams.target +
    //   edgeParams.targetHandle;
    dispatch({
      type: 'UPDATE_EDGE',
      payload: {
        // id: edgeId,
        // ...edgeParams,
        ...params,
        animated: true,
        type: 'smoothstep',
        label: LABEL_DATA[labelKey].label,
        labelKey: labelKey,
        className: LABEL_DATA[labelKey].className
      }
    });
  };

  const addEdgeToStore = async edge => {
    dispatch({ type: 'ADD_EDGE', payload: edge });
  };

  const showStepData = id => {
    dispatch({ type: 'SHOW_STEP', payload: id });
  };

  const updateNode = keyValue => {
    dispatch({ type: 'UPDATE_NODE', payload: keyValue });
  };

  const updateNodeDataProps = (id, props) => {
    dispatch({
      type: 'UPDATE_NODE_DATA_PROPS_LOCAL',
      payload: { id: id, props }
    });
  };

  const toggleEdgeLabel = async id => {
    let edges = stepState.edges.slice();
    let edgeIndex = edges.findIndex(e => e.id === id);
    let currentEdge = edges[edgeIndex];
    // check how much edge from target
    let currentEdgeTargetEdges = edges.filter(
      e => e.source === currentEdge.source
    );
    // 1 then change
    // 2 then toggle
    switch (currentEdgeTargetEdges.length) {
      case 1:
        edges[edgeIndex] = {
          ...currentEdge,
          labelKey: TOGGLE_LABEL_DATA[currentEdge.labelKey].key,
          label: TOGGLE_LABEL_DATA[currentEdge.labelKey].label,
          className: TOGGLE_LABEL_DATA[currentEdge.labelKey].className
        };
        break;
      case 2:
        currentEdgeTargetEdges.forEach(ed => {
          let edgeIndex = edges.findIndex(e => e.id === ed.id);
          edges[edgeIndex] = {
            ...ed,
            labelKey: TOGGLE_LABEL_DATA[ed.labelKey].key,
            label: TOGGLE_LABEL_DATA[ed.labelKey].label,
            className: TOGGLE_LABEL_DATA[ed.labelKey].className
          };
        });
        break;
      default:
        break;
    }
    const updatedEdge = edges[edgeIndex];
    dispatch({ type: 'TOGGLE_LABEL', payload: edges });
    const node = stepState.nodes.find(n => n.id === updatedEdge.source);
    let successStepId = node.data.successStepId;
    let failureStepId = node.data.failureStepId;
    /**
     * case 1: there might be only one edge
     * case 2: there might be two edge
     * Going through all edges to update respective values for success/failure step
     */
    let allEdgesOfNode = edges.filter(e => e.source === node.id);
    allEdgesOfNode.forEach(ed => {
      let targetNode = stepState.nodes.find(n => n.id === ed.target);
      if (ed.className === 'success') {
        successStepId = targetNode.data.stepId;
      } else {
        failureStepId = targetNode.data.stepId;
      }
    });

    let data = {
      name: node.data.name,
      // stepId: node.data.stepId,
      edges: allEdgesOfNode,
      successStepId: successStepId,
      failureStepId: failureStepId
    };
    const res = await updateStepService(node.data.stepId, data);
    await getResponse(res)
      .then(res => {
        updateNodeDataProps(node.id, { successStepId, failureStepId });
      })
      .catch(err => console.error(err));
  };

  const updateNodePosition = async data => {
    const node = stepState.nodes.find(n => n.id === data.id);
    dispatch({ type: 'UPDATE_NODE_POSITION', payload: data });
    const res = await updateStepService(node.data.stepId, {
      node: data
    });
    getResponse(res).catch(err => console.error(err));
  };

  const resetStep = () => dispatch({ type: 'RESET_STEP', payload: initData });

  const updateKillchain = id => {
    dispatch({ type: 'UPDATE_KILLCHAIN', payload: id });
  };

  const updateNodeData = async (id, data) => {
    const node = stepState.nodes.find(n => n.id === id);
    node.data = data;
    dispatch({ type: 'UPDATE_NODE_DATA', payload: node });
    const res = await updateStepService(node.data.stepId, {
      name: data.name,
      description: data.description
    });
    getResponse(res).catch(err => console.error(err));
  };

  const updateSelectStepConfig = payload => {
    dispatch({ type: 'UPDATE_STEP_CONFIG', payload });
  };

  const updateStoreNodeIndex = payload => {
    let nodesCopy = stepState.nodes.slice();
    let newNodes = nodesCopy.map(node => {
      let isFoundNode = payload.find(pnode => pnode.id === node.id);
      if (isFoundNode) {
        node.data.index = isFoundNode.data.index == 2 ? 1 : 2;
      }
      return node;
    });
    dispatch({ type: 'UPDATE_NODES_INDEX', payload: newNodes });
  };

  const updateDeleteStep = id => {
    dispatch({ type: 'UPDATE_SELECTED_DELETE_STEP', payload: id });
  };

  const deleteEdgesFromStore = ids => {
    dispatch({ type: 'DELETE_STEP_EDGES', payload: ids });
  };
  const deleteNode = id => {
    dispatch({ type: 'DELETE_STEP_NODE', payload: id });
  };
  const updateConnectionOfEdge = async (oldEdge, newConnection) => {
    let newid =
      'reactflow__edge-' +
      newConnection.source +
      newConnection.sourceHandle +
      '-' +
      newConnection.target +
      newConnection.targetHandle;

    // api call to update edge in source
    const node = stepState.nodes.find(n => n.id === newConnection.source);
    let successStepId = node.data.successStepId;
    let failureStepId = node.data.failureStepId;
    let targetNode = stepState.nodes.find(n => n.id === newConnection.target);
    if (oldEdge.className === 'success') {
      successStepId = targetNode.data.stepId;
    } else {
      failureStepId = targetNode.data.stepId;
    }
    const edgeData = {
      id: oldEdge.id,
      data: { ...oldEdge, ...newConnection, id: newid }
    };
    let data = {
      name: node.data.name,
      // stepId: node.data.stepId,
      edges: [
        ...stepState.edges
          .filter(e => e.source === node.id)
          .filter(
            e =>
              e.source != oldEdge.source &&
              e.sourceHandle != oldEdge.sourceHandle
          ),
        edgeData
      ],
      successStepId: successStepId,
      failureStepId: failureStepId
    };
    const edgesDump = stepState.edges.slice();
    dispatch({
      type: 'UPDATE_CONNECTION_OF_EDGE',
      payload: edgeData
    });

    const res = await updateStepService(node.data.stepId, data);
    getResponse(res)
      .then(async res => {
        // update index
        // const newNodes = findIndexOfSteps(stepState.nodes, stepState.edges);
        // const updatedNodes = findIndexChangedNodes(stepState.nodes, newNodes);
        // if (updatedNodes.length != 0) {
        //   const updateStepIndexData = refectorToStepWithIndex(updatedNodes);
        //   const updateStepsRes = await updateStepsService(updateStepIndexData);
        //   getResponse(updateStepsRes)
        //     .then(res => {
        //       updateStoreNodeIndex(updatedNodes);
        //     })
        //     .catch(err => {});
        // }
        //  update node data for success & failure step id
        updateNodeDataProps(node.id, { successStepId, failureStepId });
      })
      .catch(err => {
        dispatch({ type: 'RESET_PREV_UPDATED_EDGE', payload: edgesDump });
      });
  };
  const removeEdge = async id => {
    /**
     * Finding edge by id and node by source of that edge
     */
    const edge = stepState.edges.find(e => e.id === id);
    const node = stepState.nodes.find(n => n.id === edge.source);

    // storing the success/failure step ids from node to update later
    let successStepId = node.data.successStepId;
    let failureStepId = node.data.failureStepId;

    /**
     * updating step id to null for edge based on type
     * Basically if edge has success connection then success will be removed
     * so it should be null and same goes for failure
     */
    if (edge.className === 'success') {
      successStepId = null;
    } else {
      failureStepId = null;
    }

    // preparing the data for request to backend
    let data = {
      name: node.data.name,
      // stepId: node.data.stepId,
      edges: stepState.edges
        .filter(e => e.source === node.id)
        .filter(e => e.id != id),
      successStepId: successStepId,
      failureStepId: failureStepId
    };

    // dumping the data to restore in case of failure of request
    const edgesDump = stepState.edges.slice();

    // removing edge from data
    dispatch({ type: 'REMOVE_EDGE', payload: id });

    // requesting to backend for step update
    const res = await updateStepService(node.data.stepId, data);
    getResponse(res)
      .then(async res => {
        // update index
        //  update node data for success & failure step id
        updateNodeDataProps(node.id, { successStepId, failureStepId });
      })
      .catch(err => {
        // handling failure case to restore the data
        dispatch({ type: 'RESET_PREV_UPDATED_EDGE', payload: edgesDump });
      });
  };

  const handlePausePlayStep = async (id, status) => {
    const node = stepState.nodes.find(n => n.id == id);
    const res = await updateKillchainExecutionStep(
      stepState.currentKillchain,
      node.data.stepId,
      {
        isPaused: status
      }
    );
    getResponse(res)
      .then(res => {
        updateNodeDataProps(id, { isPaused: status });
        let label = status == true ? 'paused' : 'played';
        toast(<span className="text-success">Step successfully {label}.</span>);
      })
      .catch(err => console.error(err));
  };

  const updateSelectedStepForSchedule = value => {
    dispatch({ type: 'UPDATE_SELECTE_STEP_FOR_SCHEDULE', payload: value });
  };

  const handleDeleteNode = id => {
    let remainingNodes = stepState.nodes.filter(n => n.id != id);
    let remainingEdges = stepState.edges.filter(
      e => e.source !== id && e.target !== id
    );
    dispatch({
      type: 'DELETE_NODE',
      payload: { nodes: remainingNodes, edges: remainingEdges }
    });
  };

  return (
    <StepContext.Provider
      value={{
        stepState,
        updateKillchainFetchingStatus,
        updateScenarioType,
        addNodesToStep,
        addEdgesToStep,
        addHandleToStartNode,
        addHandleToEndNode,
        updateEdge,
        addEdgeToStore,
        showStepData,
        updateNode,
        toggleEdgeLabel,
        updateNodePosition,
        resetStep,
        updateKillchain,
        updateNodeData,
        updateSelectStepConfig,
        updateNodeDataProps,
        updateStoreNodeIndex,
        updateDeleteStep,
        deleteEdgesFromStore,
        deleteNode,
        removeEdge,
        updateConnectionOfEdge,
        handlePausePlayStep,
        updateSelectedStepForSchedule,
        handleDeleteNode
      }}
    >
      {children}
    </StepContext.Provider>
  );
};

StepProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default StepProvider;
