import React from 'react';
import ContentLoader from 'react-content-loader';

export default function TableLoader() {
  return (
    <ContentLoader width={'100%'} height={163} foregroundColor="#dedede">
      <rect x="0" y="0" width={'100%'} height="40" />
      <rect x="0" y="41" width={'100%'} height="40" />
      <rect x="0" y="82" width={'100%'} height="40" />
      <rect x="0" y="123" width={'100%'} height="40" />
    </ContentLoader>
  );
}
