import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';

export default function IntegrationFormatForm({
  title,
  integrationOptions,
  data,
  handleChange,
  disableIntegrationSelect = false,
  fields
}) {
  return (
    <Row>
      <Col sm={12}>
        <Form.Label>Select {title}</Form.Label>
        <Form.Select
          id="name"
          value={data.name}
          onChange={handleChange}
          className="text-700"
          disabled={disableIntegrationSelect}
        >
          <option className="text-700">Select...</option>
          {integrationOptions?.map(op => (
            <option className="text-700" value={op.value}>
              {op.label}
            </option>
          ))}
        </Form.Select>
      </Col>
      {fields?.map(f => (
        <Col sm={12} className="mt-1">
          <Form.Label>{f.label.toUpperCase()}</Form.Label>
          <Form.Control
            type="password"
            id={f.id}
            value={data[f.id]}
            onChange={handleChange}
            //   placeholder="e.g. hdwuhduhdn823u8hedhjd"
          />
        </Col>
      ))}
    </Row>
  );
}
