import { handleError } from 'helpers/utils';
import axios from './axiosInstance';

export const getTopAptsService = async () => {
  try {
    const resp = await axios.get('/aptAnalytics/topApts');
    return resp.data;
  } catch (err) {
    return handleError(err);
  }
};
