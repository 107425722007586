import React from 'react';
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';

export default function DateTimeComponent({ onChange, value }) {
  return (
    <DateTimePicker
      disableClock
      className="custom-datetime-picker"
      onChange={onChange}
      value={value}
    />
  );
}
