import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { BiLinkExternal } from 'react-icons/bi';
import { NotificationContext } from 'context/Context';
import { formatDate } from 'helpers/utils';
import usePagination from 'hooks/usePagination';
import { getNotificationsService } from 'services/userService';
import useError from 'hooks/useError';

export default function Notifications() {
  const { getResponse } = useError();
  const [isFetching, setFetching] = useState(false);
  const navigate = useNavigate();
  const { notificationState, saveAllNotifications } =
    useContext(NotificationContext);

  const getNotifications = useCallback(async page => {
    setFetching(true);
    const res = await getNotificationsService(page);
    getResponse(res)
      .then(res => {
        saveAllNotifications(res);
        setFetching(false);
      })
      .catch(err => {
        setFetching(false);
      });
  }, []);

  const { Pagination } = usePagination(getNotifications);

  useEffect(() => {
    getNotifications(1);
  }, []);

  return (
    <React.Fragment>
      <Row className="flex-between-center">
        <Col md>
          <h5 className="mb-2 mb-md-0">Notifications</h5>
        </Col>
      </Row>
      <div className="p-3">
        <Row className="bg-white py-3 pb-4">
          {notificationState?.data?.map(each => (
            <Col key={each?.id} sm={12}>
              <Row className="border rounded p-2 mt-3 mx-0 bg-200">
                <Col sm={8} className="fw-medium fs--1">
                  {each.message}
                  <BiLinkExternal
                    onClick={() => {
                      navigate(
                        `/assessments/${each?.meta?.killChainId}/executions/${each?.meta?.executionId}`
                      );
                    }}
                    className="ms-2"
                  />
                </Col>
                <Col sm={1}></Col>
                <Col sm={3} className="text-end fs--1 fw-medium">
                  {formatDate(each.createdAt)}
                </Col>
              </Row>
            </Col>
          ))}
          {notificationState?.data?.length == 0 ? (
            <Col>
              <div>No Notifications yet.</div>
            </Col>
          ) : null}
        </Row>
      </div>
      {notificationState?.total_pages ? (
        <Pagination totalPages={notificationState.total_pages} />
      ) : null}
    </React.Fragment>
  );
}
