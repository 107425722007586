import React, { useState, useEffect } from 'react';
import axios from '../../../services/axiosInstance';

// I want a json editor
const Workbench = () => {
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [page, setPage] = useState(1);
  const [numbers, setNumbers] = useState([]);

  const getWorkbenchList = async e => {
    try {
      e = e || page;
      const response = await axios.get(
        `/workbench?page=${e}`,
        {
          withCredentials: true
        }
      );
      setData(response.data.data.sort((a, b) => b.createdAt - a.createdAt));
      setTotalPages(response.data.total_pages);
      setCurrentPage(response.data.page);
      // based on total pages, create an array of numbers
      setNumbers([...Array(response.data.total_pages).keys()].map(i => i + 1));
    } catch (error) {
      console.log(error);
    }
  };

  const handlePageChange = e => {
    setPage(e);
    setCurrentPage(e);
    getWorkbenchList(e);
  };

  useEffect(() => {
    getWorkbenchList();
  }, []);

  return (
    <div>
      <div className="d-flex justify-content-between">
        <h3>Workbench</h3>
        <div className="col-auto">
          <a className="btn btn-primary" href="/workbench/new">
            New Task
          </a>
        </div>
      </div>
      {/* key, agentId, status, createdAt, updatedAt */}
      <table className="table table-striped">
        <thead className="thead-dark">
          <tr key="header">
            <th>Key</th>
            <th>Name</th>
            <th>Created</th>
            <th>Updated</th>
            <th>Actions</th>
          </tr>
          {data?.map((item, key) => (
            <tr key={key}>
              <td>{key + 1}</td>
              <td>{item?.name}</td>
              <td>{item.createdAt}</td>
              <td>{item.updatedAt}</td>
              <td>
                <a className="btn btn-primary" href={`/workbench/${item.id}`}>
                  View
                </a>
              </td>
            </tr>
          ))}
        </thead>
      </table>

      {data?.length > 0 && (
        <div className="row justify-content-end">
          <div className="col-auto">
            <nav aria-label="Page navigation example">
              <ul className="pagination">
                <li
                  className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}
                  onClick={() => handlePageChange(1)}
                >
                  <span className="page-link" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                  </span>
                </li>
                {/* use ... like 1..currentPage..last */}
                {data.length > 0 &&
                  numbers.map((item, key) => (
                    <li
                      className={`page-item ${item} ${
                        currentPage === item ? 'active' : ''
                      }`}
                      onClick={() => handlePageChange(item)}
                    >
                      <span className="page-link">{item}</span>
                    </li>
                  ))}
                <li
                  className={`page-item ${
                    currentPage === totalPages ? 'disabled' : ''
                  }`}
                  onClick={() => handlePageChange(totalPages)}
                >
                  <span className="page-link" aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                  </span>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      )}
    </div>
  );
};

export default Workbench;
