import classNames from 'classnames';
import React from 'react';

export default function ActionPreview({
  bgVariant = null,
  txVariant = null,
  children
}) {
  const bgColor = `bg-${bgVariant}`;
  const txColor = `text-${txVariant}`;
  return (
    <div
      className={classNames({
        [bgColor]: bgVariant != null,
        [txColor]: txVariant != null
      })}
    >
      <div className="p-3">{children}</div>
    </div>
  );
}
