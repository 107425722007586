import React from 'react';
import { Card } from 'react-bootstrap';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { BarChart } from 'echarts/charts';
import {
  DatasetComponent,
  GridComponent,
  TitleComponent,
  TooltipComponent,
  LegendComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import CardHeader from 'components/common/CardHeader';
import Flex from 'components/common/Flex';
import {
  convertAssessmentSnapshotToRequiredGraphFormat,
  getColor
} from 'helpers/utils';
import useDimention from 'hooks/useDimention';
import { useRef } from 'react';
import { useEffect } from 'react';
import ShadowPopup from 'components/common/ShadowPopup';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer,
  DatasetComponent,
  LegendComponent
]);

const getOption = data => ({
  legend: {
    data: ['Prevented', 'Detected']
  },
  tooltip: {
    trigger: 'item',
    padding: [7, 10],
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-300'),
    textStyle: { color: getColor('dark') },
    borderWidth: 1,
    transitionDuration: 0,
    formatter: function (params) {
      return `<div class="text-center"><div class="fs--1 text-600">
        <strong>${params.value}</strong></div>
        <small class="text-500">Tasks</small>
        <small class="text-500">${params.seriesName}</small>
        </div>`;
    }
  },
  xAxis: {
    type: 'category',
    data: data.week.cates,
    axisLabel: { color: getColor('gray-400'), rotate: 75 },
    axisLine: {
      lineStyle: {
        color: getColor('gray-300'),
        type: 'dashed'
      }
    },
    axisTick: false,
    boundaryGap: true,
    interval: 0
  },
  yAxis: {
    axisPointer: { type: 'none' },
    axisTick: 'none',
    splitLine: {
      lineStyle: {
        color: getColor('gray-300'),
        type: 'dashed'
      }
    },
    axisLine: { show: false },
    axisLabel: { color: getColor('gray-400') },
    min: 0
  },
  series: [
    {
      name: 'Detected',
      type: 'bar',
      data: data.week.data.detected,
      barWidth: '10px',
      barGap: '30%',
      label: { show: false },
      stack: 'A',
      z: 10,
      itemStyle: {
        borderRadius: [0, 0, 0, 0],
        color: getColor('danger')
      }
    },
    {
      name: 'Prevented',
      type: 'bar',
      data: data.week.data.prevented,
      barWidth: '10px',
      barGap: '30%',
      label: { show: false },
      stack: 'A',
      z: 10,
      itemStyle: {
        borderRadius: [10, 10, 0, 0],
        color: getColor('success')
      }
    }
  ],
  grid: { right: 0, left: 0, bottom: 0, top: 32, containLabel: true }
});

export default function VulnerabilitiesSnapshot({ data }) {
  // const { ref, width } = useDimention();
  const getData = () => {
    const _data = data?.assessment_snapshot || [];
    const refactoredData =
      convertAssessmentSnapshotToRequiredGraphFormat(_data);
    return getOption(refactoredData);
  };

  return (
    <Card>
      <CardHeader
        title="Assessment Snapshot (Detected/Prevented)"
        titleTag="h5"
        className="py-3 border-bottom"
        titleClass="text-black"
      />
      <div className="mb-2">
        <Flex className="justify-content-center">
          <div
            style={{ backgroundColor: '#F2F2F2' }}
            className="py-2 px-4 text-center rounded-3 w-100"
          >
            <div className="fs--1">Total</div>
            <div className="fs--1 d-flex justify-content-around">
              <p className="text-black fw-medium mb-0">
                {data?.detected_prevented_count_30_days
                  ?.find(e => e._id === 'detected')
                  ?.count.toLocaleString()}{' '}
                Detected
              </p>
              <p className="text-black fw-medium mb-0">
                {data?.detected_prevented_count_30_days
                  ?.find(e => e._id === 'prevented')
                  ?.count.toLocaleString()}{' '}
                Prevented
              </p>
            </div>
          </div>
        </Flex>
      </div>
      <Card.Body className="position-relative">
        {/* <div className="position-relative w-100" ref={ref}>
          {width > 0 && ( */}
        <ReactEChartsCore
          // ref={chartRef}
          echarts={echarts}
          option={getData()}
          style={{ height: '100%', minHeight: '17.75rem' }}
        />
        {/* )}
        </div> */}
        {data?.assessment_snapshot?.length === 0 && (
          <ShadowPopup>No data found.</ShadowPopup>
        )}
      </Card.Body>
    </Card>
  );
}
