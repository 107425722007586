import { handleError } from 'helpers/utils';
import axios from './axiosInstance';

export const getUserService = async () => {
  try {
    const resp = await axios.get('/user/profile');
    return resp.data;
  } catch (err) {
    return handleError(err);
  }
};

export const updateUserProfileService = async data => {
  try {
    const resp = await axios.post('/user/profile', data);
    return resp.data;
  } catch (err) {
    return handleError(err);
  }
};

export const changePasswordService = async data => {
  try {
    const resp = await axios.post('/user/change-password', data);
    return resp.data;
  } catch (err) {
    return handleError(err);
  }
};

export const getNotificationsService = async page => {
  let query = '';
  if (page) {
    query = `?page=${page}`;
  }
  try {
    const resp = await axios.get('/notification' + query);
    return resp.data;
  } catch (err) {
    return handleError(err);
  }
};
