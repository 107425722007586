import { generateQueryString, handleError } from 'helpers/utils';
import axios from './axiosInstance';

export const getOrganizationIntegrationService = async id => {
  try {
    const resp = await axios.get(
      '/integrations/organization/' + id + '/config'
    );
    return resp.data;
  } catch (err) {
    return handleError(err);
  }
};

export const createIntegrationForOrganizationService = async (
  id,
  integrationId,
  data
) => {
  try {
    const resp = await axios.post(
      '/integrations/organization/' + id + '/config',
      data
    );
    return resp.data;
  } catch (err) {
    return handleError(err);
  }
};

export const updateIntegrationForOrganizationService = async (
  id,
  integrationId,
  data
) => {
  try {
    const resp = await axios.patch(
      '/integrations/organization/' + id + '/config/' + integrationId,
      data
    );
    return resp.data;
  } catch (err) {
    return handleError(err);
  }
};

export const getIntegrationService = async () => {
  try {
    const resp = await axios.get('/integrations');
    return resp.data;
  } catch (err) {
    return handleError(err);
  }
};

export const createAgentIntegrationService = async (
  agentId,
  integrationInstanceId
) => {
  try {
    const resp = await axios.post('/agentIntegrations/' + agentId, {
      integrationInstanceId
    });
    return resp.data;
  } catch (err) {
    return handleError(err);
  }
};

export const getAgentIntegrationService = async agentId => {
  try {
    const resp = await axios.get('/agentIntegrations/' + agentId);
    return resp.data;
  } catch (err) {
    return handleError(err);
  }
};
