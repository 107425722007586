import React, { useRef } from 'react';
import CardHeader from 'components/common/CardHeader';
import { Card, Col, Form, Row } from 'react-bootstrap';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { PieChart } from 'echarts/charts';
import {
  GridComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { getColor } from 'helpers/utils';
import Flex from 'components/common/Flex';
import useDimensions from 'hooks/useDimention';
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  PieChart,
  CanvasRenderer
]);

const getOptions = data => ({
  color: [
    getColor('success'),
    getColor('danger'),
    getColor('primary'),
    getColor('warning')
  ],
  tooltip: {
    trigger: 'item',
    padding: [7, 10],
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-300'),
    textStyle: { color: getColor('dark') },
    borderWidth: 1,
    transitionDuration: 0,
    formatter: function (params) {
      return `<div><div>Project Type: ${params.name}</div><div>Total:- ${params.value}</div></div>`;
    }
  },
  legend: { show: false },
  series: [
    {
      name: 'Total',
      type: 'pie',
      radius: ['100%', '57%'],
      avoidLabelOverlap: false,
      itemStyle: {
        // borderWidth: 2,
        // borderColor: getColor('gray-100')
      },
      label: {
        show: false
      },
      emphasis: {
        scale: false
      },
      labelLine: { show: false },
      data: data
    }
  ]
});

export default function CategoryProjectsPie({ data }) {
  const chartRef = useRef(null);
  const { ref, width } = useDimensions();

  const getCateProjectData = () => {
    let cateData = [
      {
        id: 1,
        name: 'Success',
        value:
          data?.success_failure_count?.find(e => e.status == 'success')?._count
            ?.status || 0,
        color: 'success'
      },
      {
        id: 2,
        name: 'Failed',
        value:
          data?.success_failure_count?.find(e => e.status == 'failed')?._count
            ?.status || 0,
        color: 'danger'
      },
      {
        id: 3,
        name: 'Prevented',
        value:
          data?.detected_prevented_count_30_days?.find(
            e => e.status == 'prevented'
          )?._count?.status || 0,
        color: 'primary'
      },
      {
        id: 4,
        name: 'Detected',
        value:
          data?.detected_prevented_count_30_days?.find(
            e => e.status == 'detected'
          )?._count?.status || 0,
        color: 'warning'
      }
    ];
    return cateData;
  };

  return (
    <Card className="">
      <CardHeader
        title="Task Summary"
        titleTag="h5"
        className="py-3 border-bottom"
        titleClass="text-black"
      >
        <div className="text-end mt-1 fs--2 fw-bold text-500">Last 7 days</div>
      </CardHeader>
      <Card.Body>
        <Row>
          <Col xs={12} md={5}>
            <div
              className="w-100 d-flex justify-content-center position-relative"
              ref={ref}
            >
              <ReactEChartsCore
                ref={chartRef}
                echarts={echarts}
                option={getOptions(getCateProjectData())}
                style={{ height: '16.5rem', width: width }}
              />
              <div className="position-absolute top-50 start-50 translate-middle text-center">
                <p className="fs-4 mb-0 text-black font-sans-serif fw-bold">
                  {data?.assessments_count_30_days || 0}
                </p>
                <p className="fs--1 mb-0 text-black font-sans-serif fw-medium">
                  Total Assessments
                </p>
              </div>
            </div>
          </Col>
          <Col xs={12} md={7} className="d-flex align-items-center">
            <Row>
              {getCateProjectData().map(cate => (
                <Col md={6} key={cate.name}>
                  <Flex
                    className={'justify-content-between align-items-center'}
                  >
                    <Flex className={'align-items-center'}>
                      <div
                        style={{ width: 12, height: 12 }}
                        className={`rounded-circle bg-${cate.color} me-2`}
                      ></div>
                      <h6 className="text-700 lh-base mb-0 fs--1">
                        {cate.name}
                      </h6>
                    </Flex>
                    <h6 className="text-black mb-0 fs--1 fw-semi-bold">
                      {cate.value}
                    </h6>
                  </Flex>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
