import Flex from 'components/common/Flex';
import SelectBox from 'components/common/SelectBox';
import TagBox from 'components/common/TagBox';
import SearchBox from 'components/navbar/top/SearchBox';
import { TechniqueContext } from 'context/Context';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDebounce } from 'use-debounce';

let osData = [
  { value: '', label: 'Select' },
  { value: 'windows', label: 'Windows' },
  { value: 'macos', label: 'Mac' },
  { value: 'debian', label: 'Debian' }
];

export default function StepForm() {
  const { updateTechniqueFilters } = useContext(TechniqueContext);
  const [search, setSearch] = useState('');
  const [debouncedText] = useDebounce(search, 1000);
  const [selectedEnv, setEnv] = useState(null);
  const [tags, setTags] = useState([]);

  useEffect(() => {
    updateTechniqueFilters(debouncedText, selectedEnv?.value, tags);
  }, [debouncedText, selectedEnv, tags]);

  return (
    <div>
      <Row>
        <Col md={4} className="pe-0">
          <Flex>
            <SearchBox
              inputClass="bg-transparent border-0 border-bottom rounded-0"
              searchInputValue={search}
              setSearchInputValue={setSearch}
            />
          </Flex>
        </Col>
        <Col md={8} className="pe-0">
          <TagBox
            label={'Tags'}
            id="tag_search_box"
            tags={tags}
            updateTags={setTags}
            key={tags}
          />
        </Col>

        {/* <Col md={4}>
          <TagBox label={'Tag Sets'} id="tagset_search_box" />
        </Col> */}
      </Row>
      <Row className="my-3">
        {/* <Col md={3} className="pe-0">
          <SelectBox
            title={'Period of time'}
            options={[{ value: 'all', label: 'All' }]}
          />
        </Col> */}
        <Col md={6} className="pe-0">
          <SelectBox
            title={'Environment'}
            options={osData}
            value={selectedEnv}
            onChange={setEnv}
          />
        </Col>
        <Col md={6}>
          <SelectBox
            title={'Tactics'}
            options={[{ value: '', label: 'Select' }]}
          />
        </Col>
      </Row>
    </div>
  );
}
