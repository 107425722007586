import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import ActionButton from './ActionButton';
import { v4 as uuidv4 } from 'uuid';
import { convertTechniqueFormSchemaIntoFormat } from 'helpers/utils';

const DynamicForms = ({
  formsData,
  // ActionComponent,
  variables,
  onFormUpdate
}) => {
  const [forms, setForms] = useState([]);

  useEffect(() => {
    let copy = convertTechniqueFormSchemaIntoFormat(formsData, variables);
    setForms(copy);
  }, [formsData, variables]);

  useEffect(() => {
    onFormUpdate(forms);
  }, [forms]);

  const handleInputChange = (formId, fieldIndex, event, childId) => {
    const { name, value } = event.target;
    let formCopy = forms.slice();
    let formIndex = formCopy.findIndex(f => f.id === formId);
    let cForms = formCopy[formIndex].forms;
    let cfIndex = cForms.findIndex(cf => cf.id === childId);
    cForms[cfIndex].fields[fieldIndex].value = value;
    formCopy[formIndex].forms = cForms;
    setForms(formCopy);
  };

  const handleAddForm = (formId, max) => {
    let formCopy = forms.slice();
    let formIndex = formCopy.findIndex(f => f.id === formId);
    if (formCopy[formIndex].forms.length === max) {
      return;
    }
    let fields = formCopy[formIndex].fieldStructure.map(f => ({
      id: uuidv4(),
      ...f
    }));
    formCopy[formIndex].forms.push({
      id: uuidv4(),
      fields: fields
    });
    setForms(formCopy);
  };

  const handleRemoveForm = (formId, childId) => {
    let formCopy = forms.slice();
    let formIndex = formCopy.findIndex(f => f.id === formId);
    formCopy[formIndex].forms = formCopy[formIndex].forms.filter(
      cf => cf.id !== childId
    );
    setForms(formCopy);
  };

  const renderField = (formId, field, fieldIndex, childFormId) => {
    const { type, label, name, options } = field;
    let field_type = type === 'number' || type === 'text' ? 'input' : type;
    switch (field_type) {
      case 'input':
        return (
          <Form.Control
            type={type}
            name={name}
            className="mb-2"
            // defaultValue={field.default}
            readOnly
            onFocus={e => e.target.removeAttribute('readonly')}
            onBlur={e => e.target.setAttribute('readonly', '')}
            value={field.value}
            autoComplete="off"
            onChange={event =>
              handleInputChange(formId, fieldIndex, event, childFormId)
            }
          />
        );
      case 'textarea':
        return (
          <Form.Control
            as="textarea"
            name={name}
            className="mb-2"
            value={field.value || ''}
            onChange={event =>
              handleInputChange(formId, fieldIndex, event, childFormId)
            }
          />
        );
      case 'select':
        return (
          <Form.Control
            as="select"
            name={name}
            className="mb-2"
            value={field.value || ''}
            onChange={event =>
              handleInputChange(formId, fieldIndex, event, childFormId)
            }
          >
            {options.map((option, index) => (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            ))}
          </Form.Control>
        );
      case 'checkbox':
        if (field.multiple) {
          {
            options.map((option, index) => (
              <Form.Check
                key={option.value}
                type="checkbox"
                label={option.label}
                name={name}
                className="mb-2"
                checked={field.value == option.value}
                onChange={event =>
                  handleInputChange(formId, fieldIndex, event, childFormId)
                }
              />
            ));
          }
        } else {
          return (
            <Form.Check
              type="checkbox"
              label={label}
              name={name}
              className="mb-2"
              checked={field.value || false}
              onChange={event =>
                handleInputChange(formId, fieldIndex, event, childFormId)
              }
            />
          );
        }
      case 'radio':
        return (
          <Form.Group as={Row} className="mb-2">
            {options.map((option, index) => (
              <Col>
                <Form.Check
                  key={index}
                  type="radio"
                  label={option.label}
                  className="mb-0"
                  name={name}
                  value={option.value}
                  checked={field.value === option.value}
                  onChange={event =>
                    handleInputChange(formId, fieldIndex, event, childFormId)
                  }
                />
              </Col>
            ))}
          </Form.Group>
        );
      case 'file':
        return (
          <Form.Control
            type="file"
            name={name}
            className="mb-2"
            onChange={event =>
              handleInputChange(formId, fieldIndex, event, childFormId)
            }
          />
        );
      default:
        return null;
    }
  };

  return (
    <div>
      {forms.map((form, formIndex) => (
        <div key={form.id}>
          <h5>{form.label}</h5>
          <div className="fw-medium fs--1 mb-2">{form.description}</div>
          <Form>
            {form.forms.map((childForm, arrayIndex) => (
              <Row className="" key={childForm.id}>
                <Col>
                  <Form className="mb-2" key={childForm.id}>
                    {childForm.fields.map((field, fieldIndex) => (
                      <div key={field}>
                        <Form.Label className="mb-0">{field.label}</Form.Label>
                        {renderField(form.id, field, fieldIndex, childForm.id)}
                      </div>
                    ))}
                  </Form>
                </Col>

                {arrayIndex !== 0 ? (
                  <Col sm={2}>
                    <ActionButton
                      className="mt-4"
                      title={'Remove'}
                      icon={'trash-alt'}
                      onClick={() => handleRemoveForm(form.id, childForm.id)}
                    />
                  </Col>
                ) : null}
              </Row>
            ))}
            {form.multiple && form.forms.length < form.max && (
              <Button
                variant="primary"
                onClick={() => handleAddForm(form.id, form.max)}
              >
                Add More
              </Button>
            )}
          </Form>
          <hr />
        </div>
      ))}
      {/* <ActionComponent data={forms} /> */}
    </div>
  );
};

export default DynamicForms;

DynamicForms.defaultProps = {
  formsData: [
    {
      type: 'textarea',
      label: 'Textarea',
      placeholder: 'bar',
      default: 'foo',
      output_key: 'textarea',
      output_type: 'string'
    },
    {
      type: 'form_group',
      output_key: 'group1',
      is_multiple: true,
      required: true,
      max_count: 3,
      inputs: [
        {
          type: 'text',
          label: 'Text',
          placeholder: 'bar',
          default: 'foo',
          output_key: 'key1',
          output_type: 'string'
        },
        {
          type: 'number',
          label: 'Number',
          placeholder: 'bar',
          default: 1,
          output_key: 'key2',
          output_type: 'number'
        }
      ]
    }
  ]
  // ActionComponent: <div></div>
};
