import Flex from 'components/common/Flex';
import React, { useLayoutEffect, useState } from 'react';
import { Button, Col, Offcanvas, Row } from 'react-bootstrap';
import CreateAssessmentModal from './CreateAssessmentModal';
import { getTemplateById } from 'services/templateService';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { createKillChainService } from 'services/killchainService';
import useError from 'hooks/useError';

export default function TemplateFullDetailViewModal({
  data,
  show,
  handleClose
}) {
  const navigate = useNavigate();
  const { getResponse } = useError();
  const [isCreating, setCreating] = useState(false);
  const [showCreateAssessmentModal, setCreateAssessmentModal] = useState(false);
  const openCreateAssessmentModal = () => setCreateAssessmentModal(true);
  const closeCreateAssessmentModal = () => setCreateAssessmentModal(false);

  useLayoutEffect(() => {
    var styles = '.offcanvas{width: 40%!important;}';
    var styleSheet = document.createElement('style');
    styleSheet.innerText = styles;
    document.head.appendChild(styleSheet);
  }, []);

  const onCreateAssessment = async formData => {
    setCreating(true);
    const res = await createKillChainService({
      name: formData.name,
      templateId: data.id,
      projectId: formData.projectId
    });
    setCreating(false);
    getResponse(res)
      .then(res => {
        navigate(`/assessments/${res.id}/setup`);
      })
      .catch(err => console.error(err));
  };

  return (
    <React.Fragment>
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="end"
        backdrop="static"
      >
        <Offcanvas.Header closeButton className="border-bottom">
          <Offcanvas.Title>{data?.name}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Flex className="flex-column h-100">
            <div className="flex-grow-1">
              <div className="bg-white shadow">
                <div className="p-3 bg-primary text-white fw-medium">
                  Description
                </div>
                <div className="p-3 ">
                  <div className="fs--1 text-black fw-medium">
                    {data?.description}
                  </div>
                  {data?.tests && (
                    <div className="my-1 fs--1 text-black font-sans-serif">
                      Tests:- {data?.tests}
                    </div>
                  )}
                  {data?.Steps && (
                    <div className="my-1 fs--1 text-black font-sans-serif">
                      Scenarios:- {data?.Steps}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="border-top py-3 pb-2">
              <Row className="flex-between-center">
                <Col>
                  <Button
                    className="w-100"
                    variant="outline-primary"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col>
                  <Button
                    className="w-100"
                    variant="primary"
                    onClick={openCreateAssessmentModal}
                  >
                    Create Assessment
                  </Button>
                </Col>
              </Row>
            </div>
          </Flex>
        </Offcanvas.Body>
      </Offcanvas>
      <CreateAssessmentModal
        show={showCreateAssessmentModal}
        handleClose={closeCreateAssessmentModal}
        onCreate={onCreateAssessment}
        isLoading={isCreating}
      />
    </React.Fragment>
  );
}
