import React, { useState } from 'react';
import { Button, Modal, Form, Col, Row } from 'react-bootstrap';
import CloseButton from 'components/common/CloseButton';
import useGetAllProjects from 'hooks/useGetAllProjects';

function CreateAssessmentModal({ show, handleClose, onCreate, isLoading }) {
  const { projects, isFetching } = useGetAllProjects();
  const [formData, setFormData] = useState({});
  const handleCreate = () => {
    // TODO: validate fields
    onCreate(formData);
  };
  const handleChange = e => {
    let { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>Create Assessment</Modal.Title>
          <CloseButton onClick={handleClose} disabled={isLoading} />
        </Modal.Header>
        <Modal.Body>
          <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
            <Form.Label column sm={4}>
              Assessment Name*
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                id="name"
                type="text"
                className="text-700"
                placeholder="e.g. APT29 20-02-2023"
                onChange={handleChange}
              />
            </Col>
          </Form.Group>

          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formHorizontalPassword"
          >
            <Form.Label column sm={4}>
              Select Project
            </Form.Label>
            <Col sm={8}>
              <Form.Select
                id="projectId"
                onChange={handleChange}
                aria-label="Default select example"
                className="text-700"
              >
                <option className="text-700" value="-1">
                  Select...
                </option>
                {projects.map(p => (
                  <option className="text-700" value={p.id}>
                    {p.name}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="falcon-default"
            className="py-2 px-4"
            onClick={handleClose}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            className="py-2 px-4"
            onClick={handleCreate}
            disabled={isLoading}
          >
            {isLoading ? 'Loading...' : 'Create'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CreateAssessmentModal;
