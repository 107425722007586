import React, { useContext, useEffect } from 'react';
import { useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { FaWindows, FaApple, FaRedhat, FaCentos, FaRaspberryPi } from 'react-icons/fa';
import { GrDebian } from 'react-icons/gr';
import CloseButton from 'components/common/CloseButton';
import AgentPlatformBox from './AgentPlatformBox';
import Flex from 'components/common/Flex';
import { createAgentService } from 'services/agentService';
import { IntegrationContext } from 'context/Context';
import { toast } from 'react-toastify';
import useError from 'hooks/useError';

const AGENT_DATA = {
  windows: {
    // date YYYY-MM-DD
    name: `Agent - ${new Date().toLocaleDateString()} `,
    platform: 'windows',
    pid: 123,
    version: '1.0',
    privilege: 'admin',
    username: ' user1',
    location: 'C:/users/user1/Downloads',
    sleepMin: 1,
    sleepMax: 5
  },
  debian: {
    name: `Agent - ${new Date().toLocaleDateString()} `,
    platform: 'debian',
    pid: 123,
    version: '1.0',
    privilege: 'admin',
    username: ' user1',
    location: 'C:/users/user1/Downloads',
    sleepMin: 1,
    sleepMax: 5
  },
  macos: {
    name: `Agent - ${new Date().toLocaleDateString()}`,
    platform: 'macos',
    pid: 123,
    version: '1.0',
    privilege: 'admin',
    username: ' user1',
    location: 'C:/users/user1/Downloads',
    sleepMin: 1,
    sleepMax: 5
  },
  centos: {
    name: `Agent - ${new Date().toLocaleDateString()}`,
    platform: 'centos',
    pid: 123,
    version: '1.0',
    privilege: 'admin',
    username: ' user1',
    location: 'C:/users/user1/Downloads',
    sleepMin: 1,
    sleepMax: 5
  },
  redhat: {
    name: `Agent - ${new Date().toLocaleDateString()}`,
    platform: 'redhat',
    pid: 123,
    version: '1.0',
    privilege: 'admin',
    username: ' user1',
    location: 'C:/users/user1/Downloads',
    sleepMin: 1,
    sleepMax: 5
  },
  aarch64: {
    name: `Agent - ${new Date().toLocaleDateString()}`,
    platform: 'aarch64',
    pid: 123,
    version: '1.0',
    privilege: 'admin',
    username: ' user1',
    location: 'C:/users/user1/Downloads',
    sleepMin: 1,
    sleepMax: 5
  }
};

export default function AgentAddModal({ handleClose, show, onCreate }) {
  const { getResponse } = useError();

  const { integrationState } = useContext(IntegrationContext);
  const [selectedId, setSelectedId] = useState(null);
  const [isCreateProcessing, setCreateProcessing] = useState(false);
  const [siem, setSiem] = useState(false);
  const [edr, setEdr] = useState(false);
  const [selectedIntegration, setSelectedIntegration] = useState(null);
  const [name, setName] = useState('');
  const [data, setData] = useState({});
  const [platform, setPlatform] = useState(null);

  const handleDivClick = id => {
    setSelectedId(id);
    switch (id) {
      case 1:
        setPlatform('debian');
        setData(AGENT_DATA['debian']);
        break;
      case 2:
        setPlatform('windows');
        setData(AGENT_DATA['windows']);
        break;
      case 3:
        setPlatform('macos');
        setData(AGENT_DATA['macos']);
        break;
      case 4:
        setPlatform('redhat');
        setData(AGENT_DATA['redhat']);
        break;
      case 5:
        setPlatform('centos');
        setData(AGENT_DATA['centos']);
        break;
      case 6:
        setPlatform('aarch64');
        setData(AGENT_DATA['aarch64']);
        break;
      default:
        break;
    }
  };

  const createAgent = async () => {
    setCreateProcessing(true);
    data.name = name.length > 0 ? name : data.name;
    data.integrationId = selectedIntegration;
    const res = await createAgentService(data);
    setCreateProcessing(false);
    getResponse(res)
      .then(res => {
        toast(
          <span className="text-success">Agent Created Successfully!</span>
        );
        onCreate();
        handleClose();
      })
      .catch(err => console.error(err));
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>Add New Agent</Modal.Title>
          <CloseButton onClick={isCreateProcessing ? () => {} : handleClose} />
        </Modal.Header>
        <Modal.Body>
          <h5 className="text-center">Select Platform</h5>
          <Flex className={'justify-content-center align-items-start w-100'}>
            <AgentPlatformBox
              id={1}
              selected={selectedId === 1}
              disabled={false}
              onClick={handleDivClick}
              name="Debian"
              IconComponent={GrDebian}
            />
            <AgentPlatformBox
              id={2}
              selected={selectedId === 2}
              disabled={false}
              onClick={handleDivClick}
              name="Windows"
              IconComponent={FaWindows}
            />
            <AgentPlatformBox
              id={3}
              selected={selectedId === 3}
              disabled={false}
              onClick={handleDivClick}
              IconComponent={FaApple}
              name="Apple"
            />
            <AgentPlatformBox
              id={4}
              selected={selectedId === 4}
              disabled={false}
              onClick={handleDivClick}
              IconComponent={FaRedhat}
              name="Redhat / CentOs"
            />
            <AgentPlatformBox
              id={6}
              selected={selectedId === 6}
              disabled={false}
              onClick={handleDivClick}
              IconComponent={FaRaspberryPi}
              name="Raspbery Pi"
            />
          </Flex>

          {/* name input */}
          <div className="row justify-content-center mt-3">
            <div className="col-6">
              <div className="form-group">
                <label htmlFor="name">Agent Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Agent Name"
                  onChange={e => setName(e.target.value)}
                />
              </div>
            </div>
          </div>

          {/* do you have SIEM  question with radio buttons yes or no */}
          <div className="my-3 d-none">
            <h5 className="text-center">Do you have SIEM?</h5>
            <Flex className={'justify-content-center w-100'}>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="siem"
                  id="siem1"
                  value="option1"
                  onClick={() => setSiem(true)}
                />
                <label className="form-check-label" htmlFor="siem1">
                  Yes
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="siem"
                  id="siem2"
                  value="option2"
                  onClick={() => setSiem(false)}
                />
                <label className="form-check-label" htmlFor="siem2">
                  No
                </label>
              </div>
            </Flex>
          </div>
          {/* if yes then show the below fields */}
          {/* do you use EDR  */}
          {siem && (
            <div className="my-3">
              <h5 className="text-center">Do you use EDR?</h5>
              <Flex className={'justify-content-center w-100'}>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="edr"
                    id="edr1"
                    value="option1"
                    onClick={() => setEdr(true)}
                  />
                  <label className="form-check-label" htmlFor="edr1">
                    Yes
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="edr"
                    id="edr2"
                    value="option2"
                    onClick={() => setEdr(false)}
                  />
                  <label className="form-check-label" htmlFor="edr2">
                    No
                  </label>
                </div>
              </Flex>
            </div>
          )}

          {/* if yes then show the below fields */}
          {/* select form */}
          {siem && edr && (
            <div className="row justify-content-center my-3">
              <div className="col-8">
                <h5 className="text-center">Select Integration</h5>
                <Flex className={'justify-content-center w-100'}>
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    onChange={e => setSelectedIntegration(e.target.value)}
                  >
                    <option selected>Select Integration</option>
                    {integrationState?.allByOrg?.map((integration, index) => (
                      <option key={index} value={integration.id}>
                        {integration?.Integration?.name}
                      </option>
                    ))}
                  </select>
                </Flex>
              </div>
            </div>
          )}
          <div>
            {isCreateProcessing && (
              <Flex className="my-2 text-700 fw-medium align-items-center justify-content-center">
                <Spinner
                  animation="border"
                  size="sm"
                  className="br-nr-600 me-2"
                />{' '}
                Creating Agent...
              </Flex>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="falcon-primary"
            className="py-2 px-4"
            onClick={createAgent}
            disabled={isCreateProcessing || platform === null}
          >
            Create
          </Button>

          <Button
            variant="falcon-default"
            className="py-2 px-4"
            onClick={handleClose}
            disabled={isCreateProcessing}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
