import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import CloseButton from './CloseButton';
import DateTimeComponent from './DateTimeComponent';

export default function DateTimeModal({
  show,
  date,
  handleClose,
  isLoading,
  onSave
}) {
  const [value, onChange] = useState(date || new Date());
  const handleSave = () => {
    onSave(value);
  };
  return (
    <React.Fragment>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Schedule Step</Modal.Title>
          <CloseButton onClick={handleClose} disabled={isLoading} />
        </Modal.Header>
        <Modal.Body>
          <DateTimeComponent onChange={onChange} value={value} />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="falcon-default"
            className="py-2 px-4"
            onClick={handleClose}
            size="sm"
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            size="sm"
            className="py-2 px-4 md-auto"
            onClick={handleSave}
            disabled={isLoading}
          >
            {isLoading ? 'Saving...' : 'Save'}
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}
