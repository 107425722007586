import React from 'react';
import SimpleBarReact from 'simplebar-react';
import { Table } from 'react-bootstrap';
import classNames from 'classnames';
import TableLoader from 'components/common/TableLoader';

const getTotal = (data, key) =>
  data.reduce((acc, val) => acc + Number(val[key]), 0);

const AssetSequenceTableRow = ({ item, isLast }) => {
  return (
    <tr>
      <td
        className={classNames(
          'align-middle font-sans-serif fw-medium text-nowrap',
          {
            'border-bottom-0': isLast
          }
        )}
      >
        <div className="d-flex align-items-center">
          <div
            style={{ width: 12, height: 12 }}
            className={`rounded-circle bg-success me-2`}
          ></div>
          Step 0
        </div>
      </td>
      <td
        className={classNames('align-middle', {
          'border-bottom-0': isLast
        })}
      >
        It is a long established fact that a reader will be distracted by the
        readable content of a page when looking at its layout. The point of
        using Lorem Ipsum is that
      </td>
      <td
        className={classNames('align-middle ', {
          'border-bottom-0': isLast
        })}
      >
        Pending
      </td>
    </tr>
  );
};
const AssetSequenceTable = ({ data, isFetching }) => {
  return (
    <Table className="fs--1 mb-0" style={{ tableLayout: 'fixed' }}>
      <thead
        className="text-black fw-medium"
        style={{ backgroundColor: '#eee' }}
      >
        <tr>
          <th className=" text-nowrap">Step</th>
          <th className=" text-nowrap">Scenario</th>
          <th className=" text-nowrap">Detected</th>
        </tr>
      </thead>
      <tbody>{renderTableRows(data, isFetching)}</tbody>
    </Table>
  );
};

export default AssetSequenceTable;

const renderTableRows = (data, isFetching) => {
  if (isFetching) {
    return (
      <tr>
        <td className="px-0 py-1" colSpan={7}>
          <TableLoader />
        </td>
      </tr>
    );
  }
  if (data.length == 0) {
    return (
      <tr>
        <td colSpan="7" className="text-center text-muted fs-medium fs-0">
          No results found
        </td>
      </tr>
    );
  }

  return data.map((item, index) => (
    <AssetSequenceTableRow
      key={item.id}
      item={item}
      isLast={data.length - 1 === index}
    />
  ));
};
