import CardHeader from 'components/common/CardHeader';
import React from 'react';
import { Card, Form } from 'react-bootstrap';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { LineChart } from 'echarts/charts';
import {
  GridComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { useRef } from 'react';
import useDimention from 'hooks/useDimention';
import { getRequiredGraphFormatOfAssessmentSummary } from 'helpers/utils';
import ShadowPopup from 'components/common/ShadowPopup';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  CanvasRenderer
]);

const getOptions = (data = []) => {
  data = getRequiredGraphFormatOfAssessmentSummary(data.slice());
  return {
    xAxis: {
      type: 'category',
      data: data?.map(item => item?._id),
      axisLabel: { rotate: 15 },
      showAll: true
    },
    yAxis: {
      type: 'value'
    },
    series: [
      {
        data: data?.map(item => item?.count),
        type: 'line'
      }
    ],
    grid: { left: 0, bottom: 0, right: 0, top: 10, containLabel: true }
  };
};

export default function TaskSummarySingleLine({ data }) {
  const chartRef = useRef(null);
  const { ref, width } = useDimention();

  return (
    <Card className="">
      <CardHeader
        title="Assessment Summary"
        titleTag="h5"
        className="py-3 border-bottom"
        titleClass="text-black"
      />
      <Card.Body>
        <div className="position-relative" ref={ref}>
          {width > 0 && (
            <ReactEChartsCore
              ref={chartRef}
              echarts={echarts}
              option={getOptions(data)}
              style={{ height: '20rem', width: width }}
            />
          )}
          {data?.length === 0 && <ShadowPopup>No data found.</ShadowPopup>}
        </div>
      </Card.Body>
    </Card>
  );
}
