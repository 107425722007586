import { Fragment, useCallback, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Breadcrumb, Button, Col, Row } from 'react-bootstrap';
import { useDebounce } from 'use-debounce';

import AssessmentView from './templates/AssessmentView';
import { getAllKillChainService } from 'services/killchainService';
import CreateAssessmentModal from './flow/CreateAssessmentModal';
import usePagination from 'hooks/usePagination';
import useError from 'hooks/useError';

export default function ProjectAssessments() {
  const { getResponse } = useError();
  const getAllAssementsByPage = n => {
    getAllAssements(id, n);
  };
  const { Pagination } = usePagination(getAllAssementsByPage);
  const [search, setSearch] = useState('');
  const [debouncedText] = useDebounce(search, 1000);
  const [show, setShow] = useState(false);
  const [assessments, setAssessments] = useState({});
  const [selectedAssessmentType, setAssessmentType] = useState({
    value: '',
    label: 'Select'
  });
  const [isAssessmentFetching, setAssessmentFetching] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  let { id } = useParams();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getAllAssements = useCallback(
    async (id, page) => {
      setAssessmentFetching(true);
      const res = await getAllKillChainService(
        id,
        page,
        debouncedText,
        selectedAssessmentType.value
      );
      getResponse(res)
        .then(res => {
          setAssessments(res);
          setAssessmentFetching(false);
        })
        .catch(err => {
          setAssessmentFetching(false);
        });
    },
    [debouncedText, selectedAssessmentType]
  );

  const handleAssessmentClick = item => {
    navigate(`/assessments/${item.id}/setup`);
  };

  useEffect(() => {
    getAllAssements(id, 1);
    return setAssessments({});
  }, [debouncedText, selectedAssessmentType]);

  return (
    <Fragment>
      <Breadcrumb
        className="d-flex mb-4"
        listProps={{ className: 'bg-white py-2 px-3' }}
      >
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/projects' }}>
          Projects
        </Breadcrumb.Item>
        <Breadcrumb.Item active>Assessments</Breadcrumb.Item>
      </Breadcrumb>
      <Row className="flex-between-center mb-3">
        <Col md>
          <h5 className="mb-2 mb-md-0 ms-2">Assessments</h5>
        </Col>
        <Col xs="auto">
          <Button variant="primary" className="me-2 mb-1" onClick={handleShow}>
            Create Assessment
          </Button>
        </Col>
      </Row>
      <AssessmentView
        data={assessments?.data || []}
        onAssessmentClick={handleAssessmentClick}
        isLoading={isAssessmentFetching}
        search={search}
        setSearch={setSearch}
        selectedAssessmentType={selectedAssessmentType}
        setAssessmentType={setAssessmentType}
      />
      {assessments?.total_pages ? (
        <Pagination totalPages={assessments.total_pages} />
      ) : null}
      <CreateAssessmentModal
        projectId={id}
        show={show}
        handleClose={handleClose}
      />
    </Fragment>
  );
}
