export const getNextSteps = (edges, selectedNodeId) => {
  const connectedNodes = new Set();

  function traverse(nodeId) {
    connectedNodes.add(nodeId);
    edges.forEach(edge => {
      if (edge.source === nodeId) {
        const targetNode = edge.target;
        if (!connectedNodes.has(targetNode)) {
          traverse(targetNode);
        }
      }
    });
  }

  traverse(selectedNodeId);

  // delete current node
  connectedNodes.delete(selectedNodeId);

  return Array.from(connectedNodes);
};
