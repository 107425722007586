import React, { useContext, useState } from 'react';
import AddEditStep from './AddEditStep';
import { AddStepContext, StepContext } from 'context/Context';
import { useEffect } from 'react';

export default function AddStep() {
  const {
    addStepState: { showModal },
    toggleModal
  } = useContext(AddStepContext);
  return (
    <React.Fragment>
      <AddEditStep
        title="Add Steps"
        show={showModal}
        handleClose={toggleModal}
      />
    </React.Fragment>
  );
}
