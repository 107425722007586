import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import ProjectHeader from './projects/ProjectHeader';
import ProjectsTable from './projects/ProjectsTable';

import useGetAllProjects from 'hooks/useGetAllProjects';
import usePagination from 'hooks/usePagination';
import CreateUpdateProjectModal from './projects/CreateUpdateProjectModal';
import {
  createProjectService,
  deleteProjectService,
  updateProjectService
} from 'services/projectService';
import { toast } from 'react-toastify';
import useError from 'hooks/useError';

export default function Projects() {
  const { getResponse } = useError();

  const [showCreate, setCreateShow] = useState(false);
  // const [showUpdate, setUpdateShow] = useState(false);
  const [creating, setCreating] = useState(false);

  const [deletingProject, setDeletingProject] = useState(null);
  const [selectProjectId, setSelectProjectId] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    description: ''
  });
  const handleCreateClose = () => setCreateShow(false);
  const handleCreateShow = () => setCreateShow(true);

  const handleUpdateClose = () => {
    setSelectProjectId(null);
    setFormData({
      name: '',
      description: ''
    });
  };

  const { projects, isFetching, getAllProjects, total_pages } =
    useGetAllProjects();
  const { Pagination } = usePagination(getAllProjects);

  const handleProjectEdit = item => {
    setFormData({
      name: item.name,
      description: item.description
    });
    setSelectProjectId(item.id);
  };

  const handleProjectDelete = async id => {
    setDeletingProject(id);
    const res = await deleteProjectService(id);
    getResponse(res)
      .then(res => {
        toast(
          <span className="text-success">Project successfully deleted.</span>
        );
        getAllProjects();
        setDeletingProject(null);
      })
      .catch(err => {
        setDeletingProject(null);
      });
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCreateSubmit = async () => {
    setCreating(true);
    const res = await createProjectService({
      ...formData
    });
    getResponse(res)
      .then(res => {
        toast(
          <span className="text-success">Project successfully created.</span>
        );
        getAllProjects();
        handleCreateClose();
        setCreating(false);
      })
      .catch(err => {
        setCreating(false);
      });
  };

  const handleEditSubmit = async () => {
    setCreating(true);
    const res = await updateProjectService(selectProjectId, {
      ...formData
    });

    getResponse(res)
      .then(res => {
        toast(
          <span className="text-success">Project successfully updated.</span>
        );
        getAllProjects();
        handleUpdateClose();
        setCreating(false);
      })
      .catch(err => {
        setCreating(false);
      });
  };

  return (
    <React.Fragment>
      <ProjectHeader handleShow={handleCreateShow} />

      <Card className="overflow-hidden my-4">
        <Card.Body className="p-0">
          <ProjectsTable
            data={projects}
            isFetching={isFetching}
            editAction={handleProjectEdit}
            deleteAction={handleProjectDelete}
            deletingProject={deletingProject}
          />
        </Card.Body>
      </Card>

      {total_pages ? <Pagination totalPages={total_pages} /> : null}
      {showCreate && (
        <CreateUpdateProjectModal
          loading={creating}
          show={showCreate}
          handleClose={handleCreateClose}
          onSubmit={handleCreateSubmit}
          handleChange={handleChange}
          btnText="Create"
          btnLoadingText="Creating"
          title="Create New Project"
          formData={formData}
        />
      )}
      {selectProjectId && (
        <CreateUpdateProjectModal
          loading={creating}
          handleChange={handleChange}
          onSubmit={handleEditSubmit}
          show={selectProjectId != null}
          handleClose={handleUpdateClose}
          btnText="Save"
          btnLoadingText="Saving"
          title="Update Project"
          formData={formData}
        />
      )}
    </React.Fragment>
  );
}
