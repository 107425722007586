import React, { useContext } from 'react';
import CardHeader from 'components/common/CardHeader';
import { Card, Table } from 'react-bootstrap';
// import { MdHelp, MdRefresh } from 'react-icons/md';
// import { FiPlusCircle } from 'react-icons/fi';
// import DropdownActions from 'components/common/DropdownActions';
import { TestContext } from 'context/Context';
import Test from '../test/Test';

export default function AssessmentSetupTest() {
  const { testState } = useContext(TestContext);
  return (
    <>
      <Card className="my-4">
        <CardHeader
          title="Tests"
          titleTag="h5"
          className="bg-primary py-3 border-bottom text-white"
          titleClass="text-white"
          // endEl={
          //   <div>
          //     <FiPlusCircle
          //       size={20}
          //       className="me-3 hover-pointer"
          //       // onClick={openSASM}
          //     />
          //     <MdRefresh size={20} className="me-3 hover-pointer" />
          //     <MdHelp size={20} className=" hover-pointer" />
          //   </div>
          // }
        />
        <Card.Body className="p-0 font-sans-serif">
          <Test />
          {/* <Table className="fs--1 mb-0" borderless>
            <thead className="bg-primary text-white">
              <tr>
                <th className="text-nowrap"></th>
                <th className="text-nowrap">Test name</th>
                <th className="text-nowrap  pt-0">Assets</th>
                <th className="text-nowrap pt-0 ">Hosted Agent</th>
                <th className="text-nowrap  pt-0">Scenarios</th>
                <th className="text-nowrap  pt-0">User Privileges</th>
                <th className="text-nowrap pt-0">Status</th>
                <th className="text-nowrap pt-0 text-end"></th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {renderTestTableRows(testState?.tests)}
            </tbody>
          </Table> */}
        </Card.Body>
      </Card>
    </>
  );
}

// const STATUS_DATA = {
//   success: 'Ready',
//   warning: 'Configure',
//   danger: 'Failed',
//   info: 'Pending'
// };

// const renderTestTableRows = data => {
//   if (data.length === 0) {
//     return (
//       <tr>
//         <td colSpan={8}>
//           <div className="text-center fw-medium">No result found.</div>
//         </td>
//       </tr>
//     );
//   }
//   return data.map(test => (
//     <tr className="fw-medium">
//       <td></td>
//       <td>{test.name}</td>
//       <td>{test?.assets ?? '-'}</td>
//       <td>{test?.hostedAgents ?? '-'}</td>
//       <td>{data.length}</td>
//       <td>{test?.privileges || 'System'}</td>
//       <td className={`text-${test?.status ?? 'info'}`}>
//         {STATUS_DATA[test?.status ?? 'info']}
//       </td>
//       {/* <td>
//         <DropdownActions actions={[]} />
//       </td> */}
//     </tr>
//   ));
// };
