import classNames from 'classnames';
import TableLoader from 'components/common/TableLoader';
import { StepContext } from 'context/Context';
import { getDateTimeByString } from 'helpers/utils';
import React from 'react';
import { useContext } from 'react';
import { Table } from 'react-bootstrap';
import ContentLoader from 'react-content-loader';
import { Link, useNavigate, useParams } from 'react-router-dom';

export const STATUS_COLOR = {
  completed: 'primary',
  success: 'success',
  pending: 'info',
  failed: 'danger',
  in_progress: 'warning'
};

export default function ExecutionResultTable({ data, isFetching }) {
  return (
    // <SimpleBarReact>
    <Table className="fs--1 mb-0" size="large">
      <thead className="project_table_header text-800">
        <tr>
          <th className="text-nowrap">Execution Id</th>
          <th className="text-nowrap ">Technique</th>
          <th className="text-nowrap ">Tactic</th>
          <th className="text-nowrap">Asset</th>
          <th className="text-nowrap ">Status</th>
        </tr>
      </thead>
      <tbody>
        <RenderTableRows data={data} isFetching={isFetching} />
      </tbody>
    </Table>
    // </SimpleBarReact>
  );
}

const ExecutionResultTableRow = ({ item, isLast }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { stepState: currentKillchain } = useContext(StepContext);

  //   const handleLink = () => {
  //     navigate(`/assessments/${id}/executions/${item.id}`);
  //   };

  let statusColor = STATUS_COLOR[item?.status];

  return (
    <tr>
      <td
        className={classNames(
          'align-middle font-sans-serif fw-medium text-nowrap',
          {
            'border-bottom-0': isLast
          }
        )}
      >
        <Link
          className="text-700"
          to={`/assessments/${id}/executions/${item.executionId}`}
        >
          {getDateTimeByString(item?.Execution?.createdAt)}
        </Link>
      </td>
      <td
        className={classNames('align-middle', {
          'border-bottom-0': isLast
        })}
      >
        {item?.data?.name ?? item?.data?.techniqueName}
      </td>

      <td
        className={classNames('align-middle', {
          'border-bottom-0': isLast
        })}
      >
        {item?.data?.tacticName || ''}
      </td>
      <td
        className={classNames('align-middle', {
          'border-bottom-0': isLast
        })}
      >
        {item?.Agent?.name}
      </td>
      <td
        className={classNames('align-middle', {
          'border-bottom-0': isLast
        })}
      >
        <span
          className={`rounded-pill py-1 px-3 text-white text-capitalize bg-${statusColor}`}
        >
          {item?.status}
        </span>
      </td>
    </tr>
  );
};

const RenderTableRows = ({ data, isFetching }) => {
  if (isFetching) {
    return (
      <tr>
        <td className="px-0 py-1" colSpan={7}>
          <TableLoader />
        </td>
      </tr>
    );
  }
  if (data.length == 0) {
    return (
      <tr>
        <td colSpan="7" className="text-center text-muted fs-medium fs-0">
          No results found
        </td>
      </tr>
    );
  }

  return data.map((item, index) => (
    <ExecutionResultTableRow
      key={item.id}
      item={item}
      isLast={data.length - 1 === index}
    />
  ));
};
