import { handleError } from 'helpers/utils';
import axios from './axiosInstance';

export const uploadOrgImageService = async (orgId, data) => {
  try {
    const resp = await axios.post(`/organization/${orgId}/upload`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return resp.data;
  } catch (err) {
    return handleError(err);
  }
};

export const getOrgImageService = async orgId => {
  try {
    const resp = await axios.get(`/organization/${orgId}/image`, {
      responseType: 'blob'
    });
    return resp.data;
  } catch (err) {
    return handleError(err);
  }
};
