import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import useError from 'hooks/useError';
import { StepContext } from 'context/Context';
import { updateStepService } from 'services/killchainService';
import SelectBox from 'components/common/SelectBox';
import { Button, Form } from 'react-bootstrap';
import { getNextSteps } from 'helpers/graph.helper';

function AdvanceOptionsForStep() {
  const { getResponse } = useError();
  const [apply, setApply] = useState(false);
  const {
    stepState: { selectedStepConfig, nodes, edges },
    updateSelectStepConfig,
    updateNodeDataProps
  } = useContext(StepContext);
  const [formData, setFormData] = useState({
    parserKey: null,
    filterOutputTo: null,
    filterOutputFileName: null
  });
  const [formOptions, setFormOptions] = useState({
    parserKey: {},
    filterOutputTo: []
  });

  useEffect(() => {
    if (selectedStepConfig) {
      let stepIndex = nodes.findIndex(n => n.id === selectedStepConfig);
      let { parserKey, filterOutputTo, filterOutputFileName, parserOptions } =
        nodes[stepIndex]?.data;
      let nextNodeIds = getNextSteps(edges, selectedStepConfig);
      let nextStepsIds = nodes
        .filter(n => nextNodeIds.includes(n.id))
        .map(n => ({
          value: n?.data?.stepId,
          label: `Step ${n?.data?.index}`
        }));
      // changing parserKey and filterOutputTo to react select format
      Object.keys(parserKey ?? {}).map(osType => {
        parserKey[osType] =
          parserOptions[osType].find(o => o.value === parserKey[osType]) ??
          null;
      });
      filterOutputTo = nextStepsIds.filter(o =>
        filterOutputTo.includes(o.value)
      );
      setFormData({
        ...formData,
        parserKey,
        filterOutputTo,
        filterOutputFileName
      });
      setFormOptions({
        ...formOptions,
        parserKey: parserOptions,
        filterOutputTo: nextStepsIds
      });
    }
  }, [selectedStepConfig]);

  const handleChange = (e, osType) => {
    setFormData({
      ...formData,
      [e.target.id]: { ...formData[e.target.id], [osType]: e.target.value }
    });
  };

  const onSave = async () => {
    const data = {
      filterOutputTo: formData.filterOutputTo.map(n => n.value),
      filterOutputFileName: formData.filterOutputFileName
    };
    let parserData = {};
    Object.keys(formData.parserKey ?? {}).map(osType => {
      if (formData.parserKey[osType]) {
        parserData[osType] = formData.parserKey[osType]?.value || null;
      }
    });
    data.parserKey = parserData;
    if (
      data.filterOutputTo.length === 0 ||
      Object.keys(data.parserKey).length === 0
    ) {
      toast(
        <span className="text-danger">Please select at least one option.</span>
      );
      return;
    }
    setApply(true);
    let stepId = nodes.find(n => n.id === selectedStepConfig).data.stepId;
    const res = await updateStepService(stepId, data);
    setApply(false);
    getResponse(res)
      .then(res => {
        updateNodeDataProps(selectedStepConfig, data);
        updateSelectStepConfig(null);
        toast(
          <span className="text-success">
            Configuration updated successfully.
          </span>
        );
      })
      .catch(err => console.error(err));
  };

  return (
    <div>
      {Object.keys(formOptions.parserKey).map(osType => (
        <React.Fragment key={osType}>
          <h4 className="text-capitalize">{osType}</h4>
          <div>
            <SelectBox
              title={'Parser'}
              options={formOptions.parserKey[osType]}
              value={formData.parserKey?.[osType]}
              onChange={value =>
                handleChange({ target: { id: 'parserKey', value } }, osType)
              }
              style={{ marginBottom: '1rem' }}
            />
          </div>
        </React.Fragment>
      ))}
      <SelectBox
        title={'Merge Parser Output'}
        options={formOptions.filterOutputTo}
        isMulti
        value={formData.filterOutputTo}
        onChange={value =>
          setFormData({
            ...formData,
            filterOutputTo: value
          })
        }
        style={{ marginBottom: '1rem' }}
      />
      <Form.Label>Output File Name</Form.Label>
      <Form.Control
        name="filterOutputFileName"
        value={formData.filterOutputFileName}
        type="text"
        placeholder="e.g. Linear"
        onChange={({ target }) => {
          setFormData({
            ...formData,
            filterOutputFileName: target.value
          });
        }}
      />
      <div className="pt-2 mt-2">
        <Button
          variant="primary"
          size="sm"
          className="py-2 px-4 md-auto"
          onClick={onSave}
          disabled={apply}
        >
          {apply ? 'Applying...' : 'Apply'}
        </Button>
      </div>
    </div>
  );
}

export default AdvanceOptionsForStep;
