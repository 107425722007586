import CardHeader from 'components/common/CardHeader';
import useError from 'hooks/useError';
import React, { useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { changePasswordService } from 'services/userService';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  oldPassword: Yup.string().required('Old password is required'),
  newPassword: Yup.string()
    .min(6, 'New password must be at least 6 characters')
    .required('New password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    .required('Confirm password is required')
});

const ChangePassword = () => {
  const { getResponse } = useError();

  const [changing, setChanging] = useState(false);
  const [formData, setFormData] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  });
  const [errors, setErrors] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  });

  const validateForm = async () => {
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      setErrors({ oldPassword: '', newPassword: '', confirmPassword: '' });
      return true;
      // Form data is valid, proceed with submission or further actions
    } catch (validationError) {
      const newErrors = {};
      validationError.inner.forEach(error => {
        newErrors[error.path] = error.message;
      });
      setErrors(newErrors);
      return false;
    }
  };
  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    let isValid = validateForm();
    if (!isValid) return;
    setChanging(true);
    const res = await changePasswordService(formData);
    getResponse(res)
      .then(res => {
        toast(
          <span className="text-success">Password successfully updated.</span>
        );
        setFormData({
          oldPassword: '',
          newPassword: '',
          confirmPassword: ''
        });
        setChanging(false);
      })
      .catch(err => {
        setChanging(false);
      });
  };

  return (
    <Card className="mb-3">
      <CardHeader title="Change Password" />
      <Card.Body className="bg-light">
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="oldPassword">
            <Form.Label>Old Password</Form.Label>
            <Form.Control
              type="password"
              value={formData.oldPassword}
              name="oldPassword"
              onChange={handleChange}
            />
            {errors.oldPassword && (
              <span className="text-danger fs--2 fw-medium">
                {errors.oldPassword}
              </span>
            )}
          </Form.Group>
          <Form.Group className="mb-3" controlId="newPassword">
            <Form.Label>New Password</Form.Label>
            <Form.Control
              type="password"
              value={formData.newPassword}
              name="newPassword"
              onChange={handleChange}
            />
            {errors.newPassword && (
              <span className="text-danger fs--2 fw-medium font-sans-serif">
                {errors.newPassword}
              </span>
            )}
          </Form.Group>
          <Form.Group className="mb-3" controlId="confirmPassword">
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              type="password"
              value={formData.confirmPassword}
              name="confirmPassword"
              onChange={handleChange}
            />
            {errors.confirmPassword && (
              <span className="text-danger fs--2 fw-medium font-sans-serif">
                {errors.confirmPassword}
              </span>
            )}
          </Form.Group>
          <Button className="w-100" type="submit" disabled={changing}>
            {changing ? 'Updating...' : 'Update Password'}
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default ChangePassword;
