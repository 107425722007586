export const editStepReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'TOGGLE_EDIT_STEP_MODAL':
      return {
        ...state,
        showModal: !state.showModal
      };
    case 'UPDATE_SCENARIO':
      return {
        ...state,
        selectedScenario: payload
      };
    case 'RESET_SCENARIO':
      return { ...state, selectedScenario: null };
    case 'RESET':
      return payload;
    default:
      return state;
  }
};
