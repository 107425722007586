export const projectReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'SAVE_ALL_PROJECT_DATA':
      return {
        ...state,
        ...payload
      };
    default:
      return state;
  }
};
