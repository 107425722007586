import classNames from 'classnames';
import React from 'react';

export default function AgentPlatformBox({
  id,
  selected,
  disabled,
  onClick,
  name,
  IconComponent,
  containerClasses = ''
}) {
  const handleClick = () => {
    if (!disabled) {
      onClick(id);
    }
  };
  const divStyle = {
    cursor: disabled ? 'not-allowed' : 'pointer',
    width: 100
  };
  return (
    <div
      className={classNames(
        'text-center p-3 hover-bg-200 border border-2',
        {
          'border-primary': selected,
          ' border-white': !selected,
          'm-3': !containerClasses || containerClasses === ''
        },
        containerClasses
      )}
      style={divStyle}
      onClick={handleClick}
    >
      <IconComponent size={32} />
      <div>{name}</div>
    </div>
  );
}
