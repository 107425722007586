import { generateQueryString, handleError } from 'helpers/utils';
import axios from './axiosInstance';

export const getAllTechniquesService = async (
  page = 1,
  search = null,
  environment = null,
  tags = null
) => {
  let params = { search, environment, tags, page };
  const queryString = generateQueryString(params);
  let query = '';
  if (queryString) {
    query += `?${queryString}`;
  }
  try {
    const resp = await axios.get('/technique' + query);
    return resp.data;
  } catch (err) {
    return handleError(err);
  }
};

export const getTechniqueById = async id => {
  try {
    const resp = await axios.get('/technique/' + id);
    return resp.data;
  } catch (err) {
    return handleError(err);
  }
};

export const getMasterTemplateByName = async name => {
  try {
    const resp = await axios.get('/technique/master/' + name);
    return resp.data;
  } catch (err) {
    return handleError(err);
  }
};
