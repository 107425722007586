import CardHeader from 'components/common/CardHeader';
import ShadowPopup from 'components/common/ShadowPopup';
import Treemap from 'components/common/TreeMap';
import Spinner from 'components/common/spinner/Spinner';
import { getDateTimeByString } from 'helpers/utils';
import useDimention from 'hooks/useDimention';
import React from 'react';
import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

// Function to convert the data
function convertData(obj) {
  const id = obj.id;
  const children = [
    {
      name:
        obj?.killChainName + ' - ' + getDateTimeByString(obj?.createdAt) ?? '',
      value: obj?.total_steps,
      color: 255 - obj.detection_rate * 255,
      data: {
        ...obj
      }
    }
  ];

  return {
    id: id,
    children
  };
}

export default function ProjectsSnapshot({ data = [], loading = false }) {
  const { ref, width, height } = useDimention();
  const navigate = useNavigate();

  const getData = () => {
    // if(data.length===0){
    //   return [

    //   ]
    // }
    const refactored = {
      name: 'tasks',
      children: data?.map(obj => convertData(obj)) || []
    };
    return refactored;
  };

  if (loading) {
    return (
      <Card className="py-3 d-flex justify-content-center align-items-center">
        <Spinner />
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader
        title="Killchain Heatmap"
        titleTag="h5"
        className="border-200 border-bottom py-3"
      />
      <Card.Body>
        <div
          className="w-100 position-relative"
          style={{ height: 350 }}
          ref={ref}
        >
          {data && data?.length > 0 && width > 0 && height > 0 && (
            <Treemap
              key={width}
              width={width}
              height={height}
              data={getData()}
              onClick={url => navigate(url)}
            />
          )}
          {data?.length === 0 && <ShadowPopup>No data found.</ShadowPopup>}
        </div>
      </Card.Body>
    </Card>
  );
}
