import React, { useState, useEffect } from 'react';
// import AceEditor from 'react-ace';
import sampleJson from './sample.json';
import axios from '../../../services/axiosInstance';
// get :id from url
import { useParams } from 'react-router-dom';
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem
} from 'reactstrap';
import ReactJson from 'react-json-view';
import JSONEditor from '../../common/JsonEditor';

// I want a json editor
const Workbench = () => {
  const [json, setJson] = useState();
  const [agents, setAgents] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState('');
  const [taskId, setTaskId] = useState(null); // response.data.taskId
  const [tasks, setTasks] = useState([]); // response.data.task
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [open, setOpen] = useState('1');
  const [isRunLoading, setIsRunLoading] = useState(false);
  const [isRefreshLoading, setIsRefreshLoading] = useState(false);

  // pagination
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [page, setPage] = useState(1);
  const [numbers, setNumbers] = useState([]);

  const { id } = useParams();

  const toggle = id => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  const handleChange = e => {
    try {
      // const parsedJson = JSON.parse(e) || json;
      setJson(e);
      setError(null);
    } catch (error) {
      setError(error?.message || 'Invalid JSON');
    }
  };

  // const onSave = () => {
  //   const editor = ace.edit('json-editor-workbench');
  //   editor.setValue(JSON.stringify(editor.getValue(), null, 2));
  // };

  const getAgents = async () => {
    try {
      const response = await axios.get(`/agent/list`, {
        withCredentials: true
      });
      setAgents(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async purge => {
    setIsRunLoading(true);
    try {
      const response = await axios.post(
        `/workbench?purge=${purge}`,
        {
          agentId: selectedAgent,
          ...json
        },
        { withCredentials: true }
      );
      setSuccess(`Task Id: ${response?.data?.id}`);
      // window.location.href = `/workbench/${response?.data?.taskId}`;
      refresh();
    } catch (error) {
      console.log(error);
      setError(error?.message);
    }
    setIsRunLoading(false);
  };

  const refresh = async e => {
    e = e || page;
    setIsRefreshLoading(true);
    try {
      const workBenchId = taskId || id;
      if (workBenchId === 'new') {
        return;
      }
      const response = await axios.get(`/workbench/${taskId || id}?page=${e}`, {
        withCredentials: true
      });
      setTasks(response?.data?.data);
      setTotalPages(response.data.total_pages);
      setCurrentPage(response.data.page);
      // based on total pages, create an array of numbers
      setNumbers([...Array(response.data.total_pages).keys()].map(i => i + 1));
    } catch (error) {
      console.log(error);
      setError(
        error?.response?.data?.message ||
          `Error: ${error.message} with status code ${error?.response?.status}`
      );
    }
    setIsRefreshLoading(false);
  };

  const handlePageChange = e => {
    setPage(e);
    refresh(e);
  };

  const getTimeAgo = timestamp => {
    const current = new Date();
    const previous = new Date(timestamp);
    const diff = Math.abs(current - previous);

    const minutes = Math.floor(diff / (1000 * 60));
    if (minutes < 60) {
      return minutes === 1 ? '1 minute ago' : `${minutes} minutes ago`;
    }

    const hours = Math.floor(diff / (1000 * 60 * 60));
    if (hours < 24) {
      return hours === 1 ? '1 hour ago' : `${hours} hours ago`;
    }

    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    return days === 1 ? '1 day ago' : `${days} days ago`;
  };

  useEffect(async () => {
    // get workbench id from url
    await axios
      .get(`/workbench/technique/${id}`, {
        withCredentials: true
      })
      .then(res => {
        const data = res.data;
        setJson({
          name: data.name,
          techniqueID: data.techniqueID,
          techniqueName: data.techniqueName,
          techniqueDescription: data.techniqueDescription,
          obfuscate: data.obfuscate,
          packer: data.packer,
          supported_platforms: data.supported_platforms,
          windows: data.windows,
          debian: data?.debian,
          macos: data.macos
        });
      })
      .catch(err => {
        console.log(err);
        setJson(sampleJson);
      });

    // setJson(sampleJson);
    getAgents();
    refresh();
  }, []);

  return (
    <div>
      <h3>Workbench</h3>
      <p>Add and run techniques by selecting an agent.</p>
      {error?.length > 0 && (
        <div className="alert alert-danger" role="alert">
          {error}
        </div>
      )}
      <div className="d-flex justify-content-center">
        <div className="col-4">
          <select
            className="form-select"
            onChange={e => {
              setSelectedAgent(e.target.value);
            }}
          >
            <option value="">Select Agent</option>
            {agents.map(agent => (
              <option key={agent.id} value={agent.id}>
                {`${agent?.name} (${agent?.meta?.computerName})`}
              </option>
            ))}
          </select>
          <p className="mb-0">
            Selected Agent Id: <strong>{selectedAgent}</strong>
          </p>
        </div>
      </div>
      <div className="row m-0 mt-4">
        <div className="col-5">
          <div className="d-flex justify-content-end">
            <div className="col-auto">
              <button
                className="btn btn-primary mb-2"
                onClick={() => {
                  handleSubmit();
                }}
                disabled={isRunLoading || selectedAgent === ''}
              >
                {isRunLoading ? (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  'Run'
                )}
              </button>

              <button
                className="btn btn-primary mb-2 ms-2"
                onClick={() => {
                  handleSubmit(true);
                }}
                disabled={isRunLoading || selectedAgent === ''}
              >
                {isRunLoading ? (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  'Purge & Run'
                )}
              </button>
            </div>
          </div>
          {/* <AceEditor
            id="json-editor-workbench"
            mode="json"
            theme="github"
            onChange={handleChange}
            name="json-editor-workbench"
            showPrintMargin={true}
            showGutter={true}
            highlightActiveLine={true}
            value={JSON.stringify(json, null, 2)}
            setOptions={{
              enableBasicAutocompletion: false,
              enableLiveAutocompletion: true,
              enableSnippets: false,
              showLineNumbers: true,
              tabSize: 2,
              onSave: onSave
            }}
          /> */}

          <JSONEditor
            key={`json`}
            json={json || {}}
            handleChange={value => handleChange(value)}
          />
        </div>
        <div className="col-1"></div>
        <div className="col-6">
          <div className="d-flex justify-content-between">
            <h3>Results</h3>
            {tasks?.length > 0 && (
              <div className="row justify-content-end">
                <div className="col-auto">
                  <nav aria-label="Page navigation example">
                    <ul className="pagination">
                      <li
                        className={`page-item ${
                          currentPage === 1 ? 'disabled' : ''
                        }`}
                        onClick={() => handlePageChange(1)}
                      >
                        <span className="page-link" aria-label="Previous">
                          <span aria-hidden="true">&laquo;</span>
                        </span>
                      </li>
                      {/* use ... like 1..currentPage..last */}
                      {tasks.length > 0 &&
                        numbers.map((item, key) => (
                          <li
                            className={`page-item ${item} ${
                              currentPage === item ? 'active' : ''
                            }`}
                            onClick={() => handlePageChange(item)}
                          >
                            <span className="page-link">{item}</span>
                          </li>
                        ))}
                      <li
                        className={`page-item ${
                          currentPage === totalPages ? 'disabled' : ''
                        }`}
                        onClick={() => handlePageChange(totalPages)}
                      >
                        <span className="page-link" aria-label="Next">
                          <span aria-hidden="true">&raquo;</span>
                        </span>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            )}
            <div className="col-auto">
              <button
                className="btn btn-primary mb-2"
                onClick={() => {
                  refresh();
                }}
                disabled={isRefreshLoading}
              >
                {isRunLoading ? (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : (
                  'Refresh'
                )}
              </button>
            </div>
          </div>
          {/* status */}
          <p className="mb-0">
            {/* Status: <strong>{task?.status}</strong> */}
          </p>

          <Accordion open={open} toggle={toggle}>
            {tasks.map((task, index) => (
              <AccordionItem>
                <AccordionHeader className="d-flex" targetId={index}>
                  {task?.user?.email || '--'}{' '}
                  {task?.status === 'success' && (
                    <span role="img" aria-label="success">
                      ✅
                    </span>
                  )}
                  {task?.status === 'failed' && (
                    <span role="img" aria-label="error">
                      ❌
                    </span>
                  )}
                  {task?.status === 'pending' && (
                    <span role="img" aria-label="error">
                      ⏳
                    </span>
                  )}
                </AccordionHeader>
                <AccordionBody accordionId={index}>
                  {getTimeAgo(task?.createdAt)}

                  <h5>output</h5>
                  <ReactJson src={task?.output || {}} />
                  <hr />

                  <h5>Application Logs</h5>
                  <ReactJson src={task?.ApplicationLog || []} />

                  <hr />
                  <div className="d-flex justify-content-between mb-3">
                    <h5>Input</h5>
                    {/* copy to clipboard */}
                    <button
                      onClick={() => {
                        navigator.clipboard
                          .writeText(JSON.stringify(task?.meta?.input, null, 2))
                          .then(() => {
                            console.log('Text copied to clipboard');
                          })
                          .catch(error => {
                            console.error('Failed to copy text: ', error);
                          });
                      }}
                      className="btn btn-primary btn-sm ms-2"
                    >
                      Copy to clipboard
                    </button>
                  </div>
                  <ReactJson src={task?.meta?.input} />
                </AccordionBody>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default Workbench;
