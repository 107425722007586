import CardDropdown from 'components/common/CardDropdown';
import React from 'react';
import { Badge, Dropdown, Table } from 'react-bootstrap';
import { getDateInFormatOfDMY } from 'helpers/utils';

export default function TemplateTableView({ data = [], onViewClick }) {
  return (
    <Table className="fs--1 mb-0 font-sans-serif" borderless>
      <thead className="bg-300 text-800">
        <tr className="">
          <th className="text-nowrap ">Name</th>
          <th className="text-nowrap  text-center">Steps</th>
          <th className="text-nowrap ">Created On</th>
          <th className="text-nowrap ">Publisher</th>
          <th className="text-nowrap">Last Updated</th>
          {/* <th className="text-nowrap text-end "></th> */}
        </tr>
      </thead>
      <tbody className="bg-white">
        {data.map(template => (
          <tr onClick={() => onViewClick(template)}>
            <td className="text-nowrap ">
              <span className="me-2">{template.name}</span>
              <Badge
                bg={template.type === 'killchain' ? 'primary' : 'success'}
                className="me-2"
              >
                {template.type}
              </Badge>
            </td>
            <td className="text-nowrap text-center">{template.Steps || 0}</td>
            <td className="text-nowrap ">
              {getDateInFormatOfDMY(template.createdAt)}
            </td>
            <td className="text-nowrap ">Attack Wiz</td>
            <td className="text-nowrap ">
              {getDateInFormatOfDMY(template.updatedAt)}
            </td>
            {/* <td className="text-nowrap  text-end">
              <CardDropdown drop="start">
                <div className="py-2">
                  <Dropdown.Item href="#!">View</Dropdown.Item>
                  <Dropdown.Item href="#!">Edit</Dropdown.Item>
                  <Dropdown.Item href="#!">Refund</Dropdown.Item>
                  <Dropdown.Divider as="div" />
                  <Dropdown.Item href="#!" className="text-warning">
                    Archive
                  </Dropdown.Item>
                  <Dropdown.Item href="#!" className="text-danger">
                    Delete
                  </Dropdown.Item>
                </div>
              </CardDropdown>
            </td> */}
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
