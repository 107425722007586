import CloseButton from 'components/common/CloseButton';
import Flex from 'components/common/Flex';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { MdOutlineCancel } from 'react-icons/md';

export default function RenderAppliedFilters({
  filters,
  removeFilter,
  removeAll
}) {
  if (filters.length == 0) {
    return null;
  }
  return (
    <Flex className="border-end" justifyContent={'between'}>
      <div className="px-3">
        <Row>
          {filters.map(filter => (
            <Col key={filter?.key} md="auto" className="p-0">
              <div className="rounded-pill border border-2 ps-2 mb-2 me-2">
                <Flex alignItems={'center'}>
                  <div className="fs--1">
                    {filter?.title}:{' '}
                    <span className="text-black">{filter?.value}</span>
                  </div>
                  <div className="mx-1 mb-1 rounded-circle">
                    <MdOutlineCancel
                      size={21}
                      onClick={() => removeFilter(filter?.key)}
                    />
                  </div>
                </Flex>
              </div>
            </Col>
          ))}
        </Row>
      </div>
      <Flex alignItems={'center'} className="mx-2 mb-2">
        <CloseButton onClick={removeAll} />
      </Flex>
    </Flex>
  );
}

RenderAppliedFilters.defaultProps = {
  filters: [null],
  removeAll: () => {}
};
