import { ProjectContext } from 'context/Context';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { getAllProjectService } from 'services/projectService';
import useError from './useError';

export default function useGetAllProjects() {
  const { getResponse } = useError();
  const [isFetching, setFetching] = useState(false); // initial fetching state = false
  const { projectState, saveAllProjects } = useContext(ProjectContext);

  useEffect(() => {
    if (projectState.data.length == 0) {
      getAllProjects(1);
    }
  }, []);

  const getAllProjects = useCallback(async page => {
    setFetching(true);
    const res = await getAllProjectService(page);
    setFetching(false);
    getResponse(res)
      .then(res => {
        saveAllProjects(res);
      })
      .catch(err => console.error(err));
  }, []);

  return {
    projects: projectState.data || [],
    total_pages: projectState?.total_pages,
    isFetching,
    getAllProjects
  };
}
