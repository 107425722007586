export const executionReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'SAVE_ALL_EXECUTION_DATA':
      return {
        ...state,
        ...payload
      };
    case 'SAVE_EXECUTION':
      return {
        ...state,
        executionResult: payload
      };
    case 'UPDATE_STEP_CONFIG':
      return { ...state, selectedStepConfig: payload };
    case 'UPDATE_LOADING':
      return { ...state, isExecutionFetching: payload };
    case 'UPDATE_SELECT_EXECUTION':
      return { ...state, selectedExecutionId: payload };
    default:
      return state;
  }
};
