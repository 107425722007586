import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import { AgentContext } from 'context/Context';
import { agentReducer } from 'reducers/agentReducer';

const AgentProvider = ({ children }) => {
  const initData = {
    agents: [],
    selectedAgents: []
  };
  const [agentState, dispatch] = useReducer(agentReducer, initData);

  const saveAllAgents = data => {
    dispatch({
      type: 'SAVE_ALL_AGENT_DATA',
      payload: data
    });
  };
  const addAgent = data => {
    dispatch({
      type: 'SAVE_AGENT_DATA',
      payload: data
    });
  };
  const addSelectedAgents = data => {
    dispatch({
      type: 'ADD_SELECT_AGENTS',
      payload: data
    });
  };
  const resetSelectedAgent = () => {
    dispatch({ type: 'RESET_SELECTED_AGENTS', payload: [] });
  };
  return (
    <AgentContext.Provider
      value={{
        agentState,
        saveAllAgents,
        addAgent,
        addSelectedAgents,
        resetSelectedAgent
      }}
    >
      {children}
    </AgentContext.Provider>
  );
};

AgentProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AgentProvider;
