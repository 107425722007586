import OverviewCard from 'components/common/OverviewCard';
import React, { useEffect, useState } from 'react';

import toolsIcon from 'assets/img/icons/tools.png';
import vulnerabilitiesBlackIcon from 'assets/img/icons/vulnerabilities_black.png';
import domainScanIcon from 'assets/img/icons/domain_scan.png';
import activeIssueIcon from 'assets/img/icons/active_issue.png';
import reportGenerateIcon from 'assets/img/icons/apple_logo_black.png';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import graph1 from 'assets/img/icons/graph_1.png';
import graph2 from 'assets/img/icons/graph_2.png';
import { Col, Row } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import ProjectsSnapshot from './ProjectsSnapshot';
import TaskSummarySingleLine from './TaskSummarySingleLine';
import CategoryProjectsPie from './CategoryProjectsPie';
import CountOverview from './CountOverview';
import VulnerabilitiesSnapshot from './VulnerabilitiesSnapshot';
import ToolsScan from './ToolsScan';
import axios from '../../../services/axiosInstance';

export default function DefaultDashboard() {
  const navigate = useNavigate();
  const [fetching, setFetching] = useState(false);
  const [data, setData] = React.useState({});

  useEffect(() => {
    setFetching(true);
    axios
      .get(`/dashboard`)
      .then(res => {
        setData(res.data);
        setFetching(false);
      })
      .catch(err => {
        setFetching(false);
        console.log(err);
        // handle 401
        if (err?.response?.status === 401) {
          navigate('/?authfailed=' + location.pathname);
          return;
        }
      });
  }, []);

  const TopBar = () => {
    return (
      <>
        <OverviewCard
          title={'Projects'}
          titleImgUrl={toolsIcon}
          // label={'Current Value'}
          value={data?.projects_count || 0}
          // labelValueGraphUrl={graph1}
          bgColor={'#E6D6FF'}
        />
        <OverviewCard
          title={'Assessments'}
          titleImgUrl={vulnerabilitiesBlackIcon}
          // label={'Current Value'}
          value={data?.assessments_count || 0}
          // labelValueGraphUrl={graph1}
          bgColor={'#FFEEDD'}
        />
        <OverviewCard
          title={'Assets'}
          titleImgUrl={domainScanIcon}
          // label={'Current Value'}
          value={data?.assests_count || 0}
          // labelValueGraphUrl={graph2}
          bgColor={'#D0EEFF'}
        />
        <OverviewCard
          title={'Success/Failed'}
          titleImgUrl={activeIssueIcon}
          // label={'Current Value'}
          value={
            `${
              data?.success_failure_count?.find(e => e.status == 'success')
                ?._count?.status || 0
            } / ${
              data?.success_failure_count?.find(e => e.status == 'failed')
                ?._count?.status || 0
            }` || `0 / 0`
          }
          // labelValueGraphUrl={graph2}
          bgColor={'#D9FFC2'}
        />
        <OverviewCard
          title={'Detected/Prevented'}
          titleImgUrl={reportGenerateIcon}
          value={
            `${
              data?.detected_prevented_count_30_days?.find(
                e => e._id == 'detected'
              )?.count || 0
            } / ${
              data?.detected_prevented_count_30_days?.find(
                e => e._id == 'prevented'
              )?.count || 0
            }` || `0 / 0`
          }
          // labelValueGraphUrl={graph2}
          bgColor={'#FFD9DB'}
        />
      </>
    );
  };

  return (
    <React.Fragment>
      <Row className="mb-4">
        <Col>
          <Flex className="bg-white rounded-3 p-2 d-none d-sm-none d-md-flex">
            <TopBar />
          </Flex>
          <Flex className="bg-white rounded-3 p-2 flex-wrap d-md-none">
            <TopBar />
          </Flex>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={4}>
          <CountOverview data={data?.last_week_count || {}} />
          <div className="my-4">
            <VulnerabilitiesSnapshot data={data} />
          </div>
          <ToolsScan />
        </Col>
        <Col xs={12} md={8}>
          <ProjectsSnapshot
            loading={fetching}
            data={data?.tasks_by_assessments || []}
          />
          <div className="my-4">
            <TaskSummarySingleLine data={data?.assessment_summary || []} />
          </div>
          <CategoryProjectsPie data={data} />
        </Col>
      </Row>
    </React.Fragment>
  );
}
