import React from 'react';
import { Button, Form } from 'react-bootstrap';

export default function KillchainEditForm({
  name,
  description,
  onChange,
  onSubmit
}) {
  return (
    <div>
      <Form.Label>Name</Form.Label>
      <Form.Control
        type="text"
        id="name"
        value={name}
        onChange={onChange}
        placeholder="e.g. Linear"
      />
      <Form.Label>Description</Form.Label>
      <Form.Control
        type="text"
        id="description"
        value={description}
        onChange={onChange}
        placeholder="e.g. Linear"
      />
      <Button classname="" onClick={onSubmit} variant="primary">
        Submit
      </Button>
    </div>
  );
}
