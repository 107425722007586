import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';

export default function Treemap({ data, width, height, onClick }) {
  const svgRef = useRef(null);
  //   const legendRef = useRef(null);
  const tooltipRef = useRef(null);

  function renderTreemap() {
    const svg = d3.select(svgRef.current);
    const tooltip = d3.select(tooltipRef.current);
    svg.selectAll('g').remove();

    // const legendContainer = d3.select(legendRef.current);
    // legendContainer.selectAll('g').remove();

    svg.attr('width', width).attr('height', height);

    // create root node
    const root = d3
      .hierarchy(data)
      .sum(d => d.value)
      .sort((a, b) => b.value - a.value);

    // create treemap layout
    const treemapRoot = d3.treemap().size([width, height]).padding(1)(root);

    // create 'g' element nodes based on data
    const nodes = svg
      .selectAll('g')
      .data(treemapRoot.leaves())
      .join('g')
      .attr('transform', d => `translate(${d.x0},${d.y0})`);

    // Create a gradient color scale
    const colorScale = d3.scaleSequential(d3.interpolateReds);

    // Extract all values from the hierarchy using a function
    function extractValues(node) {
      return node.children
        ? node.children.flatMap(extractValues)
        : [node.color];
    }

    // Set the domain of the color scale based on the data values
    const minValue = d3.min(extractValues(data));
    const maxValue = d3.max(extractValues(data));
    colorScale.domain([minValue, maxValue]);

    // add treemap rects
    nodes
      .append('rect')
      .attr('width', d => d.x1 - d.x0)
      .attr('height', d => d.y1 - d.y0)
      .attr('fill', d => colorScale(d.data.color))
      // .attr('scale', 2)
      .on('mouseover', showTooltip)
      .on('mousemove', moveTooltip)
      .on('mouseout', hideTooltip)
      .on('click', handleClick);

    const fontSize = 12;

    // add text to rects
    nodes
      .append('text')
      .text(d => d.data.name)
      .attr('data-width', d => d.x1 - d.x0)
      .attr('fill', d => getColorClass(d.data.color))
      .attr('class', 'font-sans-serif')
      .attr('font-size', `${fontSize}px`)
      .attr('x', 3)
      .attr('y', fontSize)
      .call(wrapText);

    function wrapText(selection) {
      selection.each(function () {
        const node = d3.select(this);
        const rectWidth = +node.attr('data-width');
        let word;
        const words = node.text().split(' ').reverse();
        let line = [];
        let lineNumber = 0;
        const x = node.attr('x');
        const y = node.attr('y');
        let tspan = node.text('').append('tspan').attr('x', x).attr('y', y);
        while (words.length > 1) {
          word = words.pop();
          line.push(word);
          tspan.text(line.join(' '));
          const tspanLength = tspan.node().getComputedTextLength();
          if (tspanLength > rectWidth && line.length !== 1) {
            line.pop();
            tspan.text(line.join(' '));
            line = [word];
            tspan = addTspan(word);
          }
        }
        addTspan(words.pop());

        function addTspan(text) {
          lineNumber += 1;
          return node
            .append('tspan')
            .attr('x', x)
            .attr('y', y)
            .attr('dy', `${lineNumber * fontSize}px`)
            .text(text);
        }
      });
    }

    function getColorClass(red) {
      if (red >= 127) {
        return 'white';
      }
      return 'black';
    }

    function showTooltip(event, d) {
      const tooltipWidth = 140;
      const tooltipHeight = 60;

      tooltip.style('opacity', 1);
      tooltip
        .html(
          `<div class="font-sans-serif">
        <div class="fs--1 text-truncate"><strong>${d.data.name}</strong></div>
        <div class="fs--1">Failed: ${d.data.data.total_failed}</div>
        <div class="fs--1">Success: ${d.data.data.total_success}</div>
        <div class="fs--1">Detected: ${d.data.data.total_detected}</div>
        <div class="fs--1">Prevented: ${d.data.data.total_prevented}</div>
        </div>`
        )
        .style('left', `${event.layerX - tooltipWidth / 2}px`)
        .style('top', `${event.layerY - tooltipHeight}px`)
        .style('width', `${tooltipWidth}px`);
    }

    function moveTooltip(event) {
      const tooltipWidth = 100;
      const tooltipHeight = 60;

      tooltip
        .style('left', `${event.layerX - tooltipWidth / 2}px`)
        .style('top', `${event.layerY - tooltipHeight}px`);
    }

    function hideTooltip() {
      tooltip.style('opacity', 0);
    }

    // pull out hierarchy categories
    // let categories = root.leaves().map(node => node.data.category);
    // categories = categories.filter(
    //   (category, index, self) => self.indexOf(category) === index
    // );

    //     legendContainer.attr('width', width).attr('height', height / 4);

    //     // create 'g' elements based on categories
    //     const legend = legendContainer.selectAll('g').data(categories).join('g');

    //     // create 'rects' for each category
    //     legend
    //       .append('rect')
    //       .attr('width', fontSize)
    //       .attr('height', fontSize)
    //       .attr('x', fontSize)
    //       .attr('y', (_, i) => fontSize * 2 * i)
    //       .attr('fill', d => colorScale(d));

    //     // add text to each category key
    //     legend
    //       .append('text')
    //       .attr('transform', `translate(0, ${fontSize})`)
    //       .attr('x', fontSize * 3)
    //       .attr('y', (_, i) => fontSize * 2 * i)
    //       .style('font-size', fontSize)
    //       .text(d => d);
  }

  function handleClick(event, d) {
    onClick(`/assessments/${d.data.data.killChainId}/setup`);
  }

  useEffect(() => {
    renderTreemap();
  }, [data]);

  return (
    <div className="position-relative">
      <svg ref={svgRef} />
      {/* <svg ref={legendRef} /> */}
      <div
        ref={tooltipRef}
        style={{
          position: 'absolute',
          opacity: 0,
          pointerEvents: 'none',
          background: '#fff',
          padding: '5px',
          border: '1px solid #ccc',
          borderRadius: '5px'
        }}
      />
    </div>
  );
}
