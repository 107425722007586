import { AuthContext } from 'context/Context';
import React, { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export default function ProtectedLayout({ children }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { isLogedIn, onLoginSuccess } = useContext(AuthContext);
  useEffect(() => {
    if (!isLogedIn) {
      const savedAuthStateString = localStorage.getItem('authState');
      if (savedAuthStateString) {
        const savedAuthState = JSON.parse(savedAuthStateString);
        if (!savedAuthState.isLogedIn) {
          navigate('/?authfailed=' + location.pathname);
        } else {
          onLoginSuccess({ ...savedAuthState });
        }
      } else {
        navigate('/?authfailed=' + location.pathname);
      }
    }
  }, [isLogedIn]);
  return children;
}
