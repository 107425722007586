import React, { useCallback, useContext, useEffect, useState } from 'react';
import ChangePassword from './account/ChangePassword';
import ProfileSettings from './account/ProfileSettings';
import ProfileBanner from './account/ProfileBanner';
import avatar from 'assets/img/icons/user-icon.png';
import { Col, Row } from 'react-bootstrap';
import { getUserService } from 'services/userService';
import useError from 'hooks/useError';
import { AuthContext } from 'context/Context';
import OrganizationSettings from './account/OrganizationSettings';

export default function Account() {
  const { getResponse } = useError();
  const { userRole } = useContext(AuthContext);
  const [fetching, setFetching] = useState(false);
  const [userData, setUserData] = useState(null);
  const getUserProfile = useCallback(async () => {
    setFetching(true);
    const res = await getUserService();
    setFetching(false);
    getResponse(res)
      .then(res => {
        setUserData(res);
      })
      .catch(err => console.error(err));
  }, []);

  useEffect(() => {
    getUserProfile();
  }, []);

  if (fetching) {
    return null;
  }

  return (
    <>
      <ProfileBanner>
        {userData && (
          <ProfileBanner.Header
            coverSrc={null}
            avatar={userData?.avatar || avatar}
            className="mb-8"
          />
        )}
      </ProfileBanner>
      <Row className="g-3">
        <Col lg={8}>{userData && <ProfileSettings userData={userData} />}</Col>
        <Col lg={4}>
          <div className="sticky-sidebar">
            <ChangePassword />
          </div>
        </Col>
      </Row>
      <div className="py-2"></div>
      {userRole !== 'ORG_ADMIN' && <OrganizationSettings />}
    </>
  );
}
