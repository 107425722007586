import React from 'react';
import { useState } from 'react';
import { Card } from 'react-bootstrap';
import TemplateDetailView from './TemplateDetailView';
import TemplateTableView from './TemplateTableView';
import AssessmentHeader from './AssessmentHeader';
import GridLoader from 'components/common/GridLoader';

export default function AssessmentView({
  data,
  onAssessmentClick,
  isLoading,
  search,
  setSearch,
  selectedAssessmentType,
  setAssessmentType
}) {
  const [templateViewType, setTVT] = useState('grid');
  const handleViewChange = view_type => {
    setTVT(view_type);
  };

  return (
    <React.Fragment>
      <Card>
        <Card.Body>
          <AssessmentHeader
            selectedType={templateViewType}
            onViewChange={handleViewChange}
            search={search}
            setSearch={setSearch}
            selectedAssessmentType={selectedAssessmentType}
            setAssessmentType={setAssessmentType}
          />
          <div className="my-4"></div>
          {isLoading ? (
            <GridLoader />
          ) : (
            <RenderAssessmentTypeView
              type={templateViewType}
              data={data}
              onAssessmentClick={onAssessmentClick}
            />
          )}
        </Card.Body>
      </Card>
    </React.Fragment>
  );
}

const RenderAssessmentTypeView = ({ type, data, onAssessmentClick }) => {
  if (type == 'grid') {
    return <TemplateDetailView data={data} onViewClick={onAssessmentClick} />;
  }
  if (type == 'table') {
    return <TemplateTableView data={data} onViewClick={onAssessmentClick} />;
  }
};
