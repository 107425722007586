import Flex from 'components/common/Flex';
// import IconButton from 'components/common/IconButton';
import React from 'react';
import { BsFileEarmarkPlus } from 'react-icons/bs';

const RenderEmptyTestContent = () => {
  return (
    <Flex
      justifyContent={'center'}
      alignItems={'center'}
      direction={'column'}
      style={{ minHeight: 200 }}
    >
      <BsFileEarmarkPlus fontSize={64} className="empty-icon" />
      <div className="text-black fw-medium fs-1 mb-3">
        This assessment doesn't have any tests.
      </div>
      {/* <IconButton icon="plus">
				<span className="ms-2">Create Step</span>
			</IconButton> */}
    </Flex>
  );
};

export default RenderEmptyTestContent;
