import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button, Dropdown, Form } from 'react-bootstrap';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import { getSize } from 'helpers/utils';
import Flex from 'components/common/Flex';
import CardDropdown from 'components/common/CardDropdown';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';

function FileUpload({ multiple = true, content = 'Upload Files', onSubmit }) {
  const schema = yup
    .object({
      uploadedFiles: yup.array().required('This field is required.')
    })
    .required();
  const {
    handleSubmit,
    setValue,
    formState: { errors },
    clearErrors
  } = useForm({
    resolver: yupResolver(schema)
  });
  const [files, setFiles] = useState([]);

  const { getRootProps, getInputProps } = useDropzone({
    multiple: multiple,
    accept: {
      'image/*': ['.jpeg', '.png', '.jpg']
    },
    onDrop: acceptedFiles => {
      if (errors.uploadedFiles) {
        clearErrors('uploadedFiles');
      }
      setValue('uploadedFiles', acceptedFiles);
      if (multiple) {
        setFiles([
          ...files,
          ...acceptedFiles.map(file =>
            Object.assign(file, {
              preview: URL.createObjectURL(file)
            })
          )
        ]);
      } else {
        setFiles([
          ...acceptedFiles.map(file =>
            Object.assign(file, {
              preview: URL.createObjectURL(file)
            })
          )
        ]);
      }
    }
  });

  const handleRemove = path => {
    const filteredFiles = files.filter(file => file.path !== path);
    setFiles(filteredFiles);
    setValue('uploadedFiles', filteredFiles.length ? filteredFiles : null);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div
        {...getRootProps({
          className: `${classNames('dropzone-area py-3', {
            'is-invalid': errors.uploadedFiles
          })}`
        })}
      >
        <input
          name="uploadedFiles"
          {...getInputProps({ multiple: multiple })}
        />
        <Flex justifyContent="center">
          <img src={cloudUpload} alt="" width={25} className="me-2" />
          <p className="fs-0 mb-0 text-700">{content}</p>
        </Flex>
      </div>
      <div className="invalid-feedback">{errors.uploadedFiles?.message}</div>
      <div>
        {files.map(file => (
          <Flex
            alignItems="center"
            className="py-3 border-bottom btn-reveal-trigger"
            key={file.path}
          >
            <img
              className="rounded"
              width={40}
              height={40}
              src={file.preview}
              alt={file.path}
            />

            <Flex
              justifyContent="between"
              alignItems="center"
              className="ms-3 flex-1"
            >
              <div>
                <h6>{file.path}</h6>
                <Flex className="position-relative" alignItems="center">
                  <p className="mb-0 fs--1 text-400 line-height-1">
                    <strong>{getSize(file.size)}</strong>
                  </p>
                </Flex>
              </div>
            </Flex>
            <CardDropdown>
              <div className="py-2">
                <Dropdown.Item
                  className="text-danger"
                  onClick={() => handleRemove(file.path)}
                >
                  Remove
                </Dropdown.Item>
              </div>
            </CardDropdown>
          </Flex>
        ))}
      </div>
      <Button variant="falcon-primary" type="submit" className="mt-3 me-2">
        Submit
      </Button>
    </Form>
  );
}

export default FileUpload;
