import CloseButton from 'components/common/CloseButton';
import TagBox from 'components/common/TagBox';
import { IntegrationContext } from 'context/Context';
import {
  capitalize,
  getAgentStatusByRefactor,
  getDateInFormatOfDMY
} from 'helpers/utils';
import useError from 'hooks/useError';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { updateAgentService } from 'services/agentService';
import {
  createAgentIntegrationService,
  getAgentIntegrationService
} from 'services/integrationService';
import { attachTagsService } from 'services/tagService';

let STATUS_LABEL = {
  building: 'NA',
  ready: 'Alive',
  dead: 'Dead',
  inactive: 'Inactive',
  undefined: 'NA' //handling for status otherthan this
};

export default function AssetShowEditModal({
  data,
  show,
  handleClose,
  onAssetSave
}) {
  const { integrationState } = useContext(IntegrationContext);
  const { getResponse } = useError();
  const [formData, setFormData] = useState({
    name: data?.name || '',
    tags: []
  });
  const [integrationForm, setIntegrationForm] = useState({
    prevention: null,
    detection: null
  });

  const getAgentAllIntegrations = async () => {
    const res = await getAgentIntegrationService(data?.id);
    getResponse(res)
      .then(res => {
        const dataArr = new Map(
          res?.map(i => i?.IntegrationInstance).map(i => [i?.type, i?.id])
        );
        const filteredData = Object.fromEntries(dataArr);
        setIntegrationForm({
          ...integrationForm,
          prevention: filteredData?.prevented ?? null,
          detection: filteredData?.detected ?? null
        });
      })
      .catch(err => console.error(err));
  };

  useEffect(() => {
    getAgentAllIntegrations();
  }, []);

  useEffect(() => {
    let agentTags = data?.Taggable?.map(each => each?.tag).map(
      tag => tag?.name
    );
    handleTags(agentTags);
  }, [data]);

  const handleEdit = ({ target }) => {
    let { id, value } = target;
    setFormData({ ...formData, [id]: value });
  };

  const handleTags = values => {
    setFormData({ ...formData, tags: [...values] });
  };

  const handleSaveAgent = async () => {
    if (formData.name != data?.name) {
      let _data = { name: formData.name };
      const res = await updateAgentService(data?.id, _data);

      getResponse(res)
        .then(res => {
          toast(
            <span className="text-success">
              Agent details updated successfully.
            </span>
          );
        })
        .catch(err => console.error(err));
    }
    const tagRes = await attachTagsService({
      names: formData.tags,
      taggableName: 'Agent',
      taggableId: data?.id
    });
    getResponse(tagRes).catch(err => console.error(err));
    onAssetSave();
  };

  const handleChange = async e => {
    let { id, value } = e.target;
    setIntegrationForm({ ...integrationForm, [id]: value });
    const res = await createAgentIntegrationService(data?.id, value);
    getResponse(res)
      .then(res => {
        toast(
          <span className="text-success">
            {capitalize(id)} updated successfully.
          </span>
        );
      })
      .catch(err => console.error(err));
  };

  let status = getAgentStatusByRefactor(data.status, data.lastSeen);
  let statusLabel = STATUS_LABEL[status];
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>Agent Details</Modal.Title>
          <CloseButton onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          {/* <h5 className="text-black">Settings</h5> */}
          <div className="my-4">
            <Form.Group
              as={Row}
              className="mb-3"
              controlId="formHorizontalEmail"
            >
              <Form.Label column sm={4}>
                Name*
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  type="text"
                  id="name"
                  value={formData.name}
                  onChange={handleEdit}
                  placeholder="e.g. Linear"
                />
              </Col>
            </Form.Group>

            <Row>
              <Col style={{ height: 36 }}>
                <TagBox
                  labelClass="text-900 fw-semi-bold"
                  label={'Agent Tags'}
                  id="agent_tag_box"
                  tags={formData.tags}
                  updateTags={handleTags}
                  key={formData.tags}
                />
              </Col>
            </Row>
          </div>
          <Button
            variant="primary"
            className="w-100 py-2 px-4"
            onClick={handleSaveAgent}
          >
            Save
          </Button>
          <Row className="mt-3">
            <Col>
              <Form.Label>Select Prevention</Form.Label>
              <Form.Select
                id="prevention"
                onChange={handleChange}
                value={integrationForm.prevention}
                className="text-700"
              >
                <option className="text-700">Select...</option>
                {integrationState?.allByOrg
                  ?.filter(i => i.type === 'prevented')
                  ?.map(op => (
                    <option className="text-700" value={op.id}>
                      {op?.Integration?.name}
                    </option>
                  ))}
              </Form.Select>
            </Col>
            <Col>
              <Form.Label>Select Detection</Form.Label>
              <Form.Select
                id="detection"
                onChange={handleChange}
                value={integrationForm.detection}
                className="text-700"
              >
                <option className="text-700">Select...</option>
                {integrationState?.allByOrg
                  ?.filter(i => i.type === 'detected')
                  ?.map(op => (
                    <option className="text-700" value={op.id}>
                      {op?.Integration?.name}
                    </option>
                  ))}
              </Form.Select>
            </Col>
          </Row>
          <hr className="my-4 text-primary border-top-2" />
          <h5 className="text-black">Agent Details</h5>
          <div className="my-4">
            <Row>
              <Col md={4} lg={3} className="text-end">
                Status
              </Col>
              <Col md={8} lg={9}>
                {statusLabel}
              </Col>
            </Row>

            <hr className="my-2" />
            <Row>
              <Col md={4} lg={3} className="text-end">
                Host
              </Col>
              <Col md={8} lg={9}>
                {data?.meta?.computerName}
              </Col>
            </Row>
            <hr className="my-2" />
            <Row>
              <Col md={4} lg={3} className="text-end">
                Name
              </Col>
              <Col md={8} lg={9}>
                {data?.name}
              </Col>
            </Row>
            <hr className="my-2" />
            <Row>
              <Col md={4} lg={3} className="text-end">
                Privilege
              </Col>
              <Col md={8} lg={9}>
                {data?.privilege}
              </Col>
            </Row>
            <hr className="my-2" />
            <Row>
              <Col md={4} lg={3} className="text-end">
                Last Seen
              </Col>
              <Col md={8} lg={9}>
                {data?.lastSeen && getDateInFormatOfDMY(data?.lastSeen)}
              </Col>
            </Row>
            <hr className="my-2" />
            <Row>
              <Col md={4} lg={3} className="text-end">
                Created
              </Col>
              <Col md={8} lg={9}>
                {getDateInFormatOfDMY(data?.createdAt)}
              </Col>
            </Row>
            <hr className="my-2" />
            <Row>
              <Col md={4} lg={3} className="text-end">
                Platform{' '}
              </Col>
              <Col md={8} lg={9}>
                {data?.platform}
              </Col>
            </Row>
            <hr className="my-2" />
            <Row>
              <Col md={4} lg={3} className="text-end">
                PID{' '}
              </Col>
              <Col md={8} lg={9}>
                {data?.pid}
              </Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="falcon-default"
            className="py-2 px-4"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
