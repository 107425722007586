import CloseButton from 'components/common/CloseButton';
import React, { useCallback, useEffect, useRef } from 'react';
import { Button, Modal } from 'react-bootstrap';
import AddSelectorForm from './AddSelectorForm';
import SelectedAssetCountText from './SelectedAssetCountText';
import SelectAssetTable from './SelectAssetTable';
import { useContext } from 'react';
import { AgentContext } from 'context/Context';
import { useState } from 'react';
import { addAgentService, removeAgentService } from 'services/killchainService';
import useKillchainAgent from 'hooks/useKillchainAgent';
import { useDebounce } from 'use-debounce';
import useAgent from 'hooks/useAgent';
import useError from 'hooks/useError';

export default function AssetSelectorModal({ show, handleClose, id }) {
  const initialRenderDone = useRef(null);
  const { getResponse } = useError();
  const { refreshKillchainAgents } = useKillchainAgent(id);
  const { addSelectedAgents, agentState } = useContext(AgentContext);
  const [agentIds, setAgentIds] = useState([]);
  const [removedAgentIds, setRemovedAgentIds] = useState([]);
  const [apply, setApply] = useState(false);
  const [search, setSearch] = useState('');
  const [debouncedText] = useDebounce(search, 1000);
  const { agents, isFetching, getAllAgents } = useAgent(true);
  const [selectedOS, setOS] = useState(null);
  const [tags, setTags] = useState([]);
  const [debouncedTags] = useDebounce(tags, 1000);

  const handleRowSelect = agentIDs => {
    let newIds = [];
    let removedIds = [];
    if (agentState.selectedAgents?.data) {
      let saIds = agentState.selectedAgents.data.map(d => d.Agent.id);
      newIds = agentIDs.filter(id => !saIds.includes(id));
      let currentAgentDataHasSelectedAgentIds = saIds.filter(id =>
        agentState?.agents?.data?.map(a => a.id).includes(id)
      );
      removedIds = currentAgentDataHasSelectedAgentIds.filter(
        id => !agentIDs.includes(id)
      );
    }
    setAgentIds([...new Set(newIds)]);
    setRemovedAgentIds(removedIds);
  };

  const onApply = async () => {
    setApply(true);
    if (agentIds.length > 0) {
      const res = await addAgentService(id, agentIds);
      getResponse(res)
        .then(res => {})
        .catch(err => {
          setApply(false);
        });
    }
    if (removedAgentIds.length > 0) {
      const res = await removeAgentService(id, removedAgentIds);
      getResponse(res)
        .then(res => {})
        .catch(err => {
          setApply(false);
        });
    }

    setApply(false);
    refreshKillchainAgents();
    handleClose();
  };

  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      if (agentState?.agents?.page != pageIndex + 1) {
        getAllAgents(
          pageIndex + 1,
          debouncedText,
          selectedOS?.value,
          debouncedTags,
          true
        );
      }
    },
    [debouncedText, agentState?.agents?.page, selectedOS?.value, debouncedTags]
  );

  useEffect(() => {
    if (initialRenderDone.current) {
      getAllAgents(1, debouncedText, selectedOS?.value, debouncedTags, true);
    } else {
      initialRenderDone.current = 'done';
    }
  }, [debouncedText, selectedOS, debouncedTags]);

  return (
    <React.Fragment>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="xl"
      >
        <Modal.Header>
          <Modal.Title>Asset Selector</Modal.Title>
          <CloseButton onClick={handleClose} disabled={apply} />
        </Modal.Header>
        <Modal.Body>
          <AddSelectorForm
            search={search}
            setSearch={setSearch}
            selectedEnv={selectedOS}
            setEnv={setOS}
            tags={tags}
            handleTags={setTags}
          />
          <SelectAssetTable
            actionView={SelectedAssetCountText}
            onRowSelect={handleRowSelect}
            fetchData={fetchData}
            agents={agents}
            isFetching={isFetching}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="falcon-default"
            className="py-2 px-4"
            onClick={handleClose}
            size="sm"
            disabled={apply}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            size="sm"
            className="py-2 px-4"
            onClick={onApply}
            disabled={apply}
          >
            {apply ? 'Applying...' : 'Apply'}
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}
