import React from 'react';
import Select from 'react-select';
export default function SelectBox({
  title,
  options,
  selectClass,
  optionClass,
  titleClass,
  value,
  onChange,
  isOptionsLoading,
  placeholder,
  menuPlacement = 'bottom',
  style,
  isMulti = false
}) {
  return (
    <div className="h-100" style={{ minWidth: 180, ...style }}>
      {title && <label className={`mb-1 ${titleClass}`}>{title}</label>}
      <Select
        closeMenuOnSelect={isMulti ? false : true}
        options={options}
        placeholder={placeholder}
        classNamePrefix="react-select"
        value={value}
        onChange={onChange}
        key={value}
        isMulti={isMulti}
        isLoading={isOptionsLoading}
        menuPlacement={menuPlacement}
      />
    </div>
  );
}

SelectBox.defaultProps = {
  title: null,
  options: [],
  optionClass: '',
  selectClass: '',
  titleClass: '',
  isOptionsLoading: false,
  placeholder: 'Select...',
  style: {}
};
