import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import { ExecutionContext } from 'context/Context';
import { executionReducer } from 'reducers/executionReducer';
import { getKillchainExecutionsStepsService } from 'services/killchainService';
import useError from 'hooks/useError';

const ExecutionProvider = ({ children }) => {
  const { getResponse } = useError();
  const initData = {
    data: [],
    total: null,
    executionResult: null,
    selectedStepConfig: null,
    isExecutionFetching: false,
    selectedExecutionId: null
  };
  const [executionState, dispatch] = useReducer(executionReducer, initData);

  const saveAllExecutions = data => {
    dispatch({
      type: 'SAVE_ALL_EXECUTION_DATA',
      payload: data
    });
  };

  const saveExecution = data => {
    dispatch({ type: 'SAVE_EXECUTION', payload: data });
  };

  const updateSelectStepConfig = payload => {
    dispatch({ type: 'UPDATE_STEP_CONFIG', payload });
  };

  const setFetching = value => {
    dispatch({ type: 'UPDATE_LOADING', payload: value });
  };

  const updateSelectedExecution = value => {
    dispatch({ type: 'UPDATE_SELECT_EXECUTION', payload: value });
  };

  const getAllExecutionService = async (id, executionId, page, osType) => {
    setFetching(true);
    const res = await getKillchainExecutionsStepsService(
      id,
      executionId,
      page,
      osType
    );
    setFetching(false);
    getResponse(res)
      .then(res => {
        saveAllExecutions(res);
      })
      .catch(err => console.error(err));
  };

  const getAllExecution = (id, page) => {
    getAllExecutionService(id, executionState.selectedExecutionId, page);
  };

  const updateAllExecutionById = (id, executionId, osType) => {
    updateSelectedExecution(executionId);
    getAllExecutionService(id, executionId, 1, osType);
  };

  return (
    <ExecutionContext.Provider
      value={{
        executionState,
        saveAllExecutions,
        saveExecution,
        updateSelectStepConfig,
        getAllExecution,
        updateSelectedExecution,
        updateAllExecutionById
      }}
    >
      {children}
    </ExecutionContext.Provider>
  );
};

ExecutionProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default ExecutionProvider;
