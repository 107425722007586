import React, { memo } from 'react';
import SimpleBarReact from 'simplebar-react';
import { Table } from 'react-bootstrap';
import classNames from 'classnames';
// import ActionButton from 'components/common/ActionButton';

const TestRow = memo(({ item, isLast }) => {
  return (
    <tr>
      <td
        className={classNames(
          'align-middle font-sans-serif fw-medium text-nowrap',
          {
            'border-bottom-0': isLast
          }
        )}
      >
        <div className="d-flex">
          <span className="text-700 ms-1">{item.index}</span>
        </div>
      </td>
      <td
        className={classNames('align-middle', {
          'border-bottom-0': isLast
        })}
      >
        {item.name}
      </td>
      <td
        className={classNames('align-middle text-center', {
          'border-bottom-0': isLast
        })}
      >
        {item.description}
      </td>
      {/* <td
        className={classNames('align-middle text-end', {
          'border-bottom-0': isLast
        })}
      >
        <ActionButton
          icon="pencil-alt"
          title="Edit"
          variant="action"
          className="p-0 me-2"
          // onClick={() => editAction(item)}
        />
      </td> */}
    </tr>
  );
});

const TestStepTable = ({ steps }) => {
  return (
    <SimpleBarReact>
      <Table className="fs--1 mb-0 bg-white">
        <thead className="project_table_header text-800 py-3">
          <tr>
            <th className="text-nowrap pt-4 pb-3">ID</th>
            <th className="text-nowrap pt-4 pb-3">Name</th>
            <th className="text-nowrap pt-4 pb-3">Description</th>
            {/* <th className="text-nowrap pt-4 pb-3"></th> */}
          </tr>
        </thead>
        <tbody>
          {steps
            .sort((a, b) => {
              return a.index - b.index;
            })
            .map((item, index) => (
              <TestRow
                key={item.id}
                item={item}
                isLast={steps.length - 1 === index}
              />
            ))}
        </tbody>
      </Table>
    </SimpleBarReact>
  );
};

export default memo(TestStepTable);
