import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import MainLayout from '../layouts/MainLayout';
import ErrorLayout from '../layouts/ErrorLayout';
import AssessmentLayout from '../layouts/AssessmentLayout';

import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';

import Dashboard from 'components/pages/Dashboard';
import Projects from 'components/pages/Projects';
import Flow from 'components/pages/Flow';
import Assets from 'components/pages/Assets';
import Tamplates from 'components/pages/Tamplates';
import Workbench from 'components/pages/workbench';
import WorkbenchList from 'components/pages/workbench/list';
import Login from 'components/pages/auth/login';
import ProjectAssessments from 'components/pages/ProjectAssessments';
import AssessmentExecution from 'components/pages/AssessmentExecution';
import KillchainResult from 'components/pages/KillchainResult';
import RoleRoute from './RoleRoute';
import Account from 'components/pages/Account';
import Notifications from 'components/pages/Notifications';
import KillchainReports from 'components/pages/KillchainReports';
import AssessmentExecutionReport from 'components/pages/AssessmentExecutionReport';
import ResetPassword from 'components/pages/auth/ResetPassword';
import AuthChangePassword from 'components/pages/auth/AuthChangePassword';
import CreateNewAsset from 'components/pages/assets/CreateNewAsset';
import Integrations from 'components/pages/integrations';
import IntegrationsCreate from 'components/pages/integrations/create';
import IntegrationsEdit from 'components/pages/integrations/edit';

const FalconRoutes = () => {
  return (
    <Routes>
      <Route element={<ErrorLayout />}>
        <Route path="/" element={<Login />} />
        <Route path="forget-password" element={<ResetPassword />} />
        <Route path="reset-password" element={<AuthChangePassword />} />
        <Route path="errors/404" element={<Error404 />} />
        <Route path="errors/500" element={<Error500 />} />
      </Route>
      <Route path="/test" element={<CreateNewAsset />} />
      {/* //--- MainLayout Starts  */}

      <Route element={<MainLayout />}>
        {/*Dashboard*/}
        <Route
          path="/dashboard"
          element={
            <RoleRoute
              element={<Dashboard />}
              requiredRoles={RoleRoutes.dashboard}
            />
          }
        />
        <Route path="/account" element={<Account />} />
        <Route path="/notifications" element={<Notifications />} />
        <Route
          path="/projects"
          element={
            <RoleRoute
              element={<Projects />}
              requiredRoles={RoleRoutes.project}
            />
          }
        />
        <Route
          path="/projects/:id/assessments"
          element={
            <RoleRoute
              element={<ProjectAssessments />}
              requiredRoles={RoleRoutes.project}
            />
          }
        />
        <Route
          path="/assets"
          element={
            <RoleRoute element={<Assets />} requiredRoles={RoleRoutes.asset} />
          }
        />
        <Route
          path="/templates"
          element={
            <RoleRoute
              element={<Tamplates />}
              requiredRoles={RoleRoutes.template}
            />
          }
        />
        <Route
          path="/workbench"
          element={
            <RoleRoute
              element={<WorkbenchList />}
              requiredRoles={RoleRoutes.workbentch}
            />
          }
        />
        <Route
          path="/workbench/:id"
          element={
            <RoleRoute
              element={<Workbench />}
              requiredRoles={RoleRoutes.workbentch}
            />
          }
        />
        <Route path="/integrations" element={<Integrations />} />

      </Route>

      {/* //--- MainLayout end  */}

      <Route
        element={
          <RoleRoute
            element={<AssessmentLayout />}
            requiredRoles={RoleRoutes.assessment}
          />
        }
      >
        <Route
          path="assessments/:id/setup"
          //  element={
          //   <RoleRoute
          element={<Flow />}
        //   requiredRoles={RoleRoutes.assessment}
        // />}
        />
        <Route
          path="assessments/:id/results"
          // element={<RoleRoute
          element={<KillchainResult />}
        // requiredRoles={RoleRoutes.assessment}/>}
        />
        <Route
          path="assessments/:id/reports"
          // element={<RoleRoute
          element={<KillchainReports />}
        // requiredRoles={RoleRoutes.assessment}/>}
        />
        <Route
          path="assessments/:id/executions/:executionId"
          // element={<RoleRoute
          element={<AssessmentExecution />}
        // requiredRoles={RoleRoutes.assessment}/>}
        />
      </Route>
      <Route
        path="assessments/:id/executions/:executionId/report"
        // element={<RoleRoute
        element={<AssessmentExecutionReport />}
      // requiredRoles={RoleRoutes.assessment}/>}
      />

      {/* <Route path="/integrations/create" element={<IntegrationsCreate />} /> */}
      {/* <Route path="/integrations/:id" element={<IntegrationsEdit />} /> */}

      {/* <Navigate to="/errors/404" /> */}
      <Route path="*" element={<Navigate to="/errors/404" replace />} />
    </Routes>
  );
};

export default FalconRoutes;

// ORG_USER - normal user, no workbench, different dashboards
// ORG_ADMIN -- normal user, no workbench, different dashboards
// ADMIN -- workbench, admin panel
// ADMIN_SUPER -- admin panel
// ADMIN_DEVELOPER - only workbench

const RoleRoutes = {
  assessment: ['ORG_USER', 'ORG_ADMIN', 'ADMIN', 'ADMIN_SUPER'],
  project: ['ORG_USER', 'ORG_ADMIN', 'ADMIN', 'ADMIN_SUPER'],
  asset: ['ORG_USER', 'ORG_ADMIN', 'ADMIN', 'ADMIN_SUPER'],
  dashboard: ['ORG_USER', 'ORG_ADMIN', 'ADMIN', 'ADMIN_SUPER'],
  template: ['ORG_USER', 'ORG_ADMIN', 'ADMIN', 'ADMIN_SUPER'],
  integrations: ['ORG_USER', 'ORG_ADMIN', 'ADMIN', 'ADMIN_SUPER'],
  workbentch: ['ADMIN_DEVELOPER', 'ADMIN']
};
