import React from 'react';
import PropTypes from 'prop-types';
import SimpleBarReact from 'simplebar-react';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import ActionButton from 'components/common/ActionButton';
import ContentLoader from 'react-content-loader';
import TableLoader from 'components/common/TableLoader';

const getTotal = (data, key) =>
  data.reduce((acc, val) => acc + Number(val[key]), 0);

const ProjectTableRow = ({
  item,
  isLast,
  deleteAction,
  editAction,
  deletingProject
}) => {
  if (deletingProject && item.id === deletingProject) {
    return (
      <tr>
        <td
          colSpan={4}
          className="bg-danger text-white text-center fw-medium w-100 h-100"
        >
          Project Deleting...
        </td>
      </tr>
    );
  }
  return (
    <tr>
      <td
        className={classNames(
          'align-middle font-sans-serif fw-medium text-nowrap',
          {
            'border-bottom-0': isLast
          }
        )}
      >
        <Link className="text-700" to={`/projects/${item.id}/assessments`}>
          {item.name}
        </Link>
      </td>
      <td
        className={classNames('align-middle', {
          'border-bottom-0': isLast
        })}
      >
        {item.description}
      </td>
      <td
        className={classNames('align-middle text-center', {
          'border-bottom-0': isLast
        })}
      >
        {item._count.KillChain || 0}
      </td>
      <td
        className={classNames('align-middle text-end', {
          'border-bottom-0': isLast
        })}
      >
        <ActionButton
          icon="pencil-alt"
          title="Edit"
          variant="action"
          className="p-0 me-2"
          onClick={() => editAction(item)}
        />
        <ActionButton
          icon="trash-alt"
          title="Delete"
          variant="action"
          className="p-0 me-2 text-danger"
          onClick={() => deleteAction(item.id)}
        />
      </td>
    </tr>
  );
};
const ProjectsTable = ({
  data,
  isFetching,
  deleteAction,
  editAction,
  deletingProject
}) => {
  return (
    <SimpleBarReact>
      <Table className="fs--1 mb-0">
        <thead className="project_table_header text-800">
          <tr>
            <th className="text-nowrap">Project Name</th>
            <th className="text-nowrap">Description</th>
            <th className="text-nowrap text-center">Assessments</th>
            <th className="text-nowrap text-end"></th>
          </tr>
        </thead>
        <tbody>
          {renderTableRows(
            data,
            isFetching,
            deleteAction,
            editAction,
            deletingProject
          )}
        </tbody>
        <tfoot className="">
          <tr className="text-700 fw-bold py-1">
            <th></th>
            <th className="text-center"></th>
            <th className="text-center"></th>
            <th className="pe-x1 text-end"></th>
          </tr>
        </tfoot>
      </Table>
    </SimpleBarReact>
  );
};

export default ProjectsTable;

const renderTableRows = (
  data,
  isFetching,
  deleteAction,
  editAction,
  deletingProject
) => {
  if (isFetching) {
    return (
      <tr>
        <td className="px-0 py-1" colSpan={7}>
          <TableLoader />
        </td>
      </tr>
    );
  }
  if (data.length == 0) {
    return (
      <tr>
        <td colSpan="7" className="text-center text-muted fs-medium fs-0">
          No results found
        </td>
      </tr>
    );
  }

  return data.map((item, index) => (
    <ProjectTableRow
      key={item.id}
      item={item}
      isLast={data.length - 1 === index}
      deleteAction={deleteAction}
      editAction={editAction}
      deletingProject={deletingProject}
    />
  ));
};
