import Flex from 'components/common/Flex';
import SelectBox from 'components/common/SelectBox';
import TagBox from 'components/common/TagBox';
import SearchBox from 'components/navbar/top/SearchBox';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

export default function AddSelectorForm({
  search,
  setSearch,
  selectedEnv,
  setEnv,
  tags,
  handleTags
}) {
  return (
    <div>
      <Row className="my-3">
        <Col md={6} className="pe-0">
          <Flex>
            <SearchBox
              id="agent_search"
              searchInputValue={search}
              setSearchInputValue={setSearch}
              inputClass="bg-transparent border-0 border-bottom rounded-0 py-1"
              searchContainerClasses="w-100"
            />
          </Flex>
        </Col>
        <Col md={6} className="pe-0">
          <SelectBox
            options={[
              { value: 'windows', label: 'Windows' },
              { value: 'debian', label: 'Debian' },
              { value: 'macos', label: 'MacOS' }
            ]}
            placeholder="All Environments"
            value={selectedEnv}
            onChange={setEnv}
          />
        </Col>
      </Row>
      <Row>
        <Col className="pe-0" style={{ height: 36 }}>
          <TagBox
            label={'All Tags'}
            id="asset_tag_search_box"
            tags={tags}
            updateTags={handleTags}
            key={tags}
          />
        </Col>
      </Row>
    </div>
  );
}
