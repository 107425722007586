import axios from 'axios';
axios.defaults.withCredentials = true;

const instance = axios.create({
  // baseURL: `https://portal.attackwiz.com/api`,
  baseURL: `/api`,
  withCredentials: true,
  headers: {
    'Content-type': 'application/json'
  }
});

export default instance;
