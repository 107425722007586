import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from 'context/Context';
import { useEffect } from 'react';
import { resetPasswordService } from 'services/authService';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Form } from 'react-bootstrap';
import useError from 'hooks/useError';

const schema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required')
});

const ResetPassword = () => {
  const { onLoginSuccess } = useContext(AuthContext);
  let navigate = useNavigate();
  let location = useLocation();
  const { getResponse } = useError();

  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({ email: '' });
  const [isFetching, setFetching] = useState(false);

  useEffect(() => {
    const savedAuthStateString = localStorage.getItem('authState');
    const savedAuthState = JSON.parse(savedAuthStateString);
    if (
      savedAuthState?.isLogedIn &&
      savedAuthState.isLogedIn == true &&
      savedAuthState?.userRole
    ) {
      onLoginSuccess({ ...savedAuthState });

      if (savedAuthState?.userRole == 'ADMIN_DEVELOPER') {
        navigate('/workbench');
      } else {
        navigate('/dashboard');
      }
    }
  }, []);

  const handleLogin = async e => {
    e.preventDefault();
    const formData = { email };
    const isFormValid = await schema.isValid(formData, {
      abortEarly: false // Prevent aborting validation after first error
    });
    if (!isFormValid) {
      schema.validate(formData, { abortEarly: false }).catch(error => {
        // Collect all errors in { fieldName: boolean } format:
        const validationErrors = {};
        error.inner.forEach(err => {
          validationErrors[err.path] = err.message;
        });
        setErrors(validationErrors);
      });
      return;
    }
    setFetching(true);
    const res = await resetPasswordService(email);
    getResponse(res)
      .then(res => {
        toast(
          <span className="text-success">
            Reset password send to your mail successfully.
          </span>
        );
        navigate('/');
      })
      .catch(err => console.error(err));
    setFetching(false);
  };

  return (
    <div>
      <Form onSubmit={handleLogin}>
        <label>Email</label>
        <input
          type="email"
          className="form-control"
          value={email}
          onChange={e => setEmail(e.target.value)}
          placeholder="Enter your email"
        />
        <div>
          {errors.email && (
            <span className="text-danger fs--2 fw-medium">{errors.email}</span>
          )}
        </div>
        {/* <hr /> */}
        <button
          type="submit"
          className="btn btn-primary mt-3"
          // onClick={handleLogin}
          disabled={isFetching}
        >
          {!isFetching ? 'Send Reset Link' : 'Sending...'}
        </button>
      </Form>
    </div>
  );
};

export default ResetPassword;
