import { generateQueryString, handleError } from 'helpers/utils';
import axios from './axiosInstance';

export const getAllKillChainService = async (
  projectId,
  page = 1,
  search,
  assessType
) => {
  let params = { projectId, search, page, assessType };
  const queryString = generateQueryString(params);
  let query = '';
  if (queryString) {
    query += `?${queryString}`;
  }
  try {
    const resp = await axios.get('/killchain' + query);
    return resp.data;
  } catch (err) {
    return handleError(err);
  }
};

export const createKillChainService = async data => {
  try {
    const resp = await axios.post('/killchain', data);
    return resp.data;
  } catch (err) {
    return handleError(err);
  }
};

export const updateKillChainService = async (id, data) => {
  try {
    const resp = await axios.put('/killchain/' + id, data);
    return resp.data;
  } catch (err) {
    return handleError(err);
  }
};

export const createKillChainWithoutTemplateService = async data => {
  try {
    const resp = await axios.post('/killchain', data);
    return resp.data;
  } catch (err) {
    return handleError(err);
  }
};

export const getKillChainByIdService = async id => {
  try {
    const resp = await axios.get('/killchain/' + id);
    return resp.data;
  } catch (err) {
    return handleError(err);
  }
};

export const addStepService = async data => {
  try {
    const resp = await axios.post('/killchain/step', data);
    return resp.data;
  } catch (err) {
    return handleError(err);
  }
};

export const updateStepsService = async data => {
  try {
    const resp = await axios.patch('/killchain/step', data);
    return resp.data;
  } catch (err) {
    return handleError(err);
  }
};

export const updateStepService = async (id, data) => {
  try {
    const resp = await axios.post('/killchain/step/' + id, data);
    return resp.data;
  } catch (err) {
    return handleError(err);
  }
};

export const deleteStepService = async (id, data) => {
  try {
    const resp = await axios.delete('/killchain/step/' + id);
    return resp.data;
  } catch (err) {
    return handleError(err);
  }
};

export const addAgentService = async (id, data) => {
  try {
    const resp = await axios.post(`/killchain/${id}/agents`, {
      agentIds: data
    });
    return resp.data;
  } catch (err) {
    return handleError(err);
  }
};

export const removeAgentService = async (id, data) => {
  try {
    const resp = await axios.delete(`/killchain/${id}/agents`, {
      data: { agentIds: data }
    });
    return resp.data;
  } catch (err) {
    return handleError(err);
  }
};

export const getKillChainAgentsService = async id => {
  try {
    const resp = await axios.get(`/killchain/${id}/agents`);
    return resp.data;
  } catch (err) {
    return handleError(err);
  }
};

export const runKillchainService = async id => {
  try {
    const resp = await axios.post(`/killchain/${id}/executions`);
    return resp.data;
  } catch (err) {
    return handleError(err);
  }
};

export const getKillchainExecutionsService = async (id, page) => {
  let params = { page };
  const queryString = generateQueryString(params);
  let query = '';
  if (queryString) {
    query += `?${queryString}`;
  }
  try {
    const resp = await axios.get(`/killchain/${id}/executions${query}`);
    return resp.data;
  } catch (err) {
    return handleError(err);
  }
};

export const getKillchainExecutionByIdService = async (id, executionId) => {
  try {
    const resp = await axios.get(`/killchain/${id}/executions/${executionId}`);
    return resp.data;
  } catch (err) {
    return handleError(err);
  }
};

export const getKillchainExecutionsStepsService = async (
  id,
  executionId,
  page,
  osType
) => {
  let params = { executionId, page, osType };
  const queryString = generateQueryString(params);
  let query = '';
  if (queryString) {
    query += `?${queryString}`;
  }
  try {
    const resp = await axios.get(`/killchain/${id}/executions/steps${query}`);
    return resp.data;
  } catch (err) {
    return handleError(err);
  }
};

// @params id [killchain_id]
export const getKillchainExecutionList = async id => {
  try {
    const resp = await axios.get(`/killchain/${id}/executions/list`);
    return resp.data;
  } catch (err) {
    return handleError(err);
  }
};

// @params id [killchain_id]
export const updateKillchainExecutionStep = async (id, stepId, data) => {
  try {
    const resp = await axios.post(
      `/killchain/${id}/executions/step/${stepId}`,
      data
    );
    return resp.data;
  } catch (err) {
    return handleError(err);
  }
};

export const getKillchainExecutionDownloadService = async (id, executionId) => {
  try {
    const resp = await axios.get(
      `/killchain/${id}/executions/${executionId}/download`,
      { responseType: 'blob' }
    );
    return resp.data;
  } catch (err) {
    return handleError(err);
  }
};

export const getKillchainResultReport = async (id, executionId) => {
  try {
    const resp = await axios.get(
      `/killchain/${id}/executions/${executionId}/report`,
      { responseType: 'blob' }
    );
    return resp.data;
  } catch (err) {
    return handleError(err);
  }
};
