import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'helpers/initFA';
import './assets/scss/index.scss';

const container = document.getElementById('main');
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  container
);
