import ActionPreview from 'components/common/ActionPreview';
import React from 'react';
import { useEffect } from 'react';

export default function SelectedAssetCountText({ selectedRowIds, onChange }) {
  const count = Object.keys(selectedRowIds).length;

  useEffect(() => {
    let ids = Object.keys(selectedRowIds);
    onChange(ids);
  }, [selectedRowIds]);

  const renderActionView = count => {
    if (count) {
      return (
        <ActionPreview txVariant={'white'} bgVariant="primary">
          {count} Selected
        </ActionPreview>
      );
    }
  };
  return <div className="mt-4">{renderActionView(count)}</div>;
}
