export const integrationReducer = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'SAVE_ALL':
      return {
        ...state,
        all: payload
      };
    case 'SAVE_ALL_ORG':
      return {
        ...state,
        allByOrg: payload
      };
    default:
      return state;
  }
};
