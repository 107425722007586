import React, { useCallback, useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import CardHeader from 'components/common/CardHeader';

// import nmap from 'assets/img/tools/nmap.png';
// import nessus from 'assets/img/tools/nessus.png';
// import nexpose from 'assets/img/tools/nexpose.png';
// import aquatrivy from 'assets/img/tools/aquatrivy.png';
// import besecure from 'assets/img/tools/besecure.png';
import Flex from 'components/common/Flex';
import { getTopAptsService } from 'services/analyticServices';
import useError from 'hooks/useError';

export default function ToolsScan() {
  const [data, setData] = useState([]);
  const { getResponse } = useError();

  const getData = useCallback(async () => {
    const res = await getTopAptsService();
    getResponse(res)
      .then(res => {
        setData(res);
      })
      .catch(err => console.error(err));
  }, []);
  useEffect(() => {
    getData();
  }, []);
  return (
    <Card>
      <CardHeader
        title="Top APT Analytics"
        titleTag="h5"
        className="py-3 border-bottom"
        titleClass="text-black"
      >
        <div className="text-end mt-1 fs--2 fw-bold text-500">Last 30 days</div>
      </CardHeader>
      <Card.Body className="py-0">
        {data?.map((o, index) => (
          <Flex
            key={`tool-${o.templateName}-${index}`}
            className={
              'justify-content-between align-items-center border-bottom pb-2 pt-3'
            }
          >
            <Flex>
              {/* <img src={nmap} height={24} /> */}
              <p className="mb-1">{o.templateName}</p>
            </Flex>
            <div className="text-end">
              <h6>{o.runCount}</h6>
              <p className="mb-0 fs--2">Scans performed</p>
            </div>
          </Flex>
        ))}
      </Card.Body>
    </Card>
  );
}
