import ReactEChartsCore from 'echarts-for-react/lib/core';
import { LineChart, BarChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
  ToolboxComponent,
  DataZoomComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { getColor } from 'helpers/utils';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';

echarts.use([
  GridComponent,
  LineChart,
  BarChart,
  CanvasRenderer,
  LegendComponent,
  TooltipComponent,
  TitleComponent,
  ToolboxComponent,
  DataZoomComponent
]);

const getOptions = data => ({
  color: [getColor('primary'), getColor('success'), getColor('danger')],
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross',
      crossStyle: {
        color: '#999'
      }
    }
  },
  dataZoom: [
    {
      type: 'inside',
      xAxisIndex: 0,
      zoomLock: true,
      width: 100,
      right: 10,
      top: 0,
      start: 25,
      end: 100,
      handleSize: 20
    }
  ],
  legend: {
    data: ['Success', 'Failure', 'Combined Outcome']
  },
  xAxis: [
    {
      type: 'category',
      data: data?.categories || [],
      axisPointer: {
        type: 'shadow'
      },
      axisLabel: {
        rotate: 75
      }
    }
  ],
  yAxis: [
    {
      type: 'value',
      name: 'Scenario Runs',
      min: 0,
      max: 100,
      interval: 10,
      nameLocation: 'center',
      nameGap: 30
      //   axisLabel: {
      //     formatter: '{value} ml'
      //   }
    }
  ],
  series: [
    {
      name: 'Combined Outcome',
      type: 'bar',
      tooltip: {
        valueFormatter: function (value) {
          return value;
        }
      },
      data: data?.stepData || [],
      barWidth: 24
    },
    {
      name: 'Success',
      type: 'line',
      yAxisIndex: 0,
      tooltip: {
        valueFormatter: function (value) {
          return value;
        }
      },
      data: data?.successData || [],
      smooth: true
    },
    {
      name: 'Failure',
      type: 'line',
      yAxisIndex: 0,
      tooltip: {
        valueFormatter: function (value) {
          return value;
        }
      },
      data: data?.failureData || [],
      smooth: true
    }
  ],
  grid: { left: 30, bottom: 16, right: 24, top: 42, containLabel: true }
});
export default function StepGraph({ data }) {
  const chartRef = useRef(null);

  return (
    <div className="bg-white">
      <h5 className="pt-4 px-4">HISTORICAL RUN RESULTS</h5>
      <div className="position-relative">
        <ReactEChartsCore
          ref={chartRef}
          echarts={echarts}
          option={getOptions(data)}
          style={{ height: '26rem', width: 'inherit', maxWidth: '100%' }}
        />
      </div>
    </div>
  );
}
