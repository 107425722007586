import React, { useEffect, useState, useRef } from 'react';

const useDimensions = () => {
  const ref = useRef(null);

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setWidth(ref.current.offsetWidth);
      setHeight(ref.current.offsetHeight);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return { ref, width, height };
};

export default useDimensions;
