import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import SelectBox from 'components/common/SelectBox';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import RenderAppliedFilters from './RenderAppliedFilters';
import { ExecutionContext } from 'context/Context';
import { getDateTimeByString } from 'helpers/utils';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  getKillchainExecutionDownloadService,
  getKillchainExecutionList
} from 'services/killchainService';
import { toast } from 'react-toastify';
import eventBus from 'helpers/eventBus';
import useError from 'hooks/useError';

let osData = [
  { value: 'windows', label: 'Windows' },
  { value: 'macos', label: 'Mac' },
  { value: 'debian', label: 'Debian' }
];

export default function ResultFilterForm() {
  const { id } = useParams();
  const { getResponse } = useError();
  const { executionState, updateAllExecutionById } =
    useContext(ExecutionContext);
  const [selectedRunId, setRunId] = useState(null);
  const [selectedOS, setOS] = useState(null);
  const [filters, setFilters] = useState([]);
  const [sources, setSources] = useState([]);
  const [isOptionsLoading, setOptionsLoading] = useState(false);

  const getExecutionsList = useCallback(async () => {
    setOptionsLoading(true);
    const res = await getKillchainExecutionList(id);
    getResponse(res)
      .then(res => {
        let executions = [];
        res?.map(step => {
          executions.push({
            value: step?.id,
            label: getDateTimeByString(step?.createdAt)
          });
        });
        setSources([
          ...new Map(executions.map(item => [item['value'], item])).values()
        ]);
        setOptionsLoading(false);
      })
      .catch(err => {
        setOptionsLoading(false);
      });
  }, []);

  useEffect(() => {
    getExecutionsList();
  }, []);

  useEffect(() => {
    let fils = [
      {
        title: 'Assessment run',
        key: 'selectedRunId',
        value: selectedRunId?.label || null
      },
      {
        title: 'Operating System',
        key: 'selectedOS',
        value: selectedOS?.label || null
      }
    ];
    setFilters(fils.filter(fil => fil.value != null));
  }, [selectedOS, selectedRunId]);

  useEffect(() => {
    let excId = selectedRunId?.value || null;
    let osType = selectedOS?.value || null;
    updateAllExecutionById(id, excId, osType);
  }, [selectedRunId, selectedOS]);

  const handleRunChange = value => {
    setRunId(value);
  };

  const handleOSChange = value => {
    setOS(value);
  };

  const handleRemoveFilter = key => {
    if (key == 'selectedRunId') {
      setRunId(null);
      return;
    }
    if (key == 'selectedOS') {
      setOS(null);
      return;
    }
  };

  const removeAll = () => {
    setRunId(null);
    setOS(null);
  };

  const handleRefresh = () => {
    getExecutionsList();
    if (selectedRunId == null) {
      eventBus.dispatch('killchain_results_refresh');
      return;
    }
    removeAll();
  };

  const handleExportCSV = async () => {
    if (!selectedRunId?.value)
      return toast(<span className="text-warning">Select source run.</span>);
    const res = await getKillchainExecutionDownloadService(
      id,
      selectedRunId?.value
    );

    getResponse(res)
      .then(res => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file.csv');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch(err => {});
  };

  return (
    <div className="bg-white py-1 px-3 mb-3">
      <Row className="border-bottom">
        <Col md="auto" className="pe-0">
          <SelectBox
            title={'Select Source Run'}
            options={sources}
            optionClass={'fs--1'}
            selectClass={'fs--1'}
            titleClass={'fs--2'}
            value={selectedRunId}
            onChange={handleRunChange}
            isOptionsLoading={isOptionsLoading}
          />
        </Col>
        <Col md="auto" className="pe-0">
          <SelectBox
            title={'Select Operating System'}
            options={osData}
            optionClass={'fs--1'}
            selectClass={'fs--1'}
            titleClass={'fs--2'}
            value={selectedOS}
            onChange={handleOSChange}
            key={selectedOS}
          />
        </Col>
        <Col className="pe-0 ">
          <div className="d-flex align-items-end text-900 pb-2 fw-medium h-100 fs--1">
            Show {executionState?.data?.length || 0} from{' '}
            {executionState?.total_count || 0} results
          </div>
        </Col>
        <Col className="pe-0">
          <div className="d-flex justify-content-end align-items-center h-100">
            <IconButton
              className="me-2"
              variant="falcon-default"
              icon="download"
              iconAlign="left"
              transform="shrink-3"
              size="sm"
              onClick={handleExportCSV}
            >
              EXPORT CSV
            </IconButton>
          </div>
        </Col>
      </Row>
      <Flex className={'pt-2 pb-1'}>
        <div className="flex-grow-1">
          <RenderAppliedFilters
            key={filters}
            filters={filters}
            removeFilter={handleRemoveFilter}
            removeAll={removeAll}
          />
        </div>
        <div>
          <Button
            className="mx-2"
            variant="falcon-default"
            size="sm"
            onClick={handleRefresh}
          >
            REFRESH
          </Button>
        </div>
      </Flex>
    </div>
  );
}
