import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import CloseButton from './CloseButton';

export default function DeleteConfirmModal({
  show,
  handleClose,
  onDelete,
  isLoading,
  message
}) {
  return (
    <React.Fragment>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Delete</Modal.Title>
          <CloseButton onClick={handleClose} disabled={isLoading} />
        </Modal.Header>
        <Modal.Body>{message}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="falcon-default"
            className="py-2 px-4"
            onClick={handleClose}
            size="sm"
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            size="sm"
            className="py-2 px-4 md-auto"
            onClick={onDelete}
            disabled={isLoading}
          >
            {isLoading ? 'Deleting...' : 'Delete'}
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}
