import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CloseButton from 'components/common/CloseButton';
import classNames from 'classnames';

const SearchBox = ({
  id = '',
  inputClass = '',
  searchContainerClasses = '',
  searchInputValue,
  setSearchInputValue
}) => {
  return (
    <div className={classNames('search-box', searchContainerClasses)}>
      <Form className="position-relative">
        <Form.Control
          type="search"
          placeholder="Search..."
          aria-label="Search"
          className={`rounded-pill search-input ${inputClass}`}
          value={searchInputValue}
          onChange={({ target }) => setSearchInputValue(target.value)}
          id={id}
        />
        <FontAwesomeIcon
          icon="search"
          className="position-absolute text-400 search-box-icon"
        />
        {searchInputValue && (
          <div className="search-box-close-btn-container">
            <CloseButton
              size="sm"
              noOutline
              className="fs--2"
              onClick={() => {
                setSearchInputValue('');
              }}
            />
          </div>
        )}
      </Form>
    </div>
  );
};

SearchBox.propTypes = {};

export default SearchBox;
