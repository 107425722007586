import React, { Fragment, memo, useContext } from 'react';
import { Accordion, Button, Card, Col, Row, Table } from 'react-bootstrap';
import SimpleBarReact from 'simplebar-react';
import RenderEmptyTestContent from './RenderEmptyTestContent';
import { AddStepContext, TestContext } from 'context/Context';
import Flex from 'components/common/Flex';
import TestStepTable from './TestStepTable';
// import AddTestStep from './AddTestStep';

function Test() {
  // const { toggleModal } = useContext(AddStepContext);
  const { testState } = useContext(TestContext);
  const groups = [...new Set(testState.tests.map(item => item.group))];
  return (
    <Fragment>
      {/* <Card className="overflow-hidden">
				<Card.Body className="p-0"> */}
      <div className="bg-primary text-white fs--1 fw-medium py-2 px-3">
        <Row className="justify-content-between align-items-center">
          <Col className="">
            <div>Group Name</div>
          </Col>
          {/* <Col className="col-auto">
						<Button
							onClick={toggleModal}
							size="small"
							variant="light"
						>
							Add Step
						</Button>
					</Col> */}
        </Row>
      </div>
      <SimpleBarReact>
        <Accordion defaultActiveKey="0" className={'bg-white'}>
          {/* TODO: change this to component */}
          {groups.map(group => (
            <Accordion.Item eventKey={'group' + group} key={'group' + group}>
              <Accordion.Header>
                <Flex
                  className={'text-900'}
                  style={{ fontSize: 15 }}
                  alignItems={'center'}
                >
                  {group}
                </Flex>
              </Accordion.Header>
              <Accordion.Body className="bg-200">
                <TestStepTable
                  steps={testState?.tests?.filter(t => t?.group === group)}
                />
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
        {testState?.tests && testState?.tests?.length === 0 && (
          <RenderEmptyTestContent />
        )}
      </SimpleBarReact>
      {/* <AddTestStep /> */}
    </Fragment>
  );
}

export default Test;
