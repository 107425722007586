import React, { useState } from 'react';
import axios from 'services/axiosInstance';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from 'context/Context';
import { useEffect } from 'react';
import useClearContextState from 'hooks/useClearContextState';
import { Form } from 'react-bootstrap';

const Login = () => {
  const { clearContextState } = useClearContextState();
  const { isLogedIn, userRole, onLoginSuccess, saveRole } =
    useContext(AuthContext);
  let navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setErrors] = useState('');
  const [isFetching, setFetching] = useState(false);

  const [searchParams] = useSearchParams();
  const authfailed = searchParams.get('authfailed');

  useEffect(() => {
    if (authfailed) {
      clearContextState();
      return;
    }
    const savedAuthStateString = localStorage.getItem('authState');
    const savedAuthState = JSON.parse(savedAuthStateString);
    if (
      savedAuthState?.isLogedIn &&
      savedAuthState.isLogedIn == true &&
      savedAuthState?.userRole
    ) {
      onLoginSuccess({ ...savedAuthState });
      if (authfailed) {
        navigate(authfailed);
      } else {
        if (savedAuthState?.userRole == 'ADMIN_DEVELOPER') {
          navigate('/workbench');
        } else {
          navigate('/dashboard');
        }
      }
    }
  }, []);

  const handleLogin = async e => {
    e.preventDefault();
    setFetching(true);
    await axios
      .post(
        `/auth/login`,
        {
          email,
          password
        },
        {
          withCredentials: true,
          headers: { 'Content-Type': 'application/json' }
        }
      )
      .then(res => {
        onLoginSuccess({
          userRole: res.data.role,
          orgId: res.data.organizationId
        });
        setFetching(false);
        if (authfailed) {
          navigate(authfailed);
        } else {
          navigate('/dashboard');
        }
      })
      .catch(err => {
        setErrors(err?.response?.data?.message || 'Something went wrong');
        setFetching(false);
      });
  };

  return (
    <div>
      <p className="text-center">
        Adversary Warfare Perfected: Emulation at Your Command
      </p>
      <Form onSubmit={handleLogin}>
        {error && error.length > 0 && (
          <div className="alert alert-danger">{error}</div>
        )}
        <label>Email</label>
        <input
          type="email"
          className="form-control"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />

        {/* <hr /> */}
        <label className="mt-3">Password</label>
        <input
          type="password"
          className="form-control"
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
        <div className="d-flex justify-content-between mt-3">
          <button
            type="submit"
            className="btn btn-primary "
            // onClick={handleLogin}
            disabled={isFetching}
          >
            {!isFetching ? 'Login' : 'Logging in...'}
          </button>
          <Link to={'/forget-password'}>Forget Password</Link>
        </div>
      </Form>
    </div>
  );
};

export default Login;
