import React, { useContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import { EditStepContext, StepContext } from 'context/Context';
import { editStepReducer } from 'reducers/editStepReducer';

const EditStepProvider = ({ children }) => {
  const { updateScenarioType } = useContext(StepContext);
  const initData = {
    selectedScenario: null,
    stepData: null,
    showModal: false
  };
  const [editStepState, dispatch] = useReducer(editStepReducer, initData);

  const toggleModal = () => {
    if (!editStepState.showModal) {
      updateScenarioType('edit');
    } else {
      updateScenarioType(null);
    }
    dispatch({
      type: 'TOGGLE_EDIT_STEP_MODAL'
    });
  };
  const updateScenario = techId => {
    dispatch({ type: 'UPDATE_SCENARIO', payload: techId });
  };
  const resetScenario = () => {
    dispatch({ type: 'RESET_SCENARIO' });
  };
  const resetEditStep = () => {
    dispatch({ type: 'RESET', payload: initData });
  };
  return (
    <EditStepContext.Provider
      value={{
        editStepState,
        toggleModal,
        updateScenario,
        resetScenario,
        resetEditStep
      }}
    >
      {children}
    </EditStepContext.Provider>
  );
};

EditStepProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default EditStepProvider;
