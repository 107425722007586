import React from 'react';
import PropTypes from 'prop-types';
import SimpleBarReact from 'simplebar-react';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import ActionButton from 'components/common/ActionButton';
import ContentLoader from 'react-content-loader';
import TableLoader from 'components/common/TableLoader';
import TagView from 'components/common/TagView';

const getTotal = (data, key) =>
  data.reduce((acc, val) => acc + Number(val[key]), 0);

const AssetResultOverviewTableRow = ({ item, isLast }) => {
  return (
    <tr>
      <td
        className={classNames(
          'align-middle font-sans-serif fw-medium text-nowrap',
          {
            'border-bottom-0': isLast
          }
        )}
      >
        entique0-12
      </td>
      <td
        className={classNames('align-middle', {
          'border-bottom-0': isLast
        })}
      >
        step 13
      </td>
      <td
        className={classNames('align-middle ', {
          'border-bottom-0': isLast
        })}
      >
        It is a long established fact that a reader will be distracted by the
        readable content of a page when looking at its layout. The point of
        using Lorem Ipsum is that
      </td>
      <td
        className={classNames('align-middle', {
          'border-bottom-0': isLast
        })}
      >
        <TagView data={['Pronama']} maxTagShow={3} />
      </td>
    </tr>
  );
};
const AssetResultOverviewTable = ({ data, isFetching }) => {
  return (
    <Table className="fs--1 mb-0">
      <thead
        className="text-black fw-medium"
        style={{ backgroundColor: '#eee' }}
      >
        <tr>
          <th className="w-25 text-nowrap">Asset</th>
          <th className="w-25 text-nowrap">Step</th>
          <th className="w-25 text-nowrap">Scenario</th>
          <th className="w-25 text-nowrap">Detected</th>
        </tr>
      </thead>
      <tbody>{renderTableRows(data, isFetching)}</tbody>
    </Table>
  );
};

export default AssetResultOverviewTable;

const renderTableRows = (data, isFetching) => {
  if (isFetching) {
    return (
      <tr>
        <td className="px-0 py-1" colSpan={7}>
          <TableLoader />
        </td>
      </tr>
    );
  }
  if (data.length == 0) {
    return (
      <tr>
        <td colSpan="7" className="text-center text-muted fs-medium fs-0">
          No results found
        </td>
      </tr>
    );
  }

  return data.map((item, index) => (
    <AssetResultOverviewTableRow
      key={item.id}
      item={item}
      isLast={data.length - 1 === index}
    />
  ));
};
