import React, { useContext } from 'react';
import AdvanceTable from 'components/common/AdvanceTable';
import AdvanceTablePagination from 'components/common/AdvanceTablePagination';
import AdvanceTableWrapper from 'components/common/AdvanceTableWrapper';
import Flex from 'components/common/Flex';
import TableLoader from 'components/common/TableLoader';
import TagView from 'components/common/TagView';
import { AgentContext } from 'context/Context';
import { getAgentStatusByRefactor } from 'helpers/utils';

const OS_DATA = {
  windows: 'Microsoft Windows',
  'win-10': 'Microsoft Windows 10 Pro N',
  'win-11': 'Microsoft Windows 11 Pro',
  'ubuntu-22': 'Ubuntu 22',
  debian: 'Debian',
  macos: 'Apple MacOS'
};
const STATUS_COLOR = {
  ready: 'info',
  deployed: 'success',
  dead: 'danger',
  inactive: 'warning',
  building: 'grey',
  undefined: 'grey'
};

let STATUS_LABEL = {
  building: 'NA',
  ready: 'Ready',
  deployed: 'Alive',
  dead: 'Dead',
  inactive: 'Inactive',
  undefined: 'NA' //handling for status otherthan this
};
const columns = [
  {
    accessor: 'hostname',
    Header: 'Hostname',
    Cell: rowData => {
      const { meta } = rowData.row.original;
      return meta?.computerName || 'NA';
    }
  },
  {
    accessor: 'sensors',
    Header: 'Sensors',
    Cell: rowData => {
      const { sensors } = rowData.row.original;
      return renderSensorTechnologies(sensors || []);
    }
  },
  {
    accessor: 'ip',
    Header: 'IP Address',
    cellProps: {
      className: 'fw-medium'
    },
    Cell: rowData => {
      return 'NA';
    }
  },
  {
    accessor: 'os',
    Header: 'Operating System',
    Cell: rowData => {
      const { platform } = rowData.row.original;
      return OS_DATA[platform];
    }
  },
  {
    accessor: 'tags',
    Header: 'Tags',
    Cell: rowData => {
      const { Taggable } = rowData.row.original;
      return (
        <TagView
          data={Taggable?.map(each => each?.tag).map(tag => tag?.name) || []}
          maxTagShow={1}
          emptyComponent={() => <span>No tags</span>}
        />
      );
    }
  },
  {
    accessor: 'status',
    Header: 'Status',
    Cell: rowData => {
      const { status, lastSeen } = rowData.row.original;
      let _status = getAgentStatusByRefactor(status, lastSeen);
      let statusLabel = STATUS_LABEL[_status];
      let statusColor = STATUS_COLOR[_status];
      return (
        <span className={`rounded-pill py-1 px-3 text-white bg-${statusColor}`}>
          {statusLabel}
        </span>
      );
    }
  }
];

const renderSensorTechnologies = sensors => {
  if (sensors.length == 0) {
    return 'no technology detected';
  }
  return sensors.map(sensor => (
    <Flex className="flex-wrap">
      <div className="mb-1 me-1">
        <img
          src={`assets/img/sensor_techs/${sensor.tech_name}.png`}
          height={40}
        />
      </div>
    </Flex>
  ));
};

export default function SelectAssetTable({
  actionView,
  onRowSelect,
  fetchData,
  agents,
  isFetching
}) {
  const {
    agentState: { selectedAgents }
  } = useContext(AgentContext);

  const handleOnChange = rowIds => {
    const agentIds = [];
    rowIds.forEach(id => {
      agentIds.push(agents?.data[id].id);
    });
    onRowSelect(agentIds);
  };

  const ActionView = actionView;
  if (isFetching) {
    return (
      <div className="px-0 py-1 w-100">
        <TableLoader />
      </div>
    );
  }

  if (agents?.data?.length === 0) {
    return (
      <div className="w-100">
        <div className="text-center fw-medium">No result found.</div>
      </div>
    );
  }

  const selectedRowIds = (() => {
    let saIds;
    if (selectedAgents?.data) {
      saIds = selectedAgents.data.map(d => d.Agent.id);
    } else {
      saIds = [];
    }
    let rows = {};
    agents?.data?.forEach((a, ind) => {
      if (saIds.includes(a.id)) {
        rows[ind] = saIds.includes(a.id);
      }
    });
    return rows;
  })();

  return agents?.data ? (
    <AdvanceTableWrapper
      columns={columns}
      data={agents?.data}
      sortable
      pagination
      perPage={10}
      selection
      selectedRows={selectedRowIds}
      selectionColumnWidth={30}
      fetchData={fetchData}
      controlledPageCount={agents?.total_pages}
      isManualPagination={true}
      currentPage={agents?.page}
    >
      <ActionView table onChange={handleOnChange} />
      <AdvanceTable
        table
        headerClassName="bg-200 text-900 text-nowrap align-middle"
        rowClassName="align-middle white-space-nowrap"
        tableProps={{
          striped: true,
          className: 'fs--1 mb-0 overflow-hidden'
        }}
      />
      <div className="mt-3 ">
        <AdvanceTablePagination table position="start" />
      </div>
    </AdvanceTableWrapper>
  ) : null;
}
