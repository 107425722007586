import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import AssetsTable from './assets/AssetsTable';

import { Button, Card, Col, Row } from 'react-bootstrap';
import AssetShowEditModal from './assets/AssetShowEditModal';
import AgentAddModal from './assets/AgentAddModal';
import { getAllAgentService } from 'services/agentService';
import { AgentContext, AuthContext, IntegrationContext } from 'context/Context';
import { toast } from 'react-toastify';
import usePagination from 'hooks/usePagination';
import { useLocation, useNavigate } from 'react-router-dom';
import { getOrganizationIntegrationService } from 'services/integrationService';
import useError from 'hooks/useError';

export default function Assets() {
  const { getResponse } = useError();
  const { orgId } = useContext(AuthContext);
  const { saveAllIntegrationsByOrg } = useContext(IntegrationContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [isAssetFetching, setAssetFetching] = useState(false);
  const [backgroundFetching, setBackgroundFetching] = useState(false);
  const getAllAgents = useCallback(async pageId => {
    if (backgroundFetching) {
      setAssetFetching(true);
    }
    const res = await getAllAgentService(pageId);
    getResponse(res)
      .then(res => {
        saveAllAgents(res);
        if (backgroundFetching) {
          setBackgroundFetching(true);
          setAssetFetching(false);
        }
      })
      .catch(err => {
        if (backgroundFetching) {
          setBackgroundFetching(true);
          setAssetFetching(false);
        }
      });
  }, []);
  const { Pagination, currentPage } = usePagination(getAllAgents);
  const { agentState, saveAllAgents } = useContext(AgentContext);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [show, setShow] = useState(false);
  const [showAddAgentModal, setAAM] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleAddAgentModalClose = () => setAAM(false);
  const handleAddAgentModalShow = () => setAAM(true);

  const getOrgIntegrations = async () => {
    const res = await getOrganizationIntegrationService(orgId);
    getResponse(res)
      .then(res => {
        saveAllIntegrationsByOrg(res);
      })
      .catch(err => console.error(err));
  };

  useEffect(() => {
    getAllAgents();
    getOrgIntegrations();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setBackgroundFetching(true);
      getAllAgents(currentPage);
    }, 30000);
    return () => {
      clearInterval(interval);
    };
  }, [currentPage]);

  const handleOnCreate = () => {
    // toast(<span className="text-primary">Generating Link started!</span>);
    getAllAgents();
  };

  const handleAgentSave = () => {
    getAllAgents();
  };

  const handleAgentRowClick = id => {
    const agentsData = agentState.agents.data.slice();
    const agent = agentsData.find(agent => agent.id == id);
    setSelectedAgent(agent);
    handleShow();
  };

  const handleAgentClose = () => {
    handleClose();
    setSelectedAgent(null);
  };

  return (
    <Fragment>
      <div>
        <Row className="flex-between-center">
          <Col md>
            <h5 className="mb-2 mb-md-0">Assets</h5>
          </Col>
          <Col xs="auto">
            <Button
              variant="primary"
              className="me-2 mb-1"
              onClick={handleAddAgentModalShow}
            >
              Add New Agent
            </Button>{' '}
          </Col>
        </Row>
        <Card className="overflow-hidden my-4">
          <Card.Body className="p-0">
            <AssetsTable
              data={agentState.agents?.data || []}
              onRowClick={handleAgentRowClick}
              isLoading={isAssetFetching}
            />
          </Card.Body>
        </Card>
      </div>
      {agentState.agents?.total_pages ? (
        <Pagination totalPages={agentState.agents.total_pages} />
      ) : null}
      {selectedAgent && (
        <AssetShowEditModal
          show={show}
          data={selectedAgent}
          handleClose={handleAgentClose}
          onAssetSave={handleAgentSave}
        />
      )}
      <AgentAddModal
        show={showAddAgentModal}
        handleClose={handleAddAgentModalClose}
        onCreate={handleOnCreate}
      />
    </Fragment>
  );
}
