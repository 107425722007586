import axios from './axiosInstance';
import { generateQueryString, handleError } from 'helpers/utils';

export const getAllTemplatesService = async (
  page = 1,
  search = null,
  type = null
) => {
  let params = { search, page, type };
  const queryString = generateQueryString(params);
  let query = '';
  if (queryString) {
    query += `?${queryString}`;
  }
  try {
    const resp = await axios.get('/template' + query);
    return resp.data;
  } catch (err) {
    return handleError(err);
  }
};

export const getTemplateById = async id => {
  try {
    const resp = await axios.get('/template/' + id);
    return resp.data;
  } catch (err) {
    return handleError(err);
  }
};
