import React from 'react';
import { Card } from 'react-bootstrap';
import ExecutionResultTable from './killchain/results/ExecutionResultTable';
import { useLayoutEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useContext } from 'react';
import { ExecutionContext, StepContext } from 'context/Context';
import { getKillchainExecutionsStepsService } from 'services/killchainService';
import { useState } from 'react';
import usePagination from 'hooks/usePagination';
import { useEffect } from 'react';
import StepGraph from './killchain/results/StepGraph';
import { toast } from 'react-toastify';
import ResultFilterForm from './killchain/results/ResultFilterForm';
import { getDateTimeByString } from 'helpers/utils';
import GraphLoader from 'components/common/GraphLoader';
import eventBus from 'helpers/eventBus';

export default function KillchainResult() {
  const navigate = useNavigate();
  const [isFetching, setFetching] = useState(false);
  const { executionState, saveAllExecutions, getAllExecution } =
    useContext(ExecutionContext);

  const { id } = useParams();
  const getAllAssementsByPage = n => {
    getAllExecution(id, n);
  };

  const { Pagination } = usePagination(getAllAssementsByPage);
  const { updateKillchain } = useContext(StepContext);

  useLayoutEffect(() => {
    updateKillchain(id);
  }, []);

  useEffect(() => {
    if (executionState.data.length == 0) {
      getAllExecution(id, 1);
    }
    eventBus.on('killchain_results_refresh', data => getAllExecution(id, 1));
    return () => {
      eventBus.remove('killchain_results_refresh');
    };
  }, []);

  return (
    <React.Fragment>
      <ResultFilterForm />
      <RenderStepGraph
        data={executionState?.reports || []}
        isFetching={executionState?.isExecutionFetching}
      />
      <Card className="overflow-hidden my-4">
        <Card.Body className="p-0">
          <ExecutionResultTable
            data={executionState.data}
            isFetching={executionState?.isExecutionFetching}
          />
        </Card.Body>
      </Card>
      {executionState?.total_pages ? (
        <Pagination totalPages={executionState.total_pages} />
      ) : null}
    </React.Fragment>
  );
}

const RenderStepGraph = ({ data, isFetching }) => {
  if (isFetching) {
    return <GraphLoader />;
  }
  if (data.length == 0) {
    return null;
  }
  let categories = [];
  let successData = [];
  let failureData = [];
  let stepData = [];
  Object.keys(data).map(key => {
    categories.push(getDateTimeByString(key));
    successData.push(data[key].success);
    failureData.push(data[key].failed);
    stepData.push(data[key].success + data[key].failed + data[key].pending);
  });
  let graphData = {
    categories,
    stepData,
    successData,
    failureData
  };
  return <StepGraph data={graphData} />;
};
