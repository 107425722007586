import CardHeader from 'components/common/CardHeader';
import React, { memo, useState } from 'react';
import { Card, Table } from 'react-bootstrap';
import { MdEdit, MdHelp, MdRefresh, MdDelete } from 'react-icons/md';
import AssetSelectorModal from './AssetSelectorModal';
import Flex from 'components/common/Flex';
import { getAgentStatusByRefactor } from 'helpers/utils';
import classNames from 'classnames';
import useKillchainAgent from 'hooks/useKillchainAgent';
import usePagination from 'hooks/usePagination';
import TableLoader from 'components/common/TableLoader';

const STATUS_COLOR = {
  ready: 'info',
  deployed: 'success',
  dead: 'danger',
  inactive: 'warning',
  building: 'grey',
  undefined: 'grey'
};

let STATUS_LABEL = {
  building: 'NA',
  ready: 'Ready',
  deployed: 'Alive',
  dead: 'Dead',
  inactive: 'Inactive',
  undefined: 'NA' //handling for status otherthan this
};

function SelectedAssets({ id }) {
  const { selectedAgents, getAllAgents, refreshKillchainAgents } =
    useKillchainAgent(id);
  const { Pagination } = usePagination(getAllAgents);
  const [showAssetSelectorModal, setSASM] = useState(false);
  const openSASM = () => setSASM(true);
  const closeSASM = () => setSASM(false);

  const handleAgentRefresh = async () => {
    refreshKillchainAgents();
  };

  return (
    <>
      <Card className="my-4">
        <CardHeader
          title="Selected Assets"
          titleTag="h5"
          className="py-3 border-bottom"
          titleClass="text-black"
          endEl={
            <div>
              <MdEdit
                size={20}
                className="me-3 hover-pointer"
                onClick={openSASM}
              />
              <MdRefresh
                size={20}
                onClick={handleAgentRefresh}
                className="me-3 hover-pointer"
              />
              <MdHelp size={20} className=" hover-pointer" />
            </div>
          }
        />
        <Card.Body className="bg-300 font-sans-serif">
          <Table className="fs--1 mb-0" borderless>
            <thead className=" text-800">
              <tr>
                <th className="text-nowrap pt-0">Hostname</th>
                <th className="text-nowrap  pt-0">Sensors</th>
                <th className="text-nowrap pt-0 ">IP Address</th>
                <th className="text-nowrap  pt-0">Operating System</th>
                <th className="text-nowrap  pt-0 ">Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody className="bg-white">
              <RenderSelectedAssetRow id={id} />
            </tbody>
          </Table>
        </Card.Body>
      </Card>
      {selectedAgents?.total_pages ? (
        <Pagination totalPages={selectedAgents?.total_pages} />
      ) : null}
      <AssetSelectorModal
        show={showAssetSelectorModal}
        handleClose={closeSASM}
        id={id}
      />
    </>
  );
}

const RenderSelectedAssetRow = ({ id }) => {
  const { selectedAgents, isFetching, deleteAgent } = useKillchainAgent(id);

  if (isFetching) {
    return (
      <tr>
        <td className="px-0 py-1" colSpan={6}>
          <TableLoader />
        </td>
      </tr>
    );
  }

  if (selectedAgents?.data?.length === 0) {
    return (
      <tr>
        <td colSpan={6}>
          <div className="text-center fw-medium">No result found.</div>
        </td>
      </tr>
    );
  }
  return selectedAgents?.data
    ? selectedAgents?.data?.map(sa => {
        let status = getAgentStatusByRefactor(
          sa?.Agent?.status,
          sa?.Agent?.lastSeen
        );
        let statusLabel = STATUS_LABEL[status];
        let statusColor = STATUS_COLOR[status];
        return (
          <tr key={sa.id} className="border-top">
            <td>{sa?.Agent?.meta?.computerName || sa?.Agent?.name}</td>
            <td></td>
            <td>
              {sa?.Agent?.meta?.publicIp}/{sa?.Agent?.meta?.privateIp}
            </td>
            <td className="text-capitalize">{sa?.Agent?.platform || 'NA'}</td>
            <td
              className={classNames(
                'align-end  fw-medium',
                `text-${statusColor}`
                //   {
                //     'border-bottom-0': isLast
                //   }
              )}
            >
              <Flex className={'align-items-center'}>
                <div
                  style={{ width: 12, height: 12 }}
                  className={classNames(
                    `rounded-circle me-2 bg-${statusColor}`
                  )}
                ></div>
                {statusLabel}
              </Flex>
            </td>
            <td>
              <MdDelete
                className="hover-pointer"
                size={20}
                onClick={() => deleteAgent(sa.agentId)}
              />
            </td>
          </tr>
        );
      })
    : null;
};

export default memo(SelectedAssets);
