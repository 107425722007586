import React, { useRef } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { BiMovie } from 'react-icons/bi';
import { BsShieldFillExclamation, BsShieldFillCheck } from 'react-icons/bs';
import { FaShieldVirus } from 'react-icons/fa';
import {
  MdCheckCircle,
  MdMonitor,
  MdOutlinePlayCircleOutline
} from 'react-icons/md';
import { GoAlert } from 'react-icons/go/index';
import { GiRadarSweep } from 'react-icons/gi';
import { ImEye } from 'react-icons/im';
import ReportAttackGraphTable from './reports/ReportAttackGraphTable';
import AssetResultOverviewTable from './reports/AssetResultOverview';
import AssetSequenceTable from './reports/AssetSequenceTable';
import TagView from 'components/common/TagView';
export default function AssessmentExecutionReport() {
  return (
    <React.Fragment>
      {/* <div className="position-absolute bottom-2 w-100 d-flex justify-content-center">
        <Button onClick={generatePDF}>Generate PDF</Button>
      </div> */}
      <div className="font-sans-serif">
        <div style={{ height: '100vh' }} className="bg-400 ">
          <Container className="h-100 bg-white position-relative z-0">
            <div className="position-absolute top-50 start-0 translate-middle-y">
              <div>
                <h1
                  style={{
                    fontSize: '2149%',
                    fontWeight: 'bolder',
                    // marginLeft: 40.5,
                    color: '#ececec'
                  }}
                >
                  Attack Wiz
                </h1>
              </div>
            </div>
            <Row className="justify-content-start align-items-center h-100 position-relative z-1">
              <Col md={5}></Col>
              <Col md={5}>
                <div>
                  <div className="pt-5 mb-4 border-2 border-bottom border-top border-black">
                    <h1>Attack Graph Detailed Report</h1>
                    <h6 className="text-700 fw-semi-bold">REPORT:</h6>
                    <h6 className=" fw-semi-bold">
                      DETAILED ATTACK GRAPH REPORT
                    </h6>
                    <div className="my-5"></div>
                    <h6 className="text-700  fw-semi-bold">ASSESSMENT:</h6>
                    <h6 className=" fw-semi-bold">
                      DETAILED ATTACK GRAPH REPORT DETAILED ATTACK GRAPH REPORT
                      DETAILED ATTACK GRAPH REPORT DETAILED ATTACK GRAPH REPORT
                    </h6>
                  </div>
                  <div className="mb-3">
                    <h6 className="text-700  fw-semi-bold">
                      Report Generated on
                    </h6>
                    <h6 className=" fw-semi-bold">
                      DETAILED ATTACK GRAPH REPORT
                    </h6>
                  </div>
                  <div>
                    <h6 className="text-700  fw-semi-bold">
                      Report Generated by
                    </h6>
                    <h6 className=" fw-semi-bold">username: Cris Carreon</h6>
                    <h6 className=" fw-semi-bold">
                      email: criscarreon@gmail.com
                    </h6>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div style={{ height: '100vh' }}>
          <Container className="h-100">
            <Row className="justify-content-center align-items-center h-100">
              <Col>
                <div>
                  <div className="mt-4 mb-5">
                    <Row className="d-flex align-items-end justify-content-between">
                      <Col>
                        <h1 className="fw-bolder">Attack Wiz</h1>
                      </Col>
                      <Col>
                        <div className="text-end">
                          <h5 className="text-danger">
                            ATTACK GRAPH DETAILED REPORT
                          </h5>
                          <h6 className="text-700 fw-semi-bold">
                            Attack Graph Detailed Report Attack Graph Detailed
                            Report Attack Graph Detailed Report
                          </h6>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="mb-4">
                    <h1>EXECUTIVE SUMMARY</h1>
                    <h6 className="text-700">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book. It has survived not only five
                      centuries, but also the leap into electronic typesetting,
                      remaining essentially unchanged.
                    </h6>
                  </div>
                  <div>
                    <h5>DEFINATIONS</h5>
                    <h6 className="text-700 ">
                      <span className="fw-bold text-900">Scenario: </span>
                      perspiciatis unde omnis iste natus error sit voluptatem
                      accusantium doloremque laudantium, totam rem aperiam,
                      eaque ipsa
                    </h6>
                    <h6 className="text-700 ">
                      <span className="fw-bold text-900">Asset: </span>
                      explain to you how all this mistaken idea of denouncing
                      pleasure and praising pain was born and I will give you a
                      complete account of the system, and expound the actual
                      teachings of the great explorer of the truth, the
                      master-builder of human happiness.
                    </h6>
                    <h6 className="text-700 ">
                      <span className="fw-bold text-900">Result: </span>
                      accusamus et iusto odio dignissimos ducimus qui blanditiis
                      praesentium voluptatum
                    </h6>
                    <h6 className="text-700 ">
                      <span className="fw-bold text-900">Prevented: </span>
                      denounce with righteous indignation and dislike men who
                      are so beguiled and demoralized by the charms of pleasure
                      of the moment, so blinded by desire, that they cannot
                      foresee the pain and trouble that are bound to ensue;
                    </h6>
                    <h6 className="text-700 ">
                      <span className="fw-bold text-900">Detected: </span>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur.
                    </h6>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div style={{ height: '100vh' }}>
          <Container className="h-100">
            <Row className="justify-content-center align-items-center h-100">
              <Col>
                <div>
                  <h1>OVERALL STATUS</h1>
                  <div className="d-flex">
                    <div
                      className="d-flex flex-column border shadow align-items-center justify-content-center p-2 m-2"
                      style={{ width: '250px' }}
                    >
                      <BiMovie size={48} />
                      <h1 className="my-3 fw-bold">49</h1>
                      <h6 className="fw-bold mb-0 text-warning">
                        UNIQUE SCENARIO
                      </h6>
                    </div>
                    <div
                      className="d-flex flex-column border shadow align-items-center justify-content-center p-2 m-2"
                      style={{ width: '250px' }}
                    >
                      <MdMonitor size={48} />
                      <h1 className="my-3 fw-bold">2</h1>
                      <h6 className="fw-bold mb-0 text-info">TOTAL ASSETS</h6>
                    </div>
                    <div
                      className="d-flex flex-column align-items-center justify-content-center p-2 m-2"
                      style={{ width: '250px' }}
                    ></div>
                  </div>

                  <div className="d-flex">
                    <div
                      className="d-flex flex-column border shadow align-items-center justify-content-center p-2 m-2"
                      style={{ width: '250px' }}
                    >
                      <MdOutlinePlayCircleOutline size={48} />
                      <h1 className="my-3 fw-bold">100</h1>
                      <h6 className="fw-bold mb-0">TOTAL RESULTS</h6>
                    </div>
                    <div
                      className="d-flex flex-column border shadow align-items-center justify-content-center p-2 m-2"
                      style={{ width: '250px' }}
                    >
                      <GiRadarSweep size={48} />
                      <h1 className="my-3 fw-bold">42</h1>
                      <h6 className="fw-bold mb-0">PREVENTED</h6>
                    </div>
                    <div
                      className="d-flex flex-column border shadow align-items-center justify-content-center p-2 m-2"
                      style={{ width: '250px' }}
                    >
                      <ImEye size={48} />
                      <h1 className="my-3 fw-bold">33</h1>
                      <h6 className="fw-bold mb-0">DETECTED</h6>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div
          style={{ minHeight: '100vh', overflow: 'visible' }}
          className="mb-5"
        >
          <Container className="h-100">
            <Row className="justify-content-center align-items-center h-100">
              <Col>
                <div>
                  <h5 className="my-3 text-center">Attack Graph Overview</h5>
                  <div className="shadow rounded">
                    <div className="bg-primary text-white py-3 px-4">
                      Attack Graph
                    </div>
                    <ReportAttackGraphTable
                      data={[1, 2, 3, 4, 6]}
                      isFetching={false}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div style={{ minHeight: '100vh' }} className="mb-5">
          <Container className="h-100">
            <Row className="justify-content-center align-items-center h-100">
              <Col>
                <div>
                  <h5 className="my-4 text-center">Assets Results Overview</h5>
                  <div>
                    <Row>
                      <Col>
                        <div className="p-3 d-flex flex-column align-items-center border border-2">
                          <div className="d-flex align-items-center mb-3">
                            <BsShieldFillCheck
                              className="text-success"
                              size={48}
                            />
                            <h1 className="ms-2 mb-0 fw-bold">33</h1>
                          </div>
                          <h6 className="text-center">
                            Assets stopped the attack
                          </h6>
                        </div>
                      </Col>
                      <Col>
                        <div className="p-3 d-flex flex-column align-items-center border border-2">
                          <div className="d-flex align-items-center mb-3">
                            <BsShieldFillExclamation
                              className="text-danger"
                              size={48}
                            />
                            <h1 className="ms-2 mb-0 fw-bold">33</h1>
                          </div>
                          <h6 className="text-center">
                            Assets did not stop the attack
                          </h6>
                        </div>
                      </Col>
                      <Col>
                        <div className="p-3 d-flex flex-column align-items-center border border-2">
                          <div className="d-flex align-items-center mb-3">
                            <FaShieldVirus className="text-warning" size={48} />
                            <h1 className="ms-2 mb-0 fw-bold">33</h1>
                          </div>
                          <h6 className="text-center">
                            Assets did not finish the attack
                          </h6>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <div>
                    <div className="shadow rounded my-5">
                      <div className="bg-primary text-white py-3 px-4">
                        <MdCheckCircle
                          size={24}
                          className="text-success me-3"
                        />
                        Assets where the attack was Prevented
                      </div>
                      <AssetResultOverviewTable data={[]} isFetching={false} />
                    </div>
                    <div className="shadow rounded my-5">
                      <div className="bg-primary text-white py-3 px-4">
                        <GoAlert size={24} className="text-danger me-3" />
                        Assets where the attack was Not Prevented
                      </div>
                      <AssetResultOverviewTable
                        data={[1, 2, 3, 4, 6]}
                        isFetching={false}
                      />
                    </div>
                    <div className="shadow rounded my-5">
                      <div className="bg-primary text-white py-3 px-4">
                        <GoAlert size={24} className="text-warning me-3" />
                        Assets where the attack did not finish
                      </div>
                      <AssetResultOverviewTable data={[]} isFetching={false} />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div style={{ minHeight: '100vh' }} className="mb-5">
          <Container className="h-100">
            <Row className="justify-content-center align-items-center h-100">
              <Col>
                <div>
                  <h5 className="my-4 text-center">Results by Assets</h5>
                  <div>
                    <div className="p-4 border border-2">
                      <div className="d-flex">
                        <div className="">
                          <FaShieldVirus size={72} className="text-danger" />
                        </div>
                        <div className="ps-4">
                          <div>
                            <div className="d-flex mb-2">
                              <h5>entaiq10-2</h5>
                              <div className="ms-2 d-flex  align-items-center rounded-pill  px-3 bg-danger">
                                <h6 className="text-white mb-0">
                                  Not Prevented
                                </h6>
                              </div>
                            </div>
                            <h6>
                              <span>
                                Operating System:{' '}
                                <strong>Microsoft Windows 10 Pro N</strong>
                              </span>
                            </h6>
                            <h6>
                              <span>
                                IP Address: <strong>172.16.23.107</strong>
                              </span>
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*  */}
                  <div>
                    <div className="shadow rounded my-5">
                      <div className="bg-primary text-white py-3 px-4">
                        Attack graph sequence for entaiq10-2
                      </div>
                      <AssetSequenceTable data={[1, 2, 3]} isFetching={false} />
                    </div>
                  </div>
                  {/*  */}
                  <div>
                    <div className="border border-2 p-3">
                      <div>
                        <Row>
                          <Col sm={8}>
                            <div>
                              <h3>
                                Step 0 - 2022 - 05 Mustang Where Does it Come
                                From?
                              </h3>
                            </div>
                          </Col>
                          <Col sm={2}>
                            <div>
                              <h6>Prevention</h6>
                              <div className="d-flex flex-wrap">
                                <div className="d-flex align-items-center rounded-pill text-white px-3  py-1 bg-success">
                                  <h6 className="text-white mb-0">Prevented</h6>
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col sm={2}>
                            <div>
                              <h6>Detection</h6>
                              <TagView data={['Panorama']} />
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <div className="border border-2 p-3">
                        <div>
                          <Row>
                            <Col sm={8}>
                              <div>
                                <h3>Phase 1</h3>
                                <h6>
                                  Start time:{' '}
                                  <strong>12:23 pm on 04/13/2023</strong> End
                                  time: <strong>12:23 pm on 04/13/2023</strong>
                                </h6>
                              </div>
                            </Col>
                            <Col sm={2}>
                              <div>
                                <h6>Prevention</h6>
                                <div className="d-flex flex-wrap">
                                  <div className="d-flex align-items-center rounded-pill text-white px-3 py-1 bg-success">
                                    <h6 className="text-white mb-0">
                                      Prevented
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            </Col>
                            <Col sm={2}>
                              <div>
                                <h6>Detection</h6>
                                <div className="d-flex flex-wrap">
                                  <div className="d-flex align-items-center rounded-pill text-white px-3 py-1 bg-success">
                                    <h6 className="text-white mb-0">
                                      Detected
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <div className="my-3">
                          <h6>Activity details</h6>
                          <div className="border border-2 p-3 mb-3">
                            <div className="w-100 font-sans-serif">
                              <h6 style={{ lineHeight: 1.5 }}>
                                <strong>(04/12/2023)</strong>: Files will be
                                downloaded from the following host
                                [testing.scenarios.enackio-ntm. com']
                              </h6>
                              <h6 style={{ lineHeight: 1.5 }}>
                                <strong>(04/12/2023)</strong>: Attempting
                                downloading file from imp
                                mosting.scenarios.aitaclriq- nun
                                comldsfsal5753m7526320025m3ed4ei1dua94db2199432o71
                                106o5003c6¢fbe4almelicious~archivezip.
                              </h6>
                              <h6 style={{ lineHeight: 1.5 }}>
                                <strong>(04/12/2023)</strong>: I' Successfully
                                connected to httpﬂtestingjcenarios attackin- ntm
                                comid8f545TSoffDT5268200250b3ed4ai1dn894db2199432b7110605003c6:ibanalrcrous-archwezip
                                using Python Requests.
                              </h6>
                              <h6 style={{ lineHeight: 1.5 }}>
                                <strong>(04/12/2023)</strong>:- File download
                                was stopped by I connection error. most likely
                                caused by a Network Security Tool This phone
                                will be merited a: prevented.
                              </h6>
                            </div>
                          </div>
                        </div>
                        {/*  */}
                        <div className="my-3">
                          <h6 ckas>Observables</h6>
                          <div className="border border-2 p-3">
                            <div>
                              <div className="my-5">
                                <h6 className="fw-bold">Observable #1</h6>
                                <h6>
                                  Protocols: <strong>tcphttp</strong>
                                </h6>
                                <h6>
                                  Destination:
                                  <strong>
                                    testing.scenarios.attackiq-ntm.com
                                  </strong>
                                </h6>
                                <h6>
                                  HTTP Method: <strong>GET</strong>
                                </h6>
                                <h6>
                                  Source Port: <strong>59416</strong>
                                </h6>
                                <h6>
                                  Request Path:{' '}
                                  <strong>
                                    /5dhfjhjskhfjshjfskdhfjd9416.zip
                                  </strong>
                                </h6>
                                <div className="ps-3 border-start ">
                                  <h6>Object #1gdfggjgj fdhghghj</h6>
                                  <h6>
                                    id: <strong>tcphttp</strong>
                                  </h6>
                                  <h6>
                                    type:{' '}
                                    <strong>
                                      testing.scenarios.attackiq-ntm.com
                                    </strong>
                                  </h6>
                                  <h6>
                                    value: <strong>GET</strong>
                                  </h6>
                                  <h6>
                                    Defanged: <strong>59416</strong>
                                  </h6>
                                  <h6>
                                    spec_version: <strong>2.0.1</strong>
                                  </h6>
                                  <h6>
                                    resolves_to_refs:
                                    <strong>
                                      /5dhfjhjskhfjshjfskdhfjd9416.zip
                                    </strong>
                                  </h6>
                                </div>
                              </div>
                              <div className="my-5">
                                <h6 className="fw-bold">Observable #2</h6>
                                <h6>
                                  Protocols: <strong>tcphttp</strong>
                                </h6>
                                <h6>
                                  Destination:
                                  <strong>
                                    testing.scenarios.attackiq-ntm.com
                                  </strong>
                                </h6>
                                <h6>
                                  HTTP Method: <strong>GET</strong>
                                </h6>
                                <h6>
                                  Source Port: <strong>59416</strong>
                                </h6>
                                <h6>
                                  Request Path:{' '}
                                  <strong>
                                    /5dhfjhjskhfjshjfskdhfjd9416.zip
                                  </strong>
                                </h6>
                                <div className="ps-3 border-start ">
                                  <h6>Object #1gdfggjgj fdhghghj</h6>
                                  <h6>
                                    id: <strong>tcphttp</strong>
                                  </h6>
                                  <h6>
                                    type:{' '}
                                    <strong>
                                      testing.scenarios.attackiq-ntm.com
                                    </strong>
                                  </h6>
                                  <h6>
                                    value: <strong>GET</strong>
                                  </h6>
                                  <h6>
                                    Defanged: <strong>59416</strong>
                                  </h6>
                                  <h6>
                                    spec_version: <strong>2.0.1</strong>
                                  </h6>
                                  <h6>
                                    resolves_to_refs:
                                    <strong>
                                      /5dhfjhjskhfjshjfskdhfjd9416.zip
                                    </strong>
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="my-3">
                          <h6>Detections</h6>
                          <div className="border border-2 p-3">
                            <div>
                              <h6>
                                <strong>Detection #1</strong>: Falcon
                              </h6>
                              <h6>Events:</h6>
                              <h6>
                                Time: <strong>Falcon</strong>
                              </h6>
                              <h6>
                                Type: <strong>Falcon</strong>
                              </h6>
                              <h6>
                                Classification:{' '}
                                <strong>Falcon Detection Method</strong>
                              </h6>
                              <h6>
                                Description:
                                <br />
                                This 9mm: mate a suspicious ﬁle to disk. That
                                modded Illa meets the ﬁle Atlﬂbu‘la MI.
                                Ilnon'ﬂl'n's low-conﬁdence min-n detection
                                III-show. Mn Ibo niacin-d "II.
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*  */}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
}
