import CardHeader from 'components/common/CardHeader';
import FileUpload from 'components/common/FileUploader';
import { AuthContext } from 'context/Context';
import useError from 'hooks/useError';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Card, Col, Image as Img, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import {
  getOrgImageService,
  uploadOrgImageService
} from 'services/organizationService';

const blobToDataURL = blob => {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });
};

function OrganizationSettings() {
  const { getResponse } = useError();
  const { orgId } = useContext(AuthContext);
  const [image, setImage] = useState(null);
  const [isEditMode, setEditMode] = useState(false);

  useEffect(() => {
    getOrgImage();
  }, []);

  const getOrgImage = useCallback(async () => {
    const res = await getOrgImageService(orgId);
    getResponse(res)
      .then(async res => {
        const imageURL = URL.createObjectURL(res);
        // blobToDataURL(res.blob()).then(dataURL => {
        setImage(imageURL);
        // });
      })
      .catch(err => console.error(err));
  }, [orgId]);

  const uploadOrgImage = useCallback(
    async (formData, callback) => {
      const res = await uploadOrgImageService(orgId, formData);
      getResponse(res)
        .then(res => {
          callback();
        })
        .catch(err => console.error(err));
    },
    [orgId]
  );

  const handleSubmit = async data => {
    await new Promise((resolve, reject) => {
      const file = data?.uploadedFiles[0];
      if (!file) {
        reject(404);
        return;
      }
      const image = new Image();
      image.onload = () => {
        console.log('image', image.width, image.height);
        if (image.width > 256 || image.height > 64) {
          reject(403);
          return;
        }
        if (image.width / image.height !== 4) {
          reject(403);
          return;
        }
        resolve(file);
      };
      let url = URL.createObjectURL(file);
      image.src = url;
    })
      .then(file => {
        const formData = new FormData();
        formData.append('file', file);
        uploadOrgImage(formData, () => {
          setImage(file.preview);
          setEditMode(false);
        });
      })
      .catch(err => {
        if (err === 404) {
          toast(<span className="text-danger">Please select an image.</span>);
        } else if (err === 403) {
          toast(
            <span className="text-danger">
              Image must be in 4:1 ratio of max size 256x64.
            </span>
          );
        } else {
          console.err(err);
        }
      });
  };

  return (
    <Card>
      <CardHeader title="Organization Settings" />
      <Card.Body className="bg-light">
        <Row>
          <Col sm={4} md={3}>
            {!image || isEditMode ? (
              <>
                <div className="font-sans-serif fw-medium fs--2 mb-2">
                  The organization logo will be used for documentation purpose.
                  The Image resolution should be in 4:1 ratio of max size
                  256x64.
                </div>
                <FileUpload
                  onSubmit={handleSubmit}
                  multiple={false}
                  content="Drop your image here"
                />
              </>
            ) : (
              <div>
                <Img src={image} rounded className="w-100" />
              </div>
            )}
            {image && (
              <div>
                {isEditMode ? (
                  <div
                    onClick={() => setEditMode(false)}
                    className="btn btn-primary mt-3"
                  >
                    Cancel
                  </div>
                ) : (
                  <div
                    onClick={() => setEditMode(true)}
                    className="btn btn-primary mt-3"
                  >
                    Update
                  </div>
                )}
              </div>
            )}
          </Col>
          <Col></Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default OrganizationSettings;
