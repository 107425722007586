import React from 'react';
import ContentLoader from 'react-content-loader';

const GraphLoader = () => {
  return (
    <ContentLoader
      width={'100%'}
      height={420}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      className="bg-white"
    >
      {/* <rect x="0" y="0" rx="3" ry="3" width="100%" height="200" /> */}

      {/* Bar 1 */}
      <rect x="5%" y="180" rx="3" ry="3" width="5%" height="180" />

      {/* Bar 2 */}
      <rect x="15%" y="210" rx="3" ry="3" width="5%" height="150" />

      {/* Bar 3 */}
      <rect x="25%" y="180" rx="3" ry="3" width="5%" height="180" />

      <rect x="35%" y="210" rx="3" ry="3" width="5%" height="150" />
      <rect x="45%" y="180" rx="3" ry="3" width="5%" height="180" />

      {/* Bar 2 */}
      <rect x="55%" y="210" rx="3" ry="3" width="5%" height="150" />

      {/* Bar 3 */}
      <rect x="65%" y="180" rx="3" ry="3" width="5%" height="180" />
      <rect x="75%" y="210" rx="3" ry="3" width="5%" height="150" />
      <rect x="85%" y="180" rx="3" ry="3" width="5%" height="180" />
    </ContentLoader>
  );
};

export default GraphLoader;
