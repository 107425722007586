import { library } from '@fortawesome/fontawesome-svg-core';
import {
  // faAlignLeft,
  // faAlignRight,
  // faAngleRight,
  // faArchive,
  // faArrowDown,
  // faArrowLeft,
  // faArrowRight,
  // faArrowsAltV,
  // faArrowUp,
  // faAt,
  // faBan,
  faBell,
  // faBook,
  // faCalendarAlt,
  // faCalendarCheck,
  // faCalendarDay,
  // faCamera,
  // faCaretDown,
  // faCaretRight,
  // faCaretUp,
  // faCartPlus,
  faChartPie,
  // faCheck,
  // faCheckCircle,
  // faCheckDouble,
  // faCheckSquare,
  // faChevronDown,
  faChevronLeft,
  faChevronRight,
  // faChevronUp,
  // faCircle,
  // faCircleNotch,
  // faClock,
  // faCodeBranch,
  // faCog,
  // faComments,
  // faCopy,
  // faCrown,
  // faDollarSign,
  faEdit,
  faEllipsisH,
  // faEllipsisV,
  // faEnvelope,
  // faEnvelopeOpen,
  // faExchangeAlt,
  // faExclamation,
  // faExclamationCircle,
  // faExclamationTriangle,
  // faExternalLinkAlt,
  // faEye,
  // faFileAlt,
  // faFileArchive,
  // faFilePdf,
  // faFilter,
  // faFolderPlus,
  // faGift,
  // faGlobeAmericas,
  // faGlobe,
  // faHeart,
  faHome,
  // faIcons,
  // faImage,
  // faInfo,
  // faLink,
  // faList,
  // faListAlt,
  // faListUl,
  // faLocationArrow,
  // faLock,
  // faMinus,
  // faMapMarkerAlt,
  // faPalette,
  // faPaperclip,
  faPencilAlt,
  // faPercentage,
  // faTags,
  // faPhone,
  // faPlay,
  // faPlug,
  faPlus,
  // faPlusSquare,
  // faPoll,
  // faPrint,
  // faPuzzlePiece,
  // faQuestionCircle,
  // faRedo,
  // faReply,
  faSearch,
  // faShare,
  // faShareAlt,
  // faShoppingCart,
  // faSignInAlt,
  // faSlidersH,
  // faSmileBeam,
  // faSort,
  // faSortAlphaDown,
  // faSortAlphaUp,
  // faSortAmountDown,
  // faSortAmountUp,
  // faSortNumericDown,
  // faSortNumericUp,
  // faStar,
  // faStarHalfAlt,
  // faStream,
  // faTag,
  // faTh,
  // faThumbsUp,
  faTimes,
  // faTimesCircle,
  // faTools,
  // faTrash,
  faTrashAlt,
  faUser,
  // faUserCircle,
  // faUserFriends,
  // faUserPlus,
  // faUsers,
  // faVideo,
  // faCross,
  // faFlag,
  // faThumbtack,
  // faTable,
  // faChartLine,
  // faShapes,
  // faMap,
  // faFire,
  faLayerGroup,
  // faRocket,
  // faWrench,
  // faSun,
  // faMoon,
  // faAngleLeft,
  // faInfoCircle,
  // faSpinner,
  // faCapsules,
  // faTasks,
  // faCloudDownloadAlt,
  // faSyncAlt,
  // faBug,
  // faProjectDiagram,
  // faBolt,
  // faPaperPlane,
  // faLongArrowAltDown,
  // faLongArrowAltUp,
  // faInfinity,
  // faChessRook,
  // faCode,
  // faFile,
  // faMusic,
  // faMobile,
  // faBookOpen,
  // faTint,
  // faRedoAlt,
  // faTrophy,
  // faTicketAlt,
  // faFileImport,
  // faKey,
  // faPhoneAlt,
  // faObjectUngroup,
  // faFileDownload,
  // faEnvelopeOpenText,
  // faUserGraduate,
  // faChalkboardTeacher,
  // faGraduationCap,
  // faSignOutAlt,
  faDownload,
  // faFileUpload,
  // faExpandArrowsAlt,
  // faUnlockAlt,
  // faGripLines,
  // faComment,
  // faHeadphones,
  // faClosedCaptioning,
  // faMapPin,
  // faPlayCircle,
  // faPenNib,
  // faAward,
  // faBrush,
  // faHashtag,
  // faFileInvoiceDollar,
  // faBalanceScaleLeft,
  // faCommentDollar,
  // faHandHoldingWater,
  // faCameraRetro,
  // faUtensils,
  // faCreditCard,
  faSnowflake,
  faClone,
  faClipboard,
  faCube,
  faScroll,
  faReceipt,
  faCubes
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faBell,
  faChartPie,
  faChevronLeft,
  faChevronRight,
  faHome,
  faSearch,
  faTimes,
  faSnowflake,
  faClone,
  faClipboard,
  faCube,
  faScroll,
  faReceipt,
  faCubes,
  faLayerGroup,
  faUser,
  faDownload,
  faEllipsisH,

  // Solid
  // faCreditCard,
  // faEnvelopeOpenText,
  // faFileDownload,
  // faObjectUngroup,
  // faPhoneAlt,
  // faKey,
  // faFileImport,
  // faTicketAlt,
  // faChartPie,
  // faCopy,
  // faChessRook,
  // faSearch,
  // faHome,
  // faBell,
  // faPlay,
  // faChevronRight,
  // faChevronLeft,
  // faChevronUp,
  // faChevronDown,
  // faExchangeAlt,
  // faAngleRight,
  // faAngleLeft,
  // faEllipsisH,
  // faCrown,
  // faListAlt,
  // faCheck,
  // faBan,
  // faStream,
  // faArrowsAltV,
  // faSort,
  // faSortAlphaDown,
  // faSortAlphaUp,
  // faSortNumericDown,
  // faSortNumericUp,
  // faSortAmountDown,
  // faSortAmountUp,
  // faCheckCircle,
  // faUserCircle,
  // faQuestionCircle,
  // faLock,
  // faCircle,
  // faHeart,
  // faShareAlt,
  // faCaretRight,
  // faArrowDown,
  // faArrowUp,
  // faArrowLeft,
  // faArrowRight,
  // faPrint,
  // faDollarSign,
  // faLink,
  faPlus,
  // faMinus,
  // faUser,
  faPencilAlt,
  // faCamera,
  // faTimesCircle,
  // faSignInAlt,
  // faBook,
  // faPuzzlePiece,
  // faTrash,
  // faAlignLeft,
  // faAlignRight,
  // faPlug,
  // faTimes,
  // faFilter,
  // faExternalLinkAlt,
  // faCartPlus,
  // faShoppingCart,
  // faStar,
  // faStarHalfAlt,
  // faArchive,
  // faEnvelope,
  // faClock,
  // faStar,
  // faReply,
  // faLocationArrow,
  // faCog,
  // faPaperclip,
  // faImage,
  // faEllipsisV,
  // faEnvelopeOpen,
  // faRedo,
  // faSlidersH,
  faTrashAlt,
  // faFileArchive,
  // faFilePdf,
  // faFileAlt,
  // faEye,
  // faCaretUp,
  // faCodeBranch,
  // faExclamationTriangle,
  // faListUl,
  // faTh,
  // faGift,
  // faUsers,
  // faGlobeAmericas,
  // faGlobe,
  // faPlusSquare,
  // faCaretDown,
  // faUserPlus,
  // faCircleNotch,
  // faExclamation,
  // faThumbsUp,
  // faComments,
  // faPalette,
  faEdit
  // faTrashAlt,
  // faShare,
  // faCheckSquare,
  // faTools,
  // faCheckDouble,
  // faSmileBeam,
  // faPoll,
  // faPercentage,
  // faTags,
  // faIcons,
  // faList,
  // faUserFriends,
  // faExclamationCircle,
  // faFolderPlus,
  // faTag,
  // faAt,
  // faCalendarAlt,
  // faCalendarCheck,
  // faCalendarDay,
  // faMapMarkerAlt,
  // faCross,
  // faFlag,
  // faThumbtack,
  // faTable,
  // faChartLine,
  // faShapes,
  // faMap,
  // faFire,
  // faLayerGroup,
  // faRocket,
  // faWrench,
  // faSun,
  // faMoon,
  // faInfoCircle,
  // faSpinner,
  // faCapsules,
  // faTasks,
  // faCloudDownloadAlt,
  // faSyncAlt,
  // faBug,
  // faProjectDiagram,
  // faBolt,
  // faPaperPlane,
  // faLongArrowAltDown,
  // faLongArrowAltUp,
  // faInfinity,
  // faFile,
  // faMusic,
  // faMobile,
  // faBookOpen,
  // faTint,
  // faSnowflake,
  // faClone,
  // faClipboard,
  // faCube,
  // faScroll,
  // faReceipt,
  // faCubes,
);
