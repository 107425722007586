import CloseButton from 'components/common/CloseButton';
import React, { useContext } from 'react';
import { Button, Modal } from 'react-bootstrap';
import StepForm from './StepForm';
import ScenarioTable from './ScenarioTable';
import { AddStepContext, EditStepContext, StepContext } from 'context/Context';
import { addStepService, updateStepService } from 'services/killchainService';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import useError from 'hooks/useError';
import { capitalize } from 'helpers/utils';

export default function AddEditStep({ title, show, handleClose }) {
  const [isCalling, setCalling] = useState(false);
  const { getResponse } = useError();
  const {
    stepState: { scenarioType, nodes, currentKillchain, selectedStep },
    addNodesToStep,
    updateNodeDataProps
  } = useContext(StepContext);
  const {
    addStepState: { selectedScenarios },
    resetAddStep
  } = useContext(AddStepContext);
  const {
    editStepState: { selectedScenario },
    resetEditStep
  } = useContext(EditStepContext);

  const handleSave = () => {
    if (scenarioType == 'add') {
      handleAddSteps();
    } else {
      handleEditStep();
    }
  };

  const handleAddSteps = async () => {
    setCalling(true);
    let newNodes = [];
    for (const scn of selectedScenarios) {
      let data = {
        killChainId: currentKillchain,
        name: scn.name || scn.techniqueName,
        description: scn.description || scn.techniqueDescription,
        node: {
          id: uuidv4(),
          position: getNodePosition([...nodes, ...newNodes])
        },
        techniqueId: scn.id,
        edges: [],
        index: 2
      };
      const addStepRes = await addStepService(data);

      await getResponse(addStepRes)
        .then(res => {
          let advanceConfig = {};
          addStepRes?.supported_platforms?.forEach(platform => {
            let parser = addStepRes?.[platform]?.[0]?.commands?.[0]?.parser;
            if (parser) {
              advanceConfig = {
                ...advanceConfig,
                parserOptions: {
                  ...advanceConfig.parserOptions,
                  [platform]: parser?.map(p => ({
                    label: p.key
                      .split('_')
                      .map(e => capitalize(e))
                      .join(' '),
                    value: p.key
                  }))
                }
              };
            }
          });
          let newObject = {
            ...addStepRes.node,
            type: 'custom',
            data: {
              number: nodes.length + newNodes.length + 1,
              name: addStepRes.name,
              description: addStepRes.description,
              techniqueId: addStepRes.techniqueId,
              config: addStepRes.formSchema?.formSchema ?? null,
              variables: addStepRes.variables,
              parserKey: addStepRes.parserKey, // should come as {windows: value, debian: value, ...}
              filterOutputTo: addStepRes.filterOutputTo, // should come as [value]
              filterOutputFileName: addStepRes?.filterOutputFileName,
              action: 'play',
              detailStatus: 'noaction',
              status: 'noaction',
              stepId: addStepRes.id,
              index: addStepRes.index,
              tags:
                addStepRes?.Taggable?.map(each => each?.tag).map(
                  tag => tag?.name
                ) || [],
              successStepId: addStepRes.successStepId,
              failureStepId: addStepRes.failureStepId,
              ...advanceConfig
            }
          };
          if (nodes.length == 0) {
            newObject.sourcePosition = 'right';
          }
          newNodes.push(newObject);
        })
        .catch(err => {
          console.error(err);
          setCalling(false);
        });
    }
    addNodesToStep(newNodes);
    resetAddStep();
    setCalling(false);
  };

  const handleEditStep = async () => {
    setCalling(true);
    let node = nodes.find(n => n.id === selectedStep);
    const data = {
      name: selectedScenario?.name || selectedScenario?.techniqueName,
      description:
        selectedScenario?.description || selectedScenario?.techniqueDescription,
      techniqueId: selectedScenario.id
    };
    const res = await updateStepService(node.data.stepId, data);

    await getResponse(res)
      .then(res => {
        let advanceConfig = {};
        res?.supported_platforms?.forEach(platform => {
          let parser = res?.[platform]?.[0]?.commands?.[0]?.parser;
          if (parser) {
            advanceConfig = {
              ...advanceConfig,
              parserOptions: {
                ...advanceConfig.parserOptions,
                [platform]: parser
                  ?.filter(pArr => pArr?.name)
                  ?.map(p => ({
                    label: p.name,
                    value: p.key
                  }))
              }
            };
          }
        });
        let dataProps = {
          name: res.name,
          description: res.description,
          techniqueId: res.techniqueId,
          config: res.formSchema?.formSchema ?? null,
          variables: res.variables,
          parserKey: res.parserKey,
          filterOutputTo: res.filterOutputTo,
          ...advanceConfig
        };
        updateNodeDataProps(selectedStep, dataProps);
        resetEditStep();
        setCalling(false);
      })
      .catch(err => {
        setCalling(false);
      });
  };

  return (
    <React.Fragment>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="xl"
      >
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
          <CloseButton onClick={handleClose} disabled={isCalling} />
        </Modal.Header>
        <Modal.Body>
          <StepForm />
          <ScenarioTable />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="falcon-default"
            className="py-2 px-4"
            onClick={handleClose}
            disabled={isCalling}
            size="sm"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            variant="primary"
            size="sm"
            className="py-2 px-4"
            disabled={isCalling}
          >
            {isCalling ? 'Saving...' : 'Save'}
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

const getNodePosition = nodes => {
  if (nodes.length == 0) {
    return { x: 0, y: 50 };
  }
  let lastNode = nodes[nodes.length - 1];
  return { x: lastNode.position.x + 350, y: lastNode.position.y };
};

const getRefactoredTechniqueResponse = res => {
  let { id, ...remainingProperties } = res;
  return remainingProperties;
};
