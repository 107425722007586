import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import { ProjectContext } from 'context/Context';
import { projectReducer } from 'reducers/projectReducer';

const ProjectProvider = ({ children }) => {
  const initData = {
    data: []
  };
  const [projectState, dispatch] = useReducer(projectReducer, initData);

  const saveAllProjects = data => {
    dispatch({
      type: 'SAVE_ALL_PROJECT_DATA',
      payload: data
    });
  };
  return (
    <ProjectContext.Provider
      value={{
        projectState,
        saveAllProjects
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

ProjectProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default ProjectProvider;
