import Flex from 'components/common/Flex';
import TableLoader from 'components/common/TableLoader';
import { getDateTimeByString } from 'helpers/utils';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, Table } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import usePagination from 'hooks/usePagination';
import {
  getKillchainExecutionsService,
  getKillchainResultReport
} from 'services/killchainService';
import classNames from 'classnames';
import useError from 'hooks/useError';

export default function KillchainReports() {
  const { getResponse } = useError();
  const { id } = useParams();
  const [reports, setReports] = useState([]);
  const [isFetching, setFetching] = useState(false);

  useEffect(() => {
    getAllReports(1);
  }, []);

  const getAllReports = useCallback(async page => {
    setFetching(true);
    const res = await getKillchainExecutionsService(id, page);
    getResponse(res)
      .then(res => {
        setReports(res);
        setFetching(false);
      })
      .catch(err => {
        setFetching(false);
      });
  }, []);

  const { Pagination } = usePagination(getAllReports);

  return (
    <React.Fragment>
      <div className="mb-4 font-sans-serif">
        <Flex>
          <h4 className="fw-bold">Killchain Reports</h4>
        </Flex>
      </div>
      <Card className="overflow-hidden my-4 font-sans-serif">
        <Card.Body className="p-0">
          <ReportTable data={reports?.data || []} isFetching={isFetching} />
        </Card.Body>
      </Card>
      {reports?.total_pages ? (
        <Pagination totalPages={reports.total_pages} />
      ) : null}
    </React.Fragment>
  );
}

const ReportTable = ({ data, isFetching }) => {
  if (!data) return null;
  return (
    <Table className="fs--1 mb-0">
      <thead className=" text-800 py-3">
        <tr>
          <th className="pt-4 pb-3">Name</th>
          <th className="pt-4 pb-3">Created At</th>
          <th className=" pt-4 pb-3 text-end">Report</th>
          {/* <th className=" pt-4 pb-3 text-end">Technical Report</th> */}
        </tr>
      </thead>
      <tbody>
        <RenderTableRows data={data} isFetching={isFetching} />
      </tbody>
    </Table>
  );
};

const TableRow = ({ item, isLast }) => {
  const { id } = useParams();
  const { getResponse } = useError();
  const [downloading, setDownloading] = useState(false);

  const handleDonwloadClick = async e => {
    e.stopPropagation();
    setDownloading(true);
    const res = await getKillchainResultReport(id, item.id);
    setDownloading(false);
    getResponse(res)
      .then(res => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${item.id}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch(err => {});
  };

  return (
    <tr>
      <td
        className={classNames('align-middle', {
          'border-bottom-0': isLast
        })}
      >
        {item?.id}
      </td>
      <td
        className={classNames('align-middle', {
          'border-bottom-0': isLast
        })}
      >
        {getDateTimeByString(item?.createdAt)}
      </td>

      <td
        className={classNames('align-middle text-end', {
          'border-bottom-0': isLast
        })}
      >
        <Button
          variant="primary"
          size="sm"
          className="d-inline-flex flex-center gap-1 me-2"
          onClick={handleDonwloadClick}
          disabled={downloading}
        >
          {downloading ? 'Downloading...' : 'Download'}
        </Button>
      </td>
      {/* <td
        className={classNames('align-middle text-end', {
          'border-bottom-0': isLast
        })}
      >
        <Button
          variant="primary"
          size="sm"
          className="d-inline-flex flex-center gap-1 me-2"
          onClick={handleDonwloadClick}
          disabled={downloading}
        >
          {downloading ? 'Downloading...' : 'Download'}
          Download
        </Button>
      </td> */}
    </tr>
  );
};

const RenderTableRows = ({ data, isFetching }) => {
  if (isFetching) {
    return (
      <tr>
        <td className="px-0 py-1" colSpan={7}>
          <TableLoader />
        </td>
      </tr>
    );
  }
  if (data.length == 0) {
    return (
      <tr>
        <td colSpan="7" className="text-center text-muted fs-medium fs-0">
          No results found
        </td>
      </tr>
    );
  }

  return data.map((item, index) => (
    <TableRow key={item.id} item={item} isLast={data.length - 1 === index} />
  ));
};
