import { createContext } from 'react';
import { settings } from 'config';

const AppContext = createContext(settings);
export const AuthContext = createContext({});
export const SelectContext = createContext({ selectedTasks: [] });
export const AddStepContext = createContext({});
export const EditStepContext = createContext({});
export const TemplateContext = createContext({});
export const AgentContext = createContext({});
export const StepContext = createContext({});
export const TechniqueContext = createContext({});
export const ProjectContext = createContext({});
export const ExecutionContext = createContext({});
export const NotificationContext = createContext({});
export const TestContext = createContext({});
export const IntegrationContext = createContext({});
export default AppContext;
