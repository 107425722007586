import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import SimpleBarReact from 'simplebar-react';

function ControlledImageSlider({ initialIndex, data }) {
  const [index, setIndex] = useState(initialIndex);

  const handleSelect = selectedIndex => {
    setIndex(selectedIndex);
  };

  if (data?.length === 0) {
    return (
      <div className="h-100 d-flex align-items-center justify-content-center">
        No Available.
      </div>
    );
  }

  return (
    <>
      <Carousel
        variant="dark"
        data-bs-theme="dark"
        activeIndex={index}
        onSelect={handleSelect}
      >
        {data.map((image, dataIndex) => (
          <Carousel.Item>
            <SimpleBarReact
              style={{ height: '100%', width: '100%' }}
              forceVisible={true}
              autoHide={false}
            >
              {dataIndex === index && image ? (
                <img src={image} alt="" />
              ) : (
                <div className="text-center">No Available.</div>
              )}
            </SimpleBarReact>
          </Carousel.Item>
        ))}
      </Carousel>
    </>
  );
}

export default ControlledImageSlider;
