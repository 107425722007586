import React, { useContext, useState } from 'react';
import AddEditStep from './AddEditStep';
import { EditStepContext, StepContext } from 'context/Context';
import { useEffect } from 'react';

export default function EditStep() {
  const {
    editStepState: { showModal },
    toggleModal
  } = useContext(EditStepContext);
  return (
    <React.Fragment>
      <AddEditStep
        title="Change Scenario"
        show={showModal}
        handleClose={toggleModal}
      />
    </React.Fragment>
  );
}
