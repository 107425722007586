import { AgentContext } from 'context/Context';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  getKillChainAgentsService,
  removeAgentService
} from 'services/killchainService';
import useError from './useError';

export default function useKillchainAgent(id) {
  const { getResponse } = useError();
  const [isFetching, setFetching] = useState(false); // initial fetching state = false
  const { agentState, addSelectedAgents } = useContext(AgentContext);

  useEffect(() => {
    if (agentState.selectedAgents.length === 0) {
      getAllAgents(1);
    }
  }, []);

  const getAllAgents = useCallback(async page => {
    setFetching(true);
    const res = await getKillChainAgentsService(id, page);
    setFetching(false);
    getResponse(res)
      .then(res => {
        addSelectedAgents(res);
      })
      .catch(err => console.error(err));
  }, []);

  const deleteAgent = async agentId => {
    const res = await removeAgentService(id, [agentId]);
    await getResponse(res)
      .then(res => {
        const remainingAgents = agentState.selectedAgents.data.filter(
          d => d.agentId !== agentId
        );
        addSelectedAgents({
          ...agentState.selectedAgents,
          data: remainingAgents
        });
      })
      .catch(err => console.error(err));
  };

  return {
    selectedAgents: agentState.selectedAgents,
    isFetching,
    refreshKillchainAgents: getAllAgents,
    deleteAgent
  };
}
