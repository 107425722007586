import React from 'react';
import SelectedAssets from './flow/SelectedAssets';
import AddStepProvider from 'providers/AddStepProvider';
import EditStepProvider from 'providers/EditStepProvider';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import { Button, Col, Row } from 'react-bootstrap';
import AssessmentSetupKillChain from './flow/AssessmentSetupKillChain';
import AssessmentSetupTest from './flow/AssessmentSetupTest';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  getKillChainByIdService,
  runKillchainService,
  updateKillChainService
} from 'services/killchainService';
import { toast } from 'react-toastify';
import { useState } from 'react';
import { useEffect } from 'react';
// import { handleError } from 'helpers/utils';
import { useContext } from 'react';
import { AgentContext, StepContext, TestContext } from 'context/Context';
import { useLayoutEffect } from 'react';
import ConfigFormModal from './flow/ConfigForm';
import Scheduler from './flow/Scheduler';
import KillchainEditForm from './killchain/setup/KillchainEditForm';
import useError from 'hooks/useError';
import { capitalize } from 'helpers/utils';

export default function Flow() {
  const { getResponse } = useError();
  const {
    stepState,
    addNodesToStep,
    addEdgesToStep,
    updateKillchain,
    resetStep,
    updateKillchainFetchingStatus
  } = useContext(StepContext);
  const { testState, addTest, resetTest } = useContext(TestContext);
  const { agentState, resetSelectedAgent } = useContext(AgentContext);

  const [shortDetails, setDetails] = useState({
    name: '',
    description: '',
    type: null
  });
  const [isEditMode, setEditMode] = useState(false);
  const [scheduleTime, onScheduleTime] = useState(new Date());
  const [isScheduler, setScheuler] = useState(false);
  const [scheduleRepeatMode, setScheduleRepeatMode] = useState(null);

  const navigate = useNavigate();
  const { id } = useParams();

  useLayoutEffect(() => {
    updateKillchain(id);
  }, []);
  useEffect(() => {
    getKillChain();
    return () => {
      resetStep();
      resetTest();
      resetSelectedAgent();
    };
  }, []);

  const getKillChain = async () => {
    updateKillchainFetchingStatus(true);
    const res = await getKillChainByIdService(id);
    updateKillchainFetchingStatus(false);
    getResponse(res)
      .then(res => {
        setDetails({
          name: res.name,
          description: res.description,
          type: res.type
        });
        if (res.type === 'killchain') {
          handleSteps(res.Steps);
        } else {
          handleTests(res.Steps);
        }
      })
      .catch(err => console.error(err));
  };

  const handleTests = steps => {
    addTest(
      steps.map(step => {
        const { technique, edges, node, scenario, ...rest } = step;
        return { ...rest, stepId: step.id };
      })
    );
  };

  const handleSteps = steps => {
    const nodes = [];
    const edges = [];
    steps.forEach(step => {
      if (step.edges) {
        step.edges.forEach(ed => {
          edges.push(ed);
        });
      }
      let advanceConfig = {};
      step?.supported_platforms?.forEach(platform => {
        let parser = step?.[platform]?.[0]?.commands?.[0]?.parser;
        if (parser) {
          advanceConfig = {
            ...advanceConfig,
            parserOptions: {
              ...advanceConfig.parserOptions,
              [platform]: parser
                ?.filter(pArr => pArr?.name)
                ?.map(p => ({
                  label: p.name,
                  value: p.key
                }))
            }
          };
        }
      });

      nodes.push({
        ...step.node,
        type: 'custom',
        data: {
          number: nodes.length + 1,
          name:
            step.name ||
            step?.techniqueName ||
            step?.Technique?.name ||
            step?.Technique?.techniqueName,
          description:
            step.description ||
            step?.techniqueDescription ||
            step?.Technique?.description ||
            step?.Technique?.techniqueDescription,
          techniqueId: step.techniqueId,
          config: step.formSchema?.formSchema ?? null,
          variables: step.variables,
          parserKey: step.parserKey, // should come as {windows: value, debian: value, ...}
          filterOutputTo: step.filterOutputTo, // should come as [values as stepId]
          filterOutputFileName: step?.filterOutputFileName,
          action: 'play',
          detailStatus: 'noaction',
          status: 'noaction',
          stepId: step.id,
          index: step.index,
          tags:
            step?.Taggable?.map(each => each?.tag).map(tag => tag?.name) || [],
          successStepId: step.successStepId,
          failureStepId: step.failureStepId,
          ...advanceConfig
        }
      });
      if (nodes.length == 1) {
        nodes[0].sourcePosition = 'right';
      }
    });
    addNodesToStep(nodes);
    addEdgesToStep(edges);
  };

  const handleRunKillchain = async () => {
    if (
      (shortDetails.type === 'killchain' && stepState.nodes.length == 0) ||
      (shortDetails.type === 'test' && testState.tests.length == 0)
    ) {
      return toast(
        <span className="text-info">Please add atleast one step.</span>
      );
    }
    if (
      !agentState.selectedAgents?.data ||
      (agentState.selectedAgents?.data &&
        agentState.selectedAgents.data.length == 0)
    ) {
      return toast(
        <span className="text-info">Please select atleast one asset.</span>
      );
    }
    const res = await runKillchainService(id);
    getResponse(res)
      .then(res => {
        toast(
          <span className="text-success">Killchain started successfully.</span>
        );
        navigate(`/assessments/${id}/results`);
      })
      .catch(err => console.error(err));
  };

  const handleChange = e => {
    const { id, value } = e.target;
    setDetails({ ...shortDetails, [id]: value });
  };

  const handleKillchainUpdate = async () => {
    const res = await updateKillChainService(id, {
      name: shortDetails.name,
      description: shortDetails.description
    });
    getResponse(res)
      .then(res => {
        toast(
          <span className="text-success">Killchain updated successfully.</span>
        );
        setEditMode(false);
      })
      .catch(err => {
        setEditMode(false);
      });
  };

  return (
    <>
      <AddStepProvider>
        <EditStepProvider>
          {isEditMode ? (
            <KillchainEditForm
              onChange={handleChange}
              name={shortDetails.name}
              description={shortDetails.description}
              onSubmit={handleKillchainUpdate}
            />
          ) : (
            <div className="mb-4">
              <Flex>
                <h4>{shortDetails.name}</h4>
                <IconButton
                  className="ms-auto"
                  variant="falcon-default"
                  icon="edit"
                  iconAlign="right"
                  transform="shrink-3"
                  onClick={() => setEditMode(true)}
                >
                  {/* More Options{' '} */}
                </IconButton>
              </Flex>
              <div className="mt-3">
                <div className="fs--1 fw-medium font-sans-serif">
                  {shortDetails.description}
                </div>
              </div>
            </div>
          )}
          <RenderAssessmentTypeSetupView type={shortDetails.type} />
          <SelectedAssets id={id} />
          <Row>
            <Col md={6}>
              <Scheduler
                onToggle={() => setScheuler(!isScheduler)}
                isScheduled={isScheduler}
                onDateTimeChange={onScheduleTime}
                dateTimeValue={scheduleTime}
                repeatMode={scheduleRepeatMode}
                handleRepeatModeChange={setScheduleRepeatMode}
              />
            </Col>
            <Col md={6}>
              <Flex alignItems={'end'}>
                <div className="ms-auto">
                  <Button
                    variant="primary"
                    className="mb-1"
                    onClick={handleRunKillchain}
                  >
                    Run
                  </Button>
                </div>
              </Flex>
            </Col>
          </Row>

          <ConfigFormModal />
        </EditStepProvider>
      </AddStepProvider>
    </>
  );
}

const RenderAssessmentTypeSetupView = ({ type }) => {
  if (type == 'killchain') {
    return <AssessmentSetupKillChain />;
  }
  if (type == 'test') {
    return <AssessmentSetupTest />;
  }
  return null;
};
