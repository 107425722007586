import React, { useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import CardHeader from 'components/common/CardHeader';
import { toast } from 'react-toastify';
import { updateUserProfileService } from 'services/userService';
import useError from 'hooks/useError';

const ProfileSettings = ({ userData }) => {
  const { getResponse } = useError();
  const [updating, setUpdating] = useState(false);
  const [formData, setFormData] = useState({
    firstName: userData.firstName,
    lastName: userData.lastName,
    email: userData.email
  });

  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setUpdating(true);
    const { email, ...profileData } = formData;
    const res = await updateUserProfileService(profileData);
    getResponse(res)
      .then(res => {
        toast(
          <span className="text-success">
            User profile successfully updated.
          </span>
        );
        setUpdating(false);
      })
      .catch(err => {
        setUpdating(false);
      });
  };

  return (
    <Card>
      <CardHeader title="Profile Settings" />
      <Card.Body className="bg-light">
        <Form onSubmit={handleSubmit}>
          <Row className="mb-3 g-3">
            <Form.Group as={Col} lg={6} controlId="firstName">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="First Name"
                value={formData.firstName}
                name="firstName"
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group as={Col} lg={6} controlId="lastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Last Name"
                value={formData.lastName}
                name="lastName"
                onChange={handleChange}
              />
            </Form.Group>
          </Row>

          <Form.Group className="mb-3" controlId="heading">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Email"
              value={formData.email}
              name="email"
              onChange={handleChange}
              className="cursor-not-allowed"
              disabled
            />
          </Form.Group>

          <div className="text-end">
            <Button disabled={updating} variant="primary" type="submit">
              {updating ? 'Updating...' : 'Update'}
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default ProfileSettings;
