import React from 'react';
import Flex from './Flex';

export default function OverviewCard({
  titleImgUrl,
  title,
  label,
  value,
  labelValueGraphUrl,
  bgColor
}) {
  return (
    <div
      className={`p-3 m-2 rounded-3 w-100 flex-fill`}
      style={{ backgroundColor: bgColor }}
    >
      <Flex className={'mb-3'}>
        <img height={20} src={titleImgUrl} />
        <div className="ms-2 text-black fs--1 fw-semi-bold">{title}</div>
      </Flex>
      <Flex className={'justify-content-between'}>
        <div>
          {/* <div className="fs--2">{label}</div> */}
          <div className="text-black  fw-semi-bold">{value}</div>
        </div>
        <div className="d-flex align-items-center">
          <img height={24} src={labelValueGraphUrl} />
        </div>
      </Flex>
    </div>
  );
}
