import DateTimeComponent from 'components/common/DateTimeComponent';
import Flex from 'components/common/Flex';
import SelectBox from 'components/common/SelectBox';
import React, { Fragment } from 'react';
import { Col, Row } from 'react-bootstrap';
import Switch from 'react-switch';

const options = [
  { label: 'Select', value: '' },
  { label: 'Daily', value: 'daily' },
  { label: 'Weekly', value: 'weekly' },
  { label: 'Monthly', value: 'monthly' },
  { label: 'Hourly', value: 'hourly' }
];

export default function Scheduler({
  isScheduled,
  onDateTimeChange,
  dateTimeValue,
  onToggle,
  repeatMode,
  handleRepeatModeChange
}) {
  return (
    <div
      style={{
        padding: '1rem',
        backgroundColor: '#fff'
      }}
    >
      <Flex alignItems={'center'}>
        <div>
          <h5 className="mb-0 text-black">Schedule</h5>
        </div>
        {/* <div className="mx-4"> */}
        <Switch
          className="react-switch mx-4 ms-auto"
          checked={isScheduled}
          onChange={onToggle}
          uncheckedIcon={false}
          checkedIcon={false}
          onColor="#A4FFD6"
          onHandleColor="#2EB578"
          offHandleColor="#E52121AB"
          offColor="#FFA9A9"
          height={17}
          width={34}
        />
      </Flex>
      <Flex className={'mt-2'}>
        {/* </div> */}
        <div>
          {isScheduled == true ? (
            <Flex>
              <DateTimeComponent
                onChange={onDateTimeChange}
                value={dateTimeValue}
              />
              <div className="mx-2 custom-rounded-shadow">
                <SelectBox
                  options={options}
                  optionClass={'fs--1'}
                  selectClass={'fs--1'}
                  titleClass={'fs--2'}
                  value={repeatMode}
                  onChange={handleRepeatModeChange}
                  key={repeatMode}
                  menuPlacement={'top'}
                  style={{ minWidth: 'unset' }}
                />
              </div>
            </Flex>
          ) : null}
        </div>
      </Flex>
    </div>
  );
}
