import React, { useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import AppContext from 'context/Context';
import { settings } from './config';
import { getColor, getItemFromStore } from 'helpers/utils';
import { configReducer } from './reducers/configReducer';
import useToggleStyle from './hooks/useToggleStyle';
import TemplateProvider from 'providers/TemplateProvider';
import AgentProvider from 'providers/AgentProvider';
import AuthProvider from 'providers/AuthProvider';
import StepProvider from 'providers/StepProvider';
import AddStepProvider from 'providers/AddStepProvider';
import EditStepProvider from 'providers/EditStepProvider';
import TechniqueProvider from 'providers/TechniqueProvider';
import ProjectProvider from 'providers/ProjectProvider';
import Spinner from 'components/common/spinner/Spinner';
import ExecutionProvider from 'providers/ExecutionProvider';
import NotificationProvider from 'providers/NotificationProvider';
import TestProvider from 'providers/TestProvider';
import IntegrationProvider from 'providers/IntegrationProvider';

// import { Chart as ChartJS, registerables } from 'chart.js';
// ChartJS.register(...registerables);

const Main = props => {
  const [verifying, setVerifying] = useState(false);
  const configState = {
    isFluid: getItemFromStore('isFluid', settings.isFluid),
    isRTL: getItemFromStore('isRTL', settings.isRTL),
    isDark: getItemFromStore('isDark', settings.isDark),
    navbarPosition: getItemFromStore('navbarPosition', settings.navbarPosition),
    disabledNavbarPosition: [],
    isNavbarVerticalCollapsed: getItemFromStore(
      'isNavbarVerticalCollapsed',
      settings.isNavbarVerticalCollapsed
    ),
    navbarStyle: getItemFromStore('navbarStyle', settings.navbarStyle),
    currency: settings.currency,
    showBurgerMenu: settings.showBurgerMenu,
    showSettingPanel: false,
    navbarCollapsed: false
  };

  const [config, configDispatch] = useReducer(configReducer, configState);

  const { isLoaded } = useToggleStyle(
    config.isRTL,
    config.isDark,
    configDispatch
  );

  const setConfig = (key, value) => {
    configDispatch({
      type: 'SET_CONFIG',
      payload: {
        key,
        value,
        setInStore: [
          'isFluid',
          'isRTL',
          'isDark',
          'navbarPosition',
          'isNavbarVerticalCollapsed',
          'navbarStyle'
        ].includes(key)
      }
    });
  };

  // const verifyUser = () => {
  //   setVerifying(true);
  //   new Promise((res, rej) => {
  //     setTimeout(() => res(false), 5000);
  //   }).then(res => setVerifying(false));
  // };

  // useEffect(() => {
  //   verifyUser();
  // }, []);

  if (!isLoaded) {
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: config.isDark ? getColor('dark') : getColor('light')
        }}
      >
        <div
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex'
          }}
        >
          <Spinner />
        </div>
      </div>
    );
  }

  return (
    <AppContext.Provider value={{ config, setConfig }}>
      <AuthProvider>
        <NotificationProvider>
          <ProjectProvider>
            <IntegrationProvider>
              <AgentProvider>
                <TemplateProvider>
                  <TestProvider>
                    <StepProvider>
                      <AddStepProvider>
                        <EditStepProvider>
                          <TechniqueProvider>
                            <ExecutionProvider>
                              {props.children}
                            </ExecutionProvider>
                          </TechniqueProvider>
                        </EditStepProvider>
                      </AddStepProvider>
                    </StepProvider>
                  </TestProvider>
                </TemplateProvider>
              </AgentProvider>
            </IntegrationProvider>
          </ProjectProvider>
        </NotificationProvider>
      </AuthProvider>
    </AppContext.Provider>
  );
};

Main.propTypes = { children: PropTypes.node };

export default Main;
