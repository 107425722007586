import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from 'context/Context';
import { useEffect } from 'react';
import { authChangePasswordService } from 'services/authService';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Form } from 'react-bootstrap';
import useError from 'hooks/useError';

const schema = Yup.object().shape({
  password: Yup.string().required('Email is required')
});

const AuthChangePassword = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const { getResponse } = useError();

  const { onLoginSuccess } = useContext(AuthContext);
  let navigate = useNavigate();

  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({ password: '' });
  const [isFetching, setFetching] = useState(false);

  useEffect(() => {
    if (!token) return navigate('/');
  }, []);

  useEffect(() => {
    const savedAuthStateString = localStorage.getItem('authState');
    const savedAuthState = JSON.parse(savedAuthStateString);
    if (
      savedAuthState?.isLogedIn &&
      savedAuthState.isLogedIn == true &&
      savedAuthState?.userRole
    ) {
      onLoginSuccess({ ...savedAuthState });
      if (savedAuthState?.userRole == 'ADMIN_DEVELOPER') {
        navigate('/workbench');
      } else {
        navigate('/dashboard');
      }
    }
  }, []);

  const handleLogin = async e => {
    e.preventDefault();
    const formData = { password };
    const isFormValid = await schema.isValid(formData, {
      abortEarly: false // Prevent aborting validation after first error
    });
    if (!isFormValid) {
      schema.validate(formData, { abortEarly: false }).catch(error => {
        // Collect all errors in { fieldName: boolean } format:
        const validationErrors = {};
        error.inner.forEach(err => {
          validationErrors[err.path] = err.message;
        });
        setErrors(validationErrors);
      });
      return;
    }
    setFetching(true);
    const res = await authChangePasswordService(token, password);
    getResponse(res)
      .then(res => {
        toast(
          <span className="text-success">Password changed successfully.</span>
        );
        navigate('/');
      })
      .catch(err => console.error(err));
    setFetching(false);
  };

  return (
    <div>
      <Form onSubmit={handleLogin}>
        <label>Password</label>
        <input
          type="password"
          className="form-control"
          value={password}
          onChange={e => setPassword(e.target.value)}
          placeholder="Enter new password"
        />
        <div>
          {errors.password && (
            <span className="text-danger fs--2 fw-medium">
              {errors.password}
            </span>
          )}
        </div>
        {/* <hr /> */}
        <button
          type="submit"
          className="btn btn-primary mt-3"
          // onClick={handleLogin}
          disabled={isFetching}
        >
          {!isFetching ? 'Reset' : 'Reseting...'}
        </button>
      </Form>
    </div>
  );
};

export default AuthChangePassword;
