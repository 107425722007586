import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import { IntegrationContext } from 'context/Context';
import { integrationReducer } from 'reducers/integrationReducer';

const IntegrationProvider = ({ children }) => {
  const initData = {
    all: [],
    allByOrg: []
  };
  const [integrationState, dispatch] = useReducer(integrationReducer, initData);

  const saveAllIntegrations = data => {
    dispatch({
      type: 'SAVE_ALL',
      payload: data
    });
  };
  const saveAllIntegrationsByOrg = data => {
    dispatch({
      type: 'SAVE_ALL_ORG',
      payload: data
    });
  };
  return (
    <IntegrationContext.Provider
      value={{
        integrationState,
        saveAllIntegrations,
        saveAllIntegrationsByOrg
      }}
    >
      {children}
    </IntegrationContext.Provider>
  );
};

IntegrationProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default IntegrationProvider;
