import React, { memo } from 'react';
import SimpleBarReact from 'simplebar-react';
import { Table, Button } from 'react-bootstrap';
import classNames from 'classnames';
import { MdCancel } from 'react-icons/md';
import {
  getDateInFormatOfDMY,
  getAgentStatusByRefactor,
  getPlatformByExtension,
  shouldShowDownload
} from 'helpers/utils';
import Flex from 'components/common/Flex';
import { getAgentByIdService } from 'services/agentService';
import { useState } from 'react';
import TableLoader from 'components/common/TableLoader';
import TagView from 'components/common/TagView';
import useError from 'hooks/useError';

const STATUS_COLOR = {
  ready: 'info',
  deployed: 'success',
  dead: 'danger',
  inactive: 'warning',
  building: 'grey',
  undefined: 'grey'
};

let STATUS_LABEL = {
  building: 'NA',
  ready: 'Ready',
  deployed: 'Alive',
  dead: 'Dead',
  inactive: 'Inactive',
  undefined: 'NA' //handling for status otherthan this
};

let AGENT_STATUS = {
  building: 'Generating...',
  ready: 'NA',
  deployed: 'Deployed'
};

const AssetsTableRow = memo(({ item, isLast, onRowClick }) => {
  const { getResponse } = useError();

  const [downloading, setDownloading] = useState(false);

  const handleRowClick = () => {
    onRowClick(item.id);
  };

  const handleDonwloadClick = async e => {
    e.stopPropagation();
    setDownloading(true);
    const res = await getAgentByIdService(item.id);
    getResponse(res)
      .then(res => {
        // Generate the download link
        const downloadLink = document.createElement('a');
        downloadLink.href = res.url;
        let extension = res.url.split('.').pop().split('?')[0];
        let platform = getPlatformByExtension(extension);
        downloadLink.download = 'Agent_' + platform + '.' + extension;

        // Simulate a click on the download link
        downloadLink.click();
      })
      .catch(err => console.error(err));
    setDownloading(false);
  };

  let status = getAgentStatusByRefactor(item.status, item.lastSeen);
  let statusLabel = STATUS_LABEL[status];
  let statusColor = STATUS_COLOR[status];
  let showDownload = shouldShowDownload(item.status, item.url);

  return (
    <tr onClick={handleRowClick} className="hover-pointer hover-bg-200">
      <td
        className={classNames(
          'align-middle font-sans-serif fw-medium text-nowrap',
          {
            'border-bottom-0': isLast
          }
        )}
      >
        <div className="d-flex">
          <span className="ms-1">{item.id}</span>
        </div>
      </td>
      <td
        className={classNames('align-middle ', {
          'border-bottom-0': isLast
        })}
      >
        {item?.name}
      </td>
      <td
        className={classNames('align-middle ', {
          'border-bottom-0': isLast
        })}
      >
        {item?.meta?.computerName}
      </td>
      <td
        className={classNames('align-middle', {
          'border-bottom-0': isLast
        })}
      >
        {item?.platform}
      </td>
      <td
        className={classNames('align-middle', {
          'border-bottom-0': isLast
        })}
      >
        {item?.pid}
      </td>
      <td
        className={classNames('align-middle ', {
          'border-bottom-0': isLast
        })}
      >
        {item?.privilege}
      </td>
      <td
        className={classNames(
          'align-middle  fw-medium',
          `text-${statusColor}`,
          {
            'border-bottom-0': isLast
          }
        )}
      >
        <Flex className={'align-items-center'}>
          <div
            style={{ width: 12, height: 12 }}
            className={classNames(`rounded-circle me-2 bg-${statusColor}`)}
          ></div>
          {statusLabel}
        </Flex>
      </td>
      <td className="text-nowrap ">
        <TagView
          data={
            item?.Taggable?.map(each => each?.tag).map(tag => tag?.name) || []
          }
          maxTagShow={3}
          emptyComponent={() => <span>No tags</span>}
        />
      </td>
      <td
        className={classNames('align-middle ', {
          'border-bottom-0': isLast
        })}
      >
        {item.lastSeen && getDateInFormatOfDMY(item.lastSeen)}
      </td>
      <td
        className={classNames('align-middle ', {
          'border-bottom-0': isLast
        })}
      >
        {!showDownload ? (
          <span>{AGENT_STATUS[item.status]}</span>
        ) : (
          <Button
            variant="primary"
            size="sm"
            className="d-inline-flex flex-center gap-1 me-2"
            onClick={handleDonwloadClick}
            disabled={downloading}
          >
            {downloading ? 'Downloading...' : 'Download'}
          </Button>
        )}
      </td>
      <td
        className={classNames('align-middle text-end', {
          'border-bottom-0': isLast
        })}
      >
        <MdCancel size={20} className="position-relative z-10 hover-pointer" />
      </td>
    </tr>
  );
});
const AssetsTable = ({ data, onRowClick, isLoading }) => {
  return (
    <SimpleBarReact>
      <Table className="fs--1 mb-0">
        <thead className="project_table_header text-800 py-3">
          <tr>
            <th className="text-nowrap pt-4 pb-3">ID(paw)</th>
            <th className="text-nowrap pt-4 pb-3 ">Name</th>
            <th className="text-nowrap pt-4 pb-3 ">Computer Name</th>
            <th className="text-nowrap pt-4 pb-3 ">Platform</th>
            <th className="text-nowrap pt-4 pb-3 ">PID</th>
            <th className="text-nowrap pt-4 pb-3 ">Privilege</th>
            <th className="text-nowrap pt-4 pb-3 ">Status</th>
            <th className="text-nowrap pt-4 pb-3 ">Tags</th>
            <th className="text-nowrap pt-4 pb-3 ">Last seen</th>
            <th className="text-nowrap pt-4 pb-3"></th>
            <th className="text-nowrap pt-4 pb-3 text-end"></th>
          </tr>
        </thead>
        <tbody>
          <RenderTableRows
            isFetching={isLoading}
            assets={data}
            onRowClick={onRowClick}
          />
        </tbody>
      </Table>
    </SimpleBarReact>
  );
};

export default memo(AssetsTable);

const RenderTableRows = ({ assets, isFetching, onRowClick }) => {
  if (isFetching) {
    return (
      <tr>
        <td className="px-0 py-1" colSpan={10}>
          <TableLoader />
        </td>
      </tr>
    );
  }
  if (assets.length == 0) {
    return (
      <tr>
        <td colSpan="7" className="text-center text-muted fs-medium fs-0">
          No results found
        </td>
      </tr>
    );
  }
  return assets.map((item, index) => (
    <AssetsTableRow
      key={item.id}
      item={item}
      isLast={assets.length - 1 === index}
      onRowClick={onRowClick}
    />
  ));
};
