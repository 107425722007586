import React, { useContext, useEffect } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import AppContext from 'context/Context';
import classNames from 'classnames';
import NavbarTop from 'components/navbar/top/NavbarTop';
import NavbarVertical from 'components/navbar/vertical/NavbarVertical';
import { assessmentRoutes } from 'routes/siteMaps';
import NavbarVerticalMenu from 'components/navbar/vertical/NavbarVerticalMenu';
import { Nav } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { useState } from 'react';
import { useLayoutEffect } from 'react';

const MainLayout = () => {
  const { id } = useParams();
  const { hash, pathname } = useLocation();
  const [assessmentRoutesState, setARS] = useState([]);

  const {
    config: { isFluid, navbarPosition }
  } = useContext(AppContext);

  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }
    }, 0);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useLayoutEffect(() => {
    // let assessmentId = pathname.split('/')[2];

    setARS(
      assessmentRoutes.map(route => {
        return {
          ...route,
          to: `/assessments/${id}${route.to}`
        };
      })
    );
  }, []);

  return (
    <div
      className={'container-fluid overflow-hidden'}
      style={{ height: '100vh' }}
    >
      {(navbarPosition === 'vertical' || navbarPosition === 'combo') && (
        <NavbarVertical isNavbarVerticalCollapsed={true} />
      )}
      <div className={classNames('content', 'content-body-container', 'pb-0')}>
        <NavbarTop />
        {/*------ Main Routes ------*/}
        <div className="mx-n3 assessment_content">
          <Flex>
            <AssessmentNavigation routes={assessmentRoutesState} />
            <div className="w-100 px-3 py-4 bg-soft-secondary scrollbar assessment_content">
              <Outlet />
            </div>
          </Flex>
        </div>
      </div>
    </div>
  );
};

export default MainLayout;

const AssessmentNavigation = ({ routes }) => {
  return (
    <div className="assessment_navbar-container">
      <div className="assessment_navbar bg-white h-100">
        <div className="assessment_navbar_content">
          <Nav className="flex-column" as="ul">
            <NavbarVerticalMenu routes={routes} />
          </Nav>
        </div>
        <div className="parent-shadow-left position-absolute"></div>
        <div className="parent-shadow-top position-absolute"></div>
      </div>
    </div>
  );
};
