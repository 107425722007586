import classNames from 'classnames';
import Flex from 'components/common/Flex';
import SearchBox from 'components/navbar/top/SearchBox';
import { ExecutionContext } from 'context/Context';
import { capitalize, getDateTimeByString } from 'helpers/utils';
import React, { useEffect, useRef, useState } from 'react';
import { useContext } from 'react';
import { Accordion, Col, Form, Row } from 'react-bootstrap';
import { BiListCheck, BiRadar, BiListUl } from 'react-icons/bi';
import { BsFillTagFill } from 'react-icons/bs';
import { HiOutlineLightBulb } from 'react-icons/hi';
import RenderOutput from './RenderOutput';
import { getKillchainResultReport } from 'services/killchainService';
import useError from 'hooks/useError';
import ScreenshotView from './ScreenshotView';

export const STATUS_COLOR = {
  success: 'success',
  pending: 'info',
  failed: 'danger'
};

export default function PhaseComponent({ selectedAgent, setAgent }) {
  const { getResponse } = useError();
  const [downloading, setDownloading] = useState(false);
  // const [electedAgent, setElectedAgent] = useState(selectedAgent.id);
  const blurTimeoutRef = useRef(null);
  const [search, setSearch] = useState(selectedAgent?.name || '');
  const {
    executionState: { executionResult }
  } = useContext(ExecutionContext);
  const [phaseSteps, setPhaseSteps] = useState([]);

  const [filteredData, setFilteredData] = useState(
    executionResult?.agents || []
  );
  const [showFilteredData, setShowFilteredData] = useState(false);
  const [screenshots, setScreenshots] = useState([]);
  const [selectedOutput, setSelectedOutput] = useState(null);

  const handleSelectedOutput = val => setSelectedOutput(val);

  const handleInputChange = e => {
    const value = e.target.value;
    const filtered = executionResult?.agents?.filter(
      item =>
        item.name.toLowerCase().match(value.toLowerCase()) ||
        item.meta?.computerName.toLowerCase().match(value.toLowerCase())
    );
    setSearch(value);
    setFilteredData(filtered);
  };

  const handleSelectOption = agent => {
    setSearch(agent?.name);
    // setElectedAgent(id);
    setAgent(agent);
  };

  useEffect(() => {
    setFilteredData(executionResult?.agents);
  }, [executionResult?.agents]);

  useEffect(() => {
    setSearch(selectedAgent?.name || '');
    // setElectedAgent(selectedAgent);
  }, [selectedAgent]);

  useEffect(() => {
    if (executionResult) {
      const filteredPhaseSteps = executionResult.ExecutionSteps.filter(step => {
        if (!selectedAgent) {
          return true;
        }
        // if (electedAgent && step?.agentId == electedAgent) {
        if (selectedAgent && step?.agentId == selectedAgent?.id) {
          return true;
        }
        return false;
      });
      setPhaseSteps(filteredPhaseSteps);
      // id, afterSs or beforeSs
      setScreenshots(
        filteredPhaseSteps.map(({ id, output }) => {
          const newObj = { id, img: [] };
          if (output) {
            output.forEach(each => {
              if (each?.after_screenshot)
                newObj.img.push(
                    `/api/killchain/${executionResult?.killChainId}/executions/${executionResult?.id}/screenshot/` +
                    encodeURIComponent(
                      each?.after_screenshot
                    )
                );
              if (each?.before_screenshot)
                newObj.img.push(
                  `/api/killchain/${executionResult?.killChainId}/executions/${executionResult?.id}/screenshot/` +
                  encodeURIComponent(
                    each?.before_screenshot
                  )
              );
            });
          }
          return newObj;
        })
      );
    }
  }, [
    // electedAgent,
    selectedAgent,
    executionResult
  ]);

  const handleBlur = () => {
    setSearch(selectedAgent?.name || '');
    // Delay the hiding of the dropdown to allow time for the selection
    blurTimeoutRef.current = setTimeout(() => {
      setShowFilteredData(false);
    }, 200);
  };

  const handleFocus = () => {
    // Clear the blur timeout to prevent premature hiding of the dropdown
    clearTimeout(blurTimeoutRef.current);
    setShowFilteredData(true);
  };

  /**
   * Downloading the report for result
   */
  const handleDownloadReport = async () => {
    if (!executionResult?.killChainId || !executionResult?.id) {
      return;
    }
    setDownloading(true);
    const res = await getKillchainResultReport(
      executionResult?.killChainId,
      executionResult?.id
    );
    setDownloading(false);
    getResponse(res)
      .then(res => {
        const url = window.URL.createObjectURL(new Blob([res]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Report.pdf');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch(err => {});
  };

  const groups = [...new Set(phaseSteps.map(item => item.group))];
  return (
    <React.Fragment>
      <Row className="align-items-center">
        <Col>
          <div className="fs-0 fw-medium text-black">Phase</div>
        </Col>
        <Col xs={6}>
          <div className="d-flex">
            <Form.Control
              className="md-auto text-700"
              type="text"
              placeholder="filter by agent"
              value={search}
              onChange={handleInputChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
            {showFilteredData && filteredData.length > 0 && (
              <div className="dropdown">
                <ul className="dropdown-menu" style={{ display: 'block' }}>
                  {filteredData.map(item => (
                    <li
                      key={item.id}
                      className="dropdown-item"
                      onClick={() => handleSelectOption(item)}
                    >
                      {item.name}
                    </li>
                  ))}
                </ul>
              </div>
            )}
            <button
              onClick={handleDownloadReport}
              className="btn btn-primary btn-sm ms-3"
              disabled={downloading}
            >
              {downloading ? 'Downloading...' : 'Download Report'}
              <i className="fas fa-download ms-1"></i>
            </button>
          </div>
        </Col>
      </Row>
      <div className="py-2">
        <PhaseHeader />
        <Accordion defaultActiveKey="0" className={'bg-white'}>
          {/* TODO: change this to component */}
          {executionResult?.type === 'test' ? (
            groups.map(group => (
              <Accordion.Item eventKey={'group' + group} key={'group' + group}>
                <Accordion.Header>
                  <Flex
                    className={'text-900'}
                    style={{ fontSize: 15 }}
                    alignItems={'center'}
                  >
                    {group}
                  </Flex>
                </Accordion.Header>
                <Accordion.Body className="bg-200">
                  <Accordion defaultActiveKey="0" className={'bg-white'}>
                    {phaseSteps
                      ?.filter(t => t?.group === group)
                      ?.map(eachResult => (
                        <Accordion.Item
                          eventKey={eachResult.id}
                          key={eachResult.id}
                        >
                          <Accordion.Header>
                            <Row className="w-100 my-n1 text-900">
                              <Col className="">
                                <div className="d-flex align-items-center">
                                  <span className="ms-1 mt-1 fs--1 fw-medium">
                                    {eachResult?.data?.name ??
                                      eachResult?.data?.techniqueName}
                                  </span>
                                </div>
                              </Col>
                              {/* <Col className="">
                    <span className="ms-1 mt-1 fs--1 fw-medium">
                      {eachResult?.critialPhase}
                    </span>
                  </Col> */}
                              <Col className="d-flex align-items-center   fs--1 fw-medium">
                                <span
                                  className={classNames(
                                    `text-${STATUS_COLOR[eachResult.status]}`
                                  )}
                                >
                                  {capitalize(eachResult.status)}
                                </span>
                              </Col>
                              <Col className="d-flex align-items-center fs--1 fw-medium">
                                {/* show pills for detected or prevented */}
                                {eachResult?.meta?.integration?.find(
                                  row => row.type === 'prevented'
                                )?.type ? (
                                  <span className="badge rounded-pill text-bg-success">
                                    Prevented
                                  </span>
                                ) : (
                                  <span className="badge rounded-pill text-bg-danger">
                                    Not Prevented
                                  </span>
                                )}
                                <span className="mx-2">|</span>
                                {eachResult?.meta?.integration?.find(
                                  row => row.type === 'detected'
                                )?.type ? (
                                  <span className="badge rounded-pill text-bg-danger">
                                    Detected
                                  </span>
                                ) : (
                                  <span className="badge rounded-pill text-bg-success">
                                    Not Detected
                                  </span>
                                )}
                              </Col>
                              <Col className="d-flex align-items-center fs--1 fw-medium">
                                {getDateTimeByString(eachResult.createdAt)}
                              </Col>
                              <Col className="d-flex align-items-center fs--1 fw-medium">
                                {getDateTimeByString(eachResult.updatedAt)}
                              </Col>
                              <Col className="d-flex align-items-center fs--1 fw-medium">
                                {eachResult?.output &&
                                  eachResult.output?.length > 0 && (
                                    <button
                                      onClick={e => {
                                        e.stopPropagation();
                                        handleSelectedOutput(eachResult.id);
                                      }}
                                      className="btn btn-primary btn-sm ms-3"
                                    >
                                      View
                                    </button>
                                  )}
                              </Col>
                            </Row>
                          </Accordion.Header>
                          <Accordion.Body>
                            <div
                              className="px-2 py-3 text-black"
                              style={{ fontSize: 15 }}
                            >
                              <div className="fw-bold">Detail Findings: </div>
                              <div>User: </div>
                              <Accordion
                                defaultActiveKey="0"
                                className={'bg-white py-3'}
                              >
                                {/* TODO: change this to component */}
                                <Accordion.Item
                                  eventKey={'Activity'}
                                  key={'Activity'}
                                >
                                  <Accordion.Header>
                                    <Flex
                                      className={'text-900'}
                                      style={{ fontSize: 15 }}
                                      alignItems={'center'}
                                    >
                                      <BiListUl className="me-2" size={20} />
                                      Activity Details
                                    </Flex>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <pre className="text-900">
                                      {eachResult?.ApplicationLog}
                                    </pre>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item
                                  eventKey={'output'}
                                  key={'output'}
                                >
                                  <Accordion.Header>
                                    <Flex
                                      className={'text-900'}
                                      style={{ fontSize: 15 }}
                                      alignItems={'center'}
                                    >
                                      <BiListUl className="me-2" size={20} />
                                      Output
                                    </Flex>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    {eachResult?.output &&
                                      eachResult.output != null && (
                                        <RenderOutput
                                          data={eachResult.output}
                                        />
                                      )}
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item
                                  eventKey={'Mitigation'}
                                  key={'Mitigation'}
                                >
                                  <Accordion.Header>
                                    <Flex
                                      className={'text-900'}
                                      style={{ fontSize: 15 }}
                                      alignItems={'center'}
                                    >
                                      <HiOutlineLightBulb
                                        className="me-2"
                                        size={20}
                                      />{' '}
                                      Mitigation Recommendations
                                    </Flex>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    {
                                      eachResult?.data
                                        ?.mitigationRecommendations
                                    }
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item
                                  eventKey={'Detection'}
                                  key={'Detection'}
                                >
                                  <Accordion.Header>
                                    <Flex
                                      className={'text-900'}
                                      style={{ fontSize: 15 }}
                                      alignItems={'center'}
                                    >
                                      <BiRadar className="me-2" size={20} />
                                      Detection Details
                                    </Flex>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <table>
                                      <thead>
                                        <tr>
                                          <th>Observable</th>
                                          <th>Value</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {eachResult?.meta?.integration?.map(
                                          elem => {
                                            console.log(elem);
                                            return (
                                              <tr>
                                                <td>{elem?.integration}</td>
                                                <td>{elem?.type}</td>
                                              </tr>
                                            );
                                          }
                                        )}
                                      </tbody>
                                    </table>
                                  </Accordion.Body>
                                </Accordion.Item>
                                {/* <Accordion.Item
                                  eventKey={'Observable'}
                                  key={'Observable'}
                                >
                                  <Accordion.Header>
                                    <Flex
                                      className={'text-900'}
                                      style={{ fontSize: 15 }}
                                      alignItems={'center'}
                                    >
                                      <BiListCheck className="me-2" size={20} />
                                      Observable Details
                                    </Flex>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    
                                  </Accordion.Body>
                                </Accordion.Item> */}
                              </Accordion>
                              <div>
                                <Flex alignItems={'center'}>
                                  <BsFillTagFill className="me-2" size={20} />
                                  {/* <div className="rounded-pill border me-2  fs--2 px-2 border-2">
                        EDR/EPR
                      </div>
                      <div className="rounded-pill border fs--2 px-2 border-2">
                        Endpoint Antivirus
                      </div> */}
                                  {eachResult?.meta?.integration?.length >
                                    0 && (
                                    <div className="rounded-pill border me-2  fs--2 px-2 border-2">
                                      Splunk
                                    </div>
                                  )}
                                </Flex>
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      ))}
                  </Accordion>
                </Accordion.Body>
              </Accordion.Item>
            ))
          ) : (
            <Accordion defaultActiveKey="0" className={'bg-white'}>
              {phaseSteps?.map(eachResult => (
                <Accordion.Item eventKey={eachResult.id} key={eachResult.id}>
                  <Accordion.Header>
                    <Row className="w-100 my-n1 text-900">
                      <Col className="">
                        <div className="d-flex align-items-center">
                          <span className="ms-1 mt-1 fs--1 fw-medium">
                            {eachResult?.data?.name ??
                              eachResult?.data?.techniqueName}
                          </span>
                        </div>
                      </Col>
                      {/* <Col className="">
                    <span className="ms-1 mt-1 fs--1 fw-medium">
                      {eachResult?.critialPhase}
                    </span>
                  </Col> */}
                      <Col className="d-flex align-items-center   fs--1 fw-medium">
                        <span
                          className={classNames(
                            `text-${STATUS_COLOR[eachResult.status]}`
                          )}
                        >
                          {capitalize(eachResult.status)}
                        </span>
                      </Col>
                      <Col className="d-flex align-items-center fs--1 fw-medium">
                        {/* show pills for detected or prevented */}
                        {eachResult?.meta?.integration?.find(
                          row => row.type === 'prevented'
                        )?.type ? (
                          <span className="badge rounded-pill text-bg-success">
                            Prevented
                          </span>
                        ) : (
                          <span className="badge rounded-pill text-bg-danger">
                            Not Prevented
                          </span>
                        )}
                        <span className="mx-2">|</span>
                        {eachResult?.meta?.integration?.find(
                          row => row.type === 'detected'
                        )?.type ? (
                          <span className="badge rounded-pill text-bg-danger">
                            Detected
                          </span>
                        ) : (
                          <span className="badge rounded-pill text-bg-success">
                            Not Detected
                          </span>
                        )}
                      </Col>
                      <Col className="d-flex align-items-center fs--1 fw-medium">
                        {getDateTimeByString(eachResult.createdAt)}
                      </Col>
                      <Col className="d-flex align-items-center fs--1 fw-medium">
                        {getDateTimeByString(eachResult.updatedAt)}
                      </Col>
                      <Col className="d-flex align-items-center fs--1 fw-medium">
                        {eachResult?.output &&
                          eachResult.output?.length > 0 && (
                            <button
                              onClick={e => {
                                e.stopPropagation();
                                handleSelectedOutput(eachResult.id);
                              }}
                              className="btn btn-primary btn-sm ms-3"
                            >
                              View
                            </button>
                          )}
                      </Col>
                    </Row>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div
                      className="px-2 py-3 text-black"
                      style={{ fontSize: 15 }}
                    >
                      <div className="fw-bold">Detail Findings: </div>
                      <div>User: </div>
                      <Accordion
                        defaultActiveKey="0"
                        className={'bg-white py-3'}
                      >
                        {/* TODO: change this to component */}
                        <Accordion.Item eventKey={'Activity'} key={'Activity'}>
                          <Accordion.Header>
                            <Flex
                              className={'text-900'}
                              style={{ fontSize: 15 }}
                              alignItems={'center'}
                            >
                              <BiListUl className="me-2" size={20} />
                              Activity Details
                            </Flex>
                          </Accordion.Header>
                          <Accordion.Body>
                            <pre className="text-900">
                              {eachResult?.ApplicationLog}
                            </pre>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey={'output'} key={'output'}>
                          <Accordion.Header>
                            <Flex
                              className={'text-900'}
                              style={{ fontSize: 15 }}
                              alignItems={'center'}
                            >
                              <BiListUl className="me-2" size={20} />
                              Output
                            </Flex>
                          </Accordion.Header>
                          <Accordion.Body>
                            {eachResult?.output &&
                              eachResult.output != null && (
                                <RenderOutput data={eachResult.output} />
                              )}
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item
                          eventKey={'Mitigation'}
                          key={'Mitigation'}
                        >
                          <Accordion.Header>
                            <Flex
                              className={'text-900'}
                              style={{ fontSize: 15 }}
                              alignItems={'center'}
                            >
                              <HiOutlineLightBulb className="me-2" size={20} />{' '}
                              Mitigation Recommendations
                            </Flex>
                          </Accordion.Header>
                          <Accordion.Body>
                            {eachResult?.data?.mitigationRecommendations}
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item
                          eventKey={'Detection'}
                          key={'Detection'}
                        >
                          <Accordion.Header>
                            <Flex
                              className={'text-900'}
                              style={{ fontSize: 15 }}
                              alignItems={'center'}
                            >
                              <BiRadar className="me-2" size={20} />
                              Detection Details
                            </Flex>
                          </Accordion.Header>
                          <Accordion.Body>
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Observable</th>
                                  <th>Value</th>
                                </tr>
                              </thead>
                              <tbody>
                                {eachResult?.meta?.integration?.map(elem => {
                                  return (
                                    <tr>
                                      <td>{elem?.integration}</td>
                                      <td>{elem?.type?.toUpperCase()}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </Accordion.Body>
                        </Accordion.Item>
                        {/* <Accordion.Item
                          eventKey={'Observable'}
                          key={'Observable'}
                        >
                          <Accordion.Header>
                            <Flex
                              className={'text-900'}
                              style={{ fontSize: 15 }}
                              alignItems={'center'}
                            >
                              <BiListCheck className="me-2" size={20} />
                              Observable Details
                            </Flex>
                          </Accordion.Header>
                          <Accordion.Body>
                            <pre className="text-900">
                              {eachResult?.ApplicationLog}
                            </pre>
                          </Accordion.Body>
                        </Accordion.Item> */}
                      </Accordion>
                      <div>
                        <Flex alignItems={'center'}>
                          <BsFillTagFill className="me-2" size={20} />
                          {/* <div className="rounded-pill border me-2  fs--2 px-2 border-2">
                        EDR/EPR
                      </div>
                      <div className="rounded-pill border fs--2 px-2 border-2">
                        Endpoint Antivirus
                      </div> */}
                          {eachResult?.meta?.integration?.length > 0 && (
                            <div className="rounded-pill border me-2  fs--2 px-2 border-2">
                              Splunk
                            </div>
                          )}
                        </Flex>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          )}
        </Accordion>
      </div>
      {selectedOutput && (
        <ScreenshotView
          data={screenshots}
          id={selectedOutput}
          handleClose={() => handleSelectedOutput(null)}
        />
      )}
    </React.Fragment>
  );
}

const PhaseHeader = () => {
  return (
    <div
      className="bg-primary text-white fs--1 fw-medium py-3"
      style={{ paddingLeft: 23, paddingRight: 53 }}
    >
      <Row>
        <Col className="">
          <div>Name</div>
        </Col>
        {/* <Col className="">
          <div>Critical Phase</div>
        </Col> */}
        <Col className="">
          <div>Status</div>
        </Col>
        <Col className="">
          <div>Alerted</div>
        </Col>
        <Col className="">
          <div>Start Time</div>
        </Col>
        <Col>
          <div>End Time</div>
        </Col>
        <Col>
          <div>Screenshot</div>
        </Col>
      </Row>
    </div>
  );
};
