import Flex from 'components/common/Flex';
import React, { useState } from 'react';
import { Button, Col, Nav, Row, Tab } from 'react-bootstrap';
import { FaWindows, FaApple, FaUbuntu, FaRedhat } from 'react-icons/fa';
import classNames from 'classnames';

function AgentPlatformBox({
  id,
  selected,
  disabled,
  onClick,
  name,
  IconComponent,
  containerClasses = ''
}) {
  const handleClick = () => {
    if (!disabled) {
      onClick(id);
    }
  };
  const divStyle = {
    cursor: disabled ? 'not-allowed' : 'pointer',
    width: 140
  };
  return (
    <div
      className={classNames(
        'text-center p-3 hover-bg-200 border-end border-end-2',
        {
          'border-primary': selected,
          ' border-300': !selected,
          'm-3': !containerClasses || containerClasses === ''
        },
        containerClasses
      )}
      style={divStyle}
      onClick={handleClick}
    >
      <IconComponent size={24} />
      <div className="mt-1 fs--1 fw-medium text-uppercase">{name}</div>
    </div>
  );
}

export default function CreateNewAsset() {
  const [key, setKey] = useState('Ubuntu');
  const handleDivClick = id => {
    setKey(id);
  };
  return (
    <React.Fragment>
      <div className="p-5 font-sans-serif bg-soft-secondary w-100">
        <div className="mb-4">
          <Flex>
            <h4 className="fw-bold">Attack Wiz Agents</h4>
            <Button className="ms-auto" variant="outline-primary">
              Agent Configuration
            </Button>
          </Flex>
          <div className="">
            <div className="fs--1 fw-semi-bold">
              Attack Wiz agents are pre-configured and will automatically callback to
              the console.
            </div>
          </div>
        </div>
        <div className="shadow rounded">
          <div className="bg-white rounded overflow-hidden">
            <Tab.Container id="left-tabs-example" activeKey={key}>
              <Flex>
                <div>
                  <AgentPlatformBox
                    id={'Ubuntu'}
                    selected={key === 'Ubuntu'}
                    disabled={false}
                    onClick={handleDivClick}
                    name="Ubuntu"
                    IconComponent={FaUbuntu}
                    containerClasses={'m-0'}
                  />
                  <AgentPlatformBox
                    id={'Redhat'}
                    selected={key === 'Redhat'}
                    disabled={false}
                    onClick={handleDivClick}
                    name="Redhat"
                    IconComponent={FaRedhat}
                    containerClasses={'m-0'}
                  />
                  <AgentPlatformBox
                    id={'Windows'}
                    selected={key === 'Windows'}
                    disabled={false}
                    onClick={handleDivClick}
                    name="Windows"
                    IconComponent={FaWindows}
                    containerClasses={'m-0'}
                  />
                  <AgentPlatformBox
                    id={'Apple'}
                    selected={key === 'Apple'}
                    disabled={false}
                    onClick={handleDivClick}
                    IconComponent={FaApple}
                    name="Apple"
                    containerClasses={'m-0'}
                  />
                </div>
                <div>
                  <Tab.Content className="p-4 h-100">
                    <Tab.Pane eventKey="Ubuntu" className="h-100">
                      <div className="d-flex flex-column justify-content-between h-100">
                        <p>
                          Ubuntu Raw denim you probably haven't heard of them
                          jean shorts Austin. Nesciunt tofu stumptown aliqua,
                          retro synth master cleanse. Mustache cliche tempor,
                          williamsburg carles vegan helvetica. Reprehenderit
                          butcher retro keffiyeh dreamcatcher synth. Cosby
                          sweater eu banh mi, qui irure terry richardson ex
                          squid. Aliquip placeat salvia cillum iphone.
                        </p>
                        <div className="flex-1"></div>
                        <Button className="ms-auto" variant="primary">
                          Download
                        </Button>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="Redhat" className="h-100">
                      <div className="d-flex flex-column justify-content-between h-100">
                        <p>
                          Redhat Raw denim you probably haven't heard of them
                          jean shorts Austin. Nesciunt tofu stumptown aliqua,
                          retro synth master cleanse. Mustache cliche tempor,
                          williamsburg carles vegan helvetica. Reprehenderit
                          butcher retro keffiyeh dreamcatcher synth. Cosby
                          sweater eu banh mi, qui irure terry richardson ex
                          squid. Aliquip placeat salvia cillum iphone.
                        </p>
                        <div className="flex-1"></div>
                        <Button className="ms-auto" variant="primary">
                          Download
                        </Button>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="Windows">
                      <p>
                        Win Food truck fixie locavore, accusamus mcsweeney's
                        marfa nulla single-origin coffee squid. Exercitation +1
                        labore velit, blog sartorial PBR leggings next level wes
                        anderson artisan four loko farm-to-table craft beer
                        twee. Qui photo booth letterpress, commodo enim craft
                        beer mlkshk aliquip jean shorts ullamco ad vinyl cillum
                        PBR. Homo nostrud organic.
                      </p>
                    </Tab.Pane>
                    <Tab.Pane eventKey="Apple">
                      <p>
                        Apple Food truck fixie locavore, accusamus mcsweeney's
                        marfa nulla single-origin coffee squid. Exercitation +1
                        labore velit, blog sartorial PBR leggings next level wes
                        anderson artisan four loko farm-to-table craft beer
                        twee. Qui photo booth letterpress, commodo enim craft
                        beer mlkshk aliquip jean shorts ullamco ad vinyl cillum
                        PBR. Homo nostrud organic.
                      </p>
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </Flex>
            </Tab.Container>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
