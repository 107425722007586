import React, { useCallback, useEffect } from 'react';
import AddStep from './AddStep';
import EditStep from './EditStep';
import FlowDiagram from './FlowDiagram';
import AddStepButton from './AddStepButton';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import {
  findIndexChangedNodes,
  findIndexOfSteps,
  refectorToStepWithIndex,
  rgbaColor
} from 'helpers/utils';
import { getColor } from 'helpers/utils';
import { useContext } from 'react';
import { AddStepContext, StepContext } from 'context/Context';
import { ReactFlowProvider } from 'reactflow';
import DeleteConfirmModal from 'components/common/DeleteConfirmModal';
import { useState } from 'react';
import {
  deleteStepService,
  updateStepService,
  updateStepsService
} from 'services/killchainService';
import KillchainLoader from 'components/common/KillchainLoader';
import useError from 'hooks/useError';

export default function AssessmentSetupKillChain({ data = [] }) {
  const { getResponse } = useError();
  const {
    stepState: { nodes, edges, selectedDeleteStep, isKillchainFetching },
    updateDeleteStep,
    updateNodeDataProps,
    handleDeleteNode,
    updateStoreNodeIndex
  } = useContext(StepContext);
  const [isDeleting, setDeleting] = useState(false);

  const handleDeleteStep = async () => {
    setDeleting(true);
    let deleteEdgesIds = [];
    const node = nodes.find(n => n.id === selectedDeleteStep);
    // update data, edges in steps
    let sourceNodesIds = edges
      .filter(e => e.target == node.id)
      .map(e => e.source);
    // state for updating success/failure step ids
    const sourceData = [];
    for (let sourceId of sourceNodesIds) {
      let source = nodes.find(n => n.id == sourceId);
      let data = {
        name: source.data.name,
        edges: edges.filter(e => e.source == sourceId && e.target != node.id),
        successStepId:
          source.data.successStepId == node.data.successStepId
            ? null
            : source.data.successStepId,
        failureStepId:
          source.data.failureStepId == node.data.failureStepId
            ? null
            : source.data.failureStepId
      };
      const res = await updateStepService(source.data.stepId, data);
      getResponse(res)
        .then(res => {
          sourceData.push({
            nodeId: sourceId,
            data: {
              successStepId: data.successStepId,
              failureStepId: data.failureStepId
            }
          });

          //   //   delete edges where source & target is current deleted step
          //   let deleteTargetEdgeIds =
          edges
            .filter(e => e.source == sourceId && e.target == node.id)
            .forEach(e => deleteEdgesIds.push(e.id));
          //   deleteEdgesFromStore(deleteTargetEdgeIds);
        })
        .catch(err => {
          console.error(err);
          // setDeleting(false);
        });
    }

    const res = await deleteStepService(node.data.stepId);
    getResponse(res)
      .then(async res => {
        // let deleteSourceEdgeIds =
        edges
          .filter(e => e.source == node.id)
          .forEach(e => deleteEdgesIds.push(e.id));
        // deleteEdgesFromStore(deleteSourceEdgeIds);

        // //   delete node of step
        // deleteNode(node.id);

        // await new Promise(resolve => {
        //   setTimeout(resolve, 5000);
        // }).then(async () => {
        // });

        handleDeleteNode(node.id);
        // clear the respective success/failure stepId from nodes where target is current delete step
        sourceData.forEach(({ nodeId, data }) =>
          updateNodeDataProps(nodeId, data)
        );

        setDeleting(false);
        updateDeleteStep(null);
      })
      .catch(err => {
        setDeleting(false);
      });
  };

  if (isKillchainFetching) {
    return <KillchainLoader />;
  }

  return (
    <React.Fragment>
      <div className="position-relative" style={{ height: 600 }}>
        <ReactFlowProvider>
          <FlowDiagram key={nodes} flowNodes={nodes} flowEdges={edges} />
        </ReactFlowProvider>
        <AddStepButton />
        <RenderNoStepOverlay stepLength={nodes.length} />
      </div>
      <AddStep />
      <EditStep />
      <DeleteConfirmModal
        show={selectedDeleteStep != null}
        handleClose={() => updateDeleteStep(null)}
        isLoading={isDeleting}
        message={'Are you sure you want to delete this step?'}
        onDelete={handleDeleteStep}
      />
    </React.Fragment>
  );
}

const RenderNoStepOverlay = ({ stepLength }) => {
  const { toggleModal } = useContext(AddStepContext);
  if (stepLength !== 0) {
    return null;
  }
  return (
    <div
      className="position-absolute top-0 bottom-0 start-0 end-0"
      style={{ backgroundColor: rgbaColor(getColor('dark')) }}
    >
      <Flex className="justify-content-center align-items-center flex-column h-100">
        <div className="text-white fw-medium fs-1 mb-3">
          This assessment doesn't have any steps. Please create steps to start.
        </div>
        <IconButton icon="plus" onClick={toggleModal}>
          <span className="ms-2">Create Step</span>
        </IconButton>
      </Flex>
    </div>
  );
};
