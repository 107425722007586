export const pagesRoutes = {
  ADMIN: [
    {
      label: 'pages',
      labelDisable: true,
      children: [
        {
          name: 'Dashboard',
          icon: 'chart-pie',
          to: '/dashboard',
          active: true
        },
        {
          name: 'Projects',
          icon: 'clone',
          to: '/projects',
          active: true
        },
        {
          name: 'Assets',
          icon: 'cube',
          to: '/assets',
          active: true
        },
        {
          name: 'Templates',
          to: '/templates',
          icon: 'clipboard',
          exact: true,
          active: true
        },
        {
          name: 'Account',
          icon: 'user',
          to: '/account',
          active: true
        },
        {
          name: 'Workbench',
          icon: 'snowflake',
          to: '/workbench',
          active: true
        }
      ]
    }
  ],
  ADMIN_SUPER: [
    {
      label: 'pages',
      labelDisable: true,
      children: [
        {
          name: 'Dashboard',
          icon: 'chart-pie',
          to: '/dashboard',
          active: true
        },
        {
          name: 'Projects',
          icon: 'clone',
          to: '/projects',
          active: true
        },
        {
          name: 'Assets',
          icon: 'cube',
          to: '/assets',
          active: true
        },
        {
          name: 'Templates',
          to: '/templates',
          icon: 'clipboard',
          exact: true,
          active: true
        },
        {
          name: 'Account',
          icon: 'user',
          to: '/account',
          active: true
        }
      ]
    }
  ],
  ADMIN_DEVELOPER: [
    {
      label: 'pages',
      labelDisable: true,
      children: [
        {
          name: 'Workbench',
          icon: 'snowflake',
          to: '/workbench',
          active: true
        }
      ]
    }
  ],
  ORG_ADMIN: [
    {
      label: 'pages',
      labelDisable: true,
      children: [
        {
          name: 'Dashboard',
          icon: 'chart-pie',
          to: '/dashboard',
          active: true
        },
        {
          name: 'Projects',
          icon: 'clone',
          to: '/projects',
          active: true
        },
        {
          name: 'Assets',
          icon: 'cube',
          to: '/assets',
          active: true
        },
        {
          name: 'Templates',
          to: '/templates',
          icon: 'clipboard',
          exact: true,
          active: true
        },
        {
          name: 'Integrations',
          to: '/integrations',
          icon: 'layer-group',
          exact: true,
          active: true
        },
        {
          name: 'Account',
          icon: 'user',
          to: '/account',
          active: true
        }
      ]
    }
  ],
  ORG_USER: [
    {
      label: 'pages',
      labelDisable: true,
      children: [
        {
          name: 'Dashboard',
          icon: 'chart-pie',
          to: '/dashboard',
          active: true
        },
        {
          name: 'Projects',
          icon: 'clone',
          to: '/projects',
          active: true
        },
        {
          name: 'Assets',
          icon: 'cube',
          to: '/assets',
          active: true
        },
        {
          name: 'Templates',
          to: '/templates',
          icon: 'clipboard',
          exact: true,
          active: true
        },
        {
          name: 'Integrations',
          to: '/integrations',
          icon: 'layer-group',
          exact: true,
          active: true
        },
        {
          name: 'Account',
          icon: 'user',
          to: '/account',
          active: true
        }
      ]
    }
  ]
};

export const assessmentRoutes = [
  {
    name: 'Setup',
    icon: 'cubes',
    to: '/setup',
    active: true
  },
  {
    name: 'Results',
    icon: 'scroll',
    to: '/results',
    active: true
  },
  {
    name: 'Reports',
    icon: 'receipt',
    to: '/reports',
    active: true
  }
];

export default pagesRoutes;
