import React from 'react';
import { Form } from 'react-bootstrap';

export default function RenderOutput({ data = [] }) {
  if (!data) {
    return <div className="fw-medium fs--1">No output found yet.</div>;
  }
  return (
    <div>
      {data?.map(each => (
        <div>
          <RenderObjectKeyValues data={each} />
        </div>
      ))}
    </div>
  );
}

const RenderObjectKeyValues = ({ data }) => {
  return Object.keys(data)?.map(key => (
    <div key={key} className="mb-2">
      <Form.Label className="mb-1 fw-medium text-900">{key}</Form.Label>
      {/* <Form.Control value={data[key]} type="textarea" disabled /> */}
      <pre className="text-900">{data[key]}</pre>
    </div>
  ));
};
